import { METADATA_DATASCORING_URLS } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getDataScoringAssociation = () => (dispatch, getState) =>
  asyncRequest({
    url: METADATA_DATASCORING_URLS.DATASCORING_GET_ASSOCIATIONS(),
    method: 'GET',
    successAction: data => ({
      type: types.GET_METADATA_DATASCORING_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_METADATA_DATASCORING_FAILURE,
      payload: error
    }),
    dispatch
  });
export const getDataScoringFormView =
  (year, domainId, associationNo) => (dispatch, getState) =>
    asyncRequest({
      url: METADATA_DATASCORING_URLS.DATASCORING_FORM_VIEW(
        year,
        domainId,
        associationNo
      ),
      method: 'GET',
      successAction: data => ({
        type: types.GET_METADATA_DATASCORING_FORM_VIEW_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_METADATA_DATASCORING_FORM_VIEW_FAILURE,
        payload: error
      }),
      dispatch
    });
export const getDataScoringSubFormView =
  (year, domainId, associationNo, formId) => (dispatch, getState) =>
    asyncRequest({
      url: METADATA_DATASCORING_URLS.DATASCORING_SUBFORM_VIEW(
        year,
        domainId,
        associationNo,
        formId
      ),
      method: 'GET',
      successAction: data => ({
        type: types.GET_METADATA_DATASCORING_SUBFORM_VIEW_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_METADATA_DATASCORING_SUBFORM_VIEW_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getDataScoringEvaluationGroupView =
  (year, domainId, associationNo, formId, subFormId) => (dispatch, getState) =>
    asyncRequest({
      url: METADATA_DATASCORING_URLS.DATASCORING_EVALUATION_GROUP_VIEW(
        year,
        domainId,
        associationNo,
        formId,
        subFormId
      ),
      method: 'GET',
      successAction: data => ({
        type: types.GET_METADATA_DATASCORING_EVALUATION_GROUP_VIEW_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_METADATA_DATASCORING_EVALUATION_GROUP_VIEW_FAILURE,
        payload: error
      }),
      dispatch
    });
export const getDataScoringDataPointsView =
  (year, domainId, associationNo, formId, subFormId, evaluationGroupId) =>
  (dispatch, getState) =>
    asyncRequest({
      url: METADATA_DATASCORING_URLS.DATASCORING_DATAPOINTS_VIEW(
        year,
        domainId,
        associationNo,
        formId,
        subFormId,
        evaluationGroupId
      ),
      method: 'GET',
      successAction: data => ({
        type: types.GET_METADATA_DATASCORING_DATAPOINTS_VIEW_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_METADATA_DATASCORING_DATAPOINTS_VIEW_FAILURE,
        payload: error
      }),
      dispatch
    });

export const addMetadataScoring = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: METADATA_DATASCORING_URLS.ADD_METADATA_SCORIING,
    method: 'PUT',
    data: bodyObject,
    successAction: data => ({
      type: types.ADD_METADATA_DATASCORING_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.ADD_METADATA_DATASCORING_FAILURE,
      payload: error
    }),
    dispatch
  });

  export const getEvalDataBySubform = (domineId,year,formId,subFormId) => (dispatch, getState) =>
    asyncRequest({
      url: METADATA_DATASCORING_URLS.GET_EVALDATE_BY_SUBFORM(domineId,year,formId,subFormId),
      method: 'GET',
      successAction: data => ({
        type: types.ADD_METADATA_DATASCORING_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.ADD_METADATA_DATASCORING_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getEvalDataByIds = (domineId,year,formId,subFormId,category1Id,
  category2Id,
  category3Id) => (dispatch, getState) =>
  asyncRequest({
    url: METADATA_DATASCORING_URLS.GET_EVALDATE_BY_IDS(domineId,year,formId,subFormId,category1Id,category2Id,category3Id),
    method: 'GET',
    successAction: data => ({
      type: types.ADD_METADATA_DATASCORING_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.ADD_METADATA_DATASCORING_FAILURE,
      payload: error
    }),
    dispatch
  });