import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import moment from 'moment';

import BackIcon from '@static/images/back-icon.svg';
import SearchIcon from '@static/images/search-icon.svg';
import uploadIconWhite from '@static/images/upload-icon-white.svg';
import exportIcon from '@static/images/export-icon-white.svg';
import tdsBlueIcon from '@static/images/tds-blue-icon.svg';
import tdsWhiteIcon from '@static/images/tds-white-icon.svg';
import noOfUploadLightIcon from '@static/images/noofupload-light-icon.svg';
import noOfUploadDarkIcon from '@static/images/noofuploaddark-icon.svg';
import totalModulelightIcon from '@static/images/total-module-light-icon.svg';
import totalModulelDarkIcon from '@static/images/total-module-dark-icon.svg';
import { deleteMetadata } from '@redux/metadata-manager/manage-metadata/actions';
import get from 'lodash.get';
import {
  getDashboardCounts,
  getDomains,
  getYears,
  getDashboardForms,
  getTotalDataCollection,
  getTotalUploads,
  getTotalChanges
} from '@redux/metadata-manager/dashboard/actions';
import appConfig, { APP_LABELS, META_DATA } from '@config/app-config';
import userService from '@utilities/user-service';
import CustomModal from '@utilities/CustomModal';

import { METADATA_DASHBOARD_URLS } from '@config/api-config';

import Table from './table';
import DashboardViewDetails from './view-details';

import {
  METADATA_DASHBOARD_COLUMNS,
  METADATA_DASHBOARD_UPLOAD_COLUMNS,
  METADATA_NO_OF_CHANGES_COLUMNS
} from './table-columns';
import { handleCatch, handleResponse } from '../shared/index';

const MetaDataManagerDashboard = ({
  handleGetDomains,
  handleGetYears,
  handleGetDashboardCounts,
  handleGetDashboardForms,
  handleGetTotalCollections,
  handleGetTotalUploads,
  handleGetTotalChanges,
  handleDeleteMetadata,
  loginUserData
}) => {
  const role = get(loginUserData, 'user.role[0]');
  const [deleteId, setDeleteId] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [activeKpi, setActiveKpi] = useState(1);
  const [dataDomains, setDataDomains] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedDataDomain, setSelectedDataDomain] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [appliedDataDomain, setAppliedDataDomain] = useState(null);
  const [appliedYear, setAppliedYear] = useState(null);
  const [kpiData, setKpiData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [globalFilter, setGlobalFilter] = useState('');
  const [forms, setForms] = useState([]);
  const [subForms, setSubForms] = useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [selectedSubForms, setSelectedSubForms] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [, setCanOpenPopup] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccess, setShowSucess] = useState(false);
  const [successMessage, setSucessMessage] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessage] = useState(META_DATA.CONFIRM_META_DATA_DELETE);
  const [allTableData, setAllTableData] = useState([]); // Store original table data
  const [tableColumns, setTableColumns] = useState(
    METADATA_DASHBOARD_COLUMNS({})
  );

  const loadCounts = useCallback(
    async (domainId, year) => {
      setIsLoading(true);
      try {
        const response = await handleGetDashboardCounts(domainId, year);
        const result = handleResponse(response);
        if (result.isSuccess) setKpiData(result.data);
      } finally {
        setIsLoading(false);
      }
    },
    [handleGetDashboardCounts]
  );

  const handleDeleteClick = id => {
    setDeleteId(id);
    setShowConfirmation(true);
  };

  const handleViewClick = item => {
    setSelectedRow(item.row.original);
    setCanOpenPopup(true);
  };

  const handleUploadViewClick = item => {
    setSelectedRow(item.row.original.metadataFileUploadMappings);
    setCanOpenPopup(true);
  };

  const resetFormNSubFroms = () => {
    setSelectedForms([]);
    setSelectedSubForms([]);
    setSubForms([]);
  };

  const loadMetadata = useCallback(
    async (
      domainId,
      year,
      activeKpiOverride = activeKpi,
      startDate = null,
      endDate = null
    ) => {
      setIsLoading(true);
      let response;
      try {
        switch (activeKpiOverride) {
          case 1:
            setTableColumns(
              METADATA_DASHBOARD_COLUMNS({
                handleViewClick,
                handleDeleteClick,
                role
              })
            );
            response = await handleGetTotalCollections(domainId, year);
            break;
          case 2:
            setTableColumns(
              METADATA_DASHBOARD_UPLOAD_COLUMNS({ handleUploadViewClick })
            );
            response = await handleGetTotalUploads(domainId, year);
            break;
          case 3:
            setTableColumns(
              METADATA_NO_OF_CHANGES_COLUMNS({ handleViewClick })
            );
            response = await handleGetTotalChanges(
              domainId,
              year,
              startDate,
              endDate
            );
            break;
          default:
            return;
        }
        const result = handleResponse(response);
        if (result.isSuccess) {
          setTableData(result.data);
          setAllTableData(result.data);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [handleGetTotalCollections, handleGetTotalUploads, handleGetTotalChanges]
  );

  const loadForms = useCallback(
    async (domainId, year) => {
      setIsLoading(true);
      const response = await handleGetDashboardForms(domainId, year);
      const result = handleResponse(response);
      if (result.isSuccess) {
        setForms(
          result.data.map(({ form, subForms }) => ({
            name: form.formName,
            id: form.formId,
            subForms
          }))
        );
      }
      setIsLoading(false);
    },
    [handleGetDashboardForms]
  );

  const loadDashboard = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await Promise.all([
        handleGetDomains(),
        handleGetYears()
      ]);
      const domainRes = handleResponse(response[0]);
      const yearRes = handleResponse(response[1]);
      if (domainRes.isSuccess && yearRes.isSuccess) {
        setDataDomains(domainRes.data);
        setYears(yearRes.data);

        const initialDomain = domainRes.data[0]?.dataDomainId;
        const initialYear = moment().year();
        const storedDomainId =
          sessionStorage.getItem('domainId') || initialDomain;
        const storedYear = sessionStorage.getItem('year') || initialYear;

        if (storedDomainId) {
          setSelectedDataDomain(storedDomainId);
        }

        if (storedYear) {
          setSelectedYear(storedYear);
        }

        setSelectedDataDomain(storedDomainId);
        setSelectedYear(storedYear);
        setAppliedDataDomain(storedDomainId);
        setAppliedYear(storedYear);

        await loadCounts(storedDomainId, storedYear);
        await loadMetadata(storedDomainId, storedYear, activeKpi);
        await loadForms(storedDomainId, storedYear);
      }
    } finally {
      setIsLoading(false);
    }
  }, [handleGetDomains, handleGetYears, loadCounts, loadMetadata, loadForms]);

  const initializeDashboard = useCallback(async () => {
    await loadDashboard();
  }, [handleGetDomains, handleGetYears, loadCounts, loadMetadata, loadForms]);

  // Filter tableData based on selected forms and sub-forms
  const filterTableData = (selectedDataForms, selectedDataSubForms) => {
    if (selectedDataForms.length === 0 && selectedDataSubForms.length === 0) {
      setTableData(allTableData); // Reset to all data if no filters are applied
    } else {
      const filteredData = allTableData.filter(data => {
        const formMatch =
          selectedDataForms.length === 0 ||
          selectedDataForms.some(
            form => form.name.trim() === data.formName?.trim()
          );
        const subFormMatch =
          selectedDataSubForms.length === 0 ||
          selectedDataSubForms.some(
            subForm => subForm.name.trim() === data.subformName?.trim()
          );
        return formMatch && subFormMatch;
      });
      setTableData(filteredData);
    }
  };

  const handleFormChange = selectedFormData => {
    if (selectedFormData.length === 0) {
      // If no forms are selected, clear selectedSubForms and reset subForms
      setSelectedSubForms([]);
      setSubForms([]); // Reset to show all subForms
      setTableData(allTableData); // Reset to original table data
      return;
    }

    const childForms = selectedFormData.flatMap(({ subForms }) =>
      subForms.map(a => ({
        name: a.subFormName,
        id: a.subFormId,
        formId: a.formId
      }))
    );



    setSelectedForms(selectedFormData);
    setSubForms(childForms);
    const selectedFormIds = selectedFormData.map(c => c.id);
    const isChildExisits = childForms.find(c => selectedFormIds.indexOf(c.formId));
    if (!isChildExisits) {
      setSelectedSubForms([]);
      filterTableData(selectedFormData, []);
    } else {
      filterTableData(selectedFormData, selectedSubForms);
    }
  };

  const handleSubFormChange = data => {
    setSelectedSubForms(data);
    filterTableData(selectedForms, data); // Apply filter
  };

  const handleApplyClick = async () => {
    if (selectedDataDomain && selectedYear) {
      setAppliedDataDomain(selectedDataDomain);
      setAppliedYear(selectedYear);
      setGlobalFilter('');
      setStartDate('');
      setEndDate('');
      resetFormNSubFroms();
      sessionStorage.setItem('domainId', selectedDataDomain);
      sessionStorage.setItem('year', selectedYear);
      await loadCounts(selectedDataDomain, selectedYear);
      await loadForms(selectedDataDomain, selectedYear);
      await loadMetadata(selectedDataDomain, selectedYear, activeKpi);
    }
  };

  const handleDateApplyClick = async () => {
    if (selectedDataDomain && selectedYear) {
      const momentStartDate = moment(startDate);
      const momentEndDate = moment(endDate);
      if (
        momentStartDate.isSame(momentEndDate) ||
        momentStartDate.isAfter(momentEndDate)
      ) {
        setErrorMessage('End date should be greater than start date!');
        setShowFailure(true);
      } else {
        setAppliedDataDomain(selectedDataDomain);
        setAppliedYear(selectedYear);
        setGlobalFilter('');
        resetFormNSubFroms();
        await loadCounts(selectedDataDomain, selectedYear);

        await loadMetadata(
          selectedDataDomain,
          selectedYear,
          activeKpi,
          startDate,
          endDate
        );
      }
    }
  };

  const handleKpiCardClick = async kpiIndex => {
    if (kpiIndex !== activeKpi) {
      setTableData([]);
      setGlobalFilter('');
      resetFormNSubFroms();
      setStartDate('');
      setEndDate('');
      setActiveKpi(kpiIndex);
      if (appliedDataDomain && appliedYear) {
        await loadMetadata(appliedDataDomain, appliedYear, kpiIndex);
      }
    }
  };

  const handleResetClick = async () => {
    setTableData([]);
    setGlobalFilter('');
    setSelectedDataDomain(dataDomains[0].dataDomainId);
    setSelectedYear(moment().year());
    sessionStorage.removeItem('year')
    resetFormNSubFroms();
    await loadDashboard();
  };

  const handleConfirmClick = async id => {
    setIsLoading(true);
    try {
      const response = await handleDeleteMetadata(id);
      const result = handleResponse(response);
      if (result.isSuccess) {
        setSucessMessage(META_DATA.DELETE_META_DATA_SUCCESS);
        setShowSucess(true);
        await loadCounts(selectedDataDomain, selectedYear);
        const flteredData = tableData.filter(c => c.s_ID !== id);
        setTableData(flteredData);
      } else {
        setErrorMessage(result.message);
        setShowFailure(true);
      }
    } finally {
      setDeleteId(undefined);
      setShowConfirmation(false);
      setIsLoading(false);
    }
  };

  const handleExportClick = async () => {
    setIsLoading(true);
    try {
      const selectedFormIds = selectedForms.map(c => c.id.toString());
      const selectedSubFormIds = selectedSubForms.map(c => c.id.toString());
      const payload = {
        dataDomain: appliedDataDomain,
        year: appliedYear,
        form: selectedFormIds,
        subform: selectedSubFormIds
      };
      const response = await axios.post(
        METADATA_DASHBOARD_URLS.DASHBOARD_EXPORT_METADATA(),
        payload,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userService.getToken()}`,
            'Ocp-Apim-Subscription-Key': appConfig.APIM_KEY
          }
        }
      );
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'Metadata-DataCollections.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      const err = await handleCatch(error);
      setErrorMessage(err);
      setShowFailure(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initializeDashboard();
    window.scrollTo(0, 0)
  }, [initializeDashboard]);

  return (
    <BlockUi
      blocking={isLoading}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="page-container ymca-data-wrapper">
        <div className="program-admin-wrapper dashboard-wrapper">
          <div className="container">
            <section className="dashboard-header">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="user-name">
                    <h6>Welcome!</h6>
                    <h2 className="ymca-sub-heading mb-0">
                      {loginUserData.user.firstName}{' '}
                      {loginUserData.user.lastName}
                    </h2>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-lg-end text-md-end mt-3 mt-md-0">
                  <Link className="ymca-title-link" to="/home">
                    <img src={BackIcon} className="me-1 mb-2" alt="back-icon" />
                    Back
                  </Link>
                </div>
              </div>

              <div className="program-details mt-4 mb-4">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2 className="ymca-section-heading mb-0">
                      Metadata Manager
                    </h2>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="row align-items-center ymca-form">
                <div className="col-sm-12 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="ddlDataDomains" className="form-label">
                      Data Domain<span className="text-mandatory ms-1">*</span>
                    </label>
                    <select
                      id="ddlDataDomains"
                      className="form-select"
                      aria-label="Default select example"
                      value={selectedDataDomain}
                      onChange={e => setSelectedDataDomain(e.target.value)}
                    >
                      {dataDomains.map(item => (
                        <option value={item.dataDomainId}>
                          {item.dataDomainName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label htmlFor="ddlYears" className="form-label">
                      Year<span className="text-mandatory ms-1">*</span>
                    </label>
                    {console.log("years", years)}
                    <select
                      id="ddlYears"
                      className="form-select"
                      aria-label="Default select example"
                      value={selectedYear}
                      onChange={e => setSelectedYear(e.target.value)}
                    >
                      {years.map(item => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-2 px-lg-0">
                  <button
                    className="btn ymca-primary-btn custom-align"
                    type="button"
                    onClick={() => handleApplyClick()}
                  >
                    Apply
                  </button>
                  <button
                    className="btn ymca-primary-outline-btn custom-align ms-3"
                    type="button"
                    onClick={() => handleResetClick()}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-auto px-lg-0 d-none d-lg-block">
                  <div className="d-none d-lg-flex">
                    <div className="vr custom-vr" />
                  </div>
                </div>
                <div className="col-sm-12 col-md-8 col-lg-auto d-flex justify-content-md-end mt-3 mt-md-0">
                  <Link
                    to="/metadata-manager/categories"
                    className="ymca-hyperlink-text"
                    type="button"
                  >
                    Categories
                  </Link>
                  {role !== APP_LABELS.YUSA_DATA_VIEWER && (
                    <>
                      <Link
                        to="/metadata-manager/manage-metadata"
                        className="ymca-hyperlink-text ms-2"
                        type="button"
                      >
                        + Add Metadata
                      </Link>
                      <Link
                        to="/metadata-manager/scoring"
                        className="ymca-hyperlink-text ms-2"
                        type="button"
                      >
                        + Add Scoring Metadata
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </section>
            {/* {kpiData && kpiData?.length > 0 && ( */}
            <section className="stats-data">
              <div className="row justify-content-center">
                <div
                  className="col-lg-4 col-md-4 col-sm-12"
                  tabIndex="0"
                  role="button"
                  onKeyDown={e => e.preventDefault()}
                  onClick={() => handleKpiCardClick(1)}
                >
                  <div
                    className={`card stats-card tdsCollection-card ${activeKpi === 1 ? 'active' : ''
                      }`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="card-icon">
                        <img
                          src={tdsBlueIcon}
                          alt="tdsBlueIcon"
                          className="primary-icon"
                        />
                        <img
                          src={tdsWhiteIcon}
                          alt="tdsWhiteIcon"
                          className="secondary-icon"
                        />
                      </div>

                      <div className="card-title">
                        <h4>{kpiData?.[0]?.count ?? 0}</h4>
                        <p>{kpiData?.[0]?.metric ?? "No. of Date Points"}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 col-sm-12"
                  tabIndex="0"
                  role="button"
                  onKeyDown={e => e.preventDefault()}
                  onClick={() => handleKpiCardClick(2)}
                >
                  <div
                    className={`card stats-card no-of-upload-card ${activeKpi === 2 ? 'active' : ''
                      }`}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={noOfUploadLightIcon}
                          alt="noOfUploadLightIcon"
                          className="primary-icon"
                        />
                        <img
                          src={noOfUploadDarkIcon}
                          alt="noOfUploadDarkIcon"
                          className="secondary-icon"
                        />
                      </div>

                      <div className="card-title">
                        <h4>{kpiData?.[1]?.count ?? 0}</h4>
                        <p>{kpiData?.[1]?.metric ?? "No. of Uploads"}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 col-sm-12"
                  tabIndex="0"
                  role="button"
                  onKeyDown={e => e.preventDefault()}
                  onClick={() => handleKpiCardClick(3)}
                >
                  <div
                    className={`card stats-card manual-update-card ${activeKpi === 3 ? 'active' : ''
                      }`}
                  >
                    <div className="d-flex align-items-center h-100">
                      <div className="card-icon">
                        <img
                          src={totalModulelightIcon}
                          alt="totalModulelightIcon"
                          className="primary-icon"
                        />
                        <img
                          src={totalModulelDarkIcon}
                          alt="totalModulelDarkIcon"
                          className="secondary-icon"
                        />
                      </div>

                      <div className="card-title">
                        <h4>{kpiData?.[2]?.count ?? 0}</h4>
                        <p>{kpiData?.[2]?.metric ?? "No. of Changes"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* )} */}
          </div>

          <hr className="custom-hr mt-0" />

          {activeKpi === 1 && (
            <section>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-4">
                    <div className="ymca-sub-heading"> Data Points</div>
                  </div>
                  <div className="col-12 col-lg-8">
                    <div className="row align-items-center justify-content-end">
                      <div className="col-12 col-md-6 col-lg-6 mb-3 mb-md-0 mt-2 mt-lg-0">
                        <div className="search-form-wrapper mb-0">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                <img src={SearchIcon} alt="SearchIcon" />
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control search-form-control"
                              placeholder="Search based form section and category"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={globalFilter}
                              onChange={e => setGlobalFilter(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-auto vertical-align-btn">
                        {role !== APP_LABELS.YUSA_DATA_VIEWER && (
                          <Link
                            className="btn ymca-primary-outline-btn"
                            to="/metadata-manager/clone"
                          >
                            Clone
                          </Link>
                        )}
                        <Link
                          to="/metadata-manager/datascoring"
                          className="btn ymca-primary-btn custom-align ms-2"
                        >
                          Data Scoring
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row align-items-center ymca-form mt-3">
                  <div className="col-lg-4">&nbsp;</div>
                  <div className="col-lg-8">
                    <div className="row align-items-center justify-content-end">
                      <div className="col-md-4 col-lg-4">
                        <div className="form-group">
                          <label htmlFor="ddlFormName" className="form-label">
                            Filter by Form Name
                            <span className="text-mandatory ms-1">*</span>
                          </label>
                          <Multiselect
                            showCheckbox
                            options={forms}
                            hidePlaceholder
                            displayValue="name"
                            selectionLimit={50}
                            selectedValues={selectedForms}
                            onSelect={e => handleFormChange(e)}
                            onRemove={e => handleFormChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <div className="form-group">
                          <label htmlFor="datadomain" className="form-label">
                            Filter by Sub Form Name
                            <span className="text-mandatory ms-1">*</span>
                          </label>
                          <Multiselect
                            showCheckbox
                            hidePlaceholder
                            displayValue="name"
                            options={subForms}
                            selectionLimit={50}
                            selectedValues={selectedSubForms}
                            onSelect={e => handleSubFormChange(e)}
                            onRemove={e => handleSubFormChange(e)}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-auto pt-lg-2 mb-3 mb-md-0">
                        <button
                          className="btn ymca-primary-btn mb-2 custom-align d-flex align-items-center"
                          type="button"
                          onClick={() => handleExportClick()}
                        >
                          Export
                          <img
                            src={exportIcon}
                            alt="exportIcon"
                            className="ms-2"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {activeKpi === 2 && (
            <section>
              <div className="container mb-3">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-4">
                    <div className="ymca-sub-heading"> No. of Uploads</div>
                  </div>
                  <div className="col-md-12 col-lg-8">
                    <div className="row align-items-center justify-content-end">
                      <div className="col-md-8 col-lg-6 mt-2 mt-lg-0">
                        <div className="search-form-wrapper mb-0">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                <img src={SearchIcon} alt="SearchIcon" />
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control search-form-control"
                              placeholder="Search based form section and category"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={globalFilter}
                              onChange={e => setGlobalFilter(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      {role !== APP_LABELS.YUSA_DATA_VIEWER && (
                        <div className="col-md-4 col-lg-auto d-flex mt-3 mt-lg-0">
                          <Link
                            to="/metadata-manager/upload-metadata"
                            className="btn ymca-primary-btn d-flex align-items-center ms-0 ms-lg-3"
                          >
                            <img
                              src={uploadIconWhite}
                              className="me-2"
                              alt=""
                            />
                            Upload Metadata
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {activeKpi === 3 && (
            <section>
              <div className="container  mb-3">
                <div className="row align-items-center mt-3">
                  <div className="col-lg-2">
                    <div className="ymca-sub-heading"> Changes</div>
                  </div>
                  <div className="col-12 col-lg-10 mt-3 mt-lg-0">
                    <div className="row align-items-center justify-content-end">
                      <div className="col-lg-6">
                        <div className="search-form-wrapper mb-0">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                <img src={SearchIcon} alt="SearchIcon" />
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control search-form-control"
                              placeholder="Search based form section and category"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={globalFilter}
                              onChange={e => setGlobalFilter(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-auto d-md-flex ymca-form mt-3 mt-lg-0 align-items-center">
                        <div className="form-group">
                          <label htmlFor="dateStartDate" className="form-label">
                            Start Date
                            <span className="text-mandatory ms-1">*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Select Start Date"
                            id="dateStartDate"
                            value={startDate}
                            onChange={e => setStartDate(e.target.value)}
                          />
                        </div>

                        <div className="form-group ms-0 ms-md-3">
                          <label htmlFor="dateEndDate" className="form-label">
                            End Date
                            <span className="text-mandatory ms-1">*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Select End Date"
                            id="dateEndDate"
                            value={endDate}
                            onChange={e => setEndDate(e.target.value)}
                          />
                        </div>
                        <button
                          className="btn ymca-primary-btn d-flex align-items-center ms-0 ms-md-3"
                          type="button"
                          onClick={() => handleDateApplyClick()}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* Table */}
          {tableData && (
            <Table
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              rows={tableData}
              headers={tableColumns}
              showCount
            />
          )}
        </div>
      </div>
      {<DashboardViewDetails row={selectedRow} activeKpi={activeKpi} />}
      <CustomModal
        show={showConfirmation}
        type={APP_LABELS.ATTENTION}
        message={confirmationMessage}
        submitButtonName="Yes"
        cancelButtonName="No"
        onSubmit={() => handleConfirmClick(deleteId)}
        onCancel={() => setShowConfirmation(false)}
      />
      <CustomModal
        show={showSuccess}
        type={APP_LABELS.SUCCESS}
        message={successMessage || APP_LABELS.SUCCESS}
        submitButtonName="Ok"
        onSubmit={() => setShowSucess(false)}
      />
      <CustomModal
        show={showFailure}
        type={APP_LABELS.ERROR}
        message={errorMessage || APP_LABELS.ERROR_MESSAGE}
        submitButtonName="Ok"
        onSubmit={() => setShowFailure(false)}
      />
    </BlockUi>
  );
};

MetaDataManagerDashboard.propTypes = {
  loginUserData: PropTypes.node.isRequired,
  handleGetDomains: PropTypes.func.isRequired,
  handleGetYears: PropTypes.func.isRequired,
  handleGetDashboardCounts: PropTypes.func.isRequired,
  handleGetDashboardForms: PropTypes.func.isRequired,
  handleGetTotalCollections: PropTypes.func.isRequired,
  handleGetTotalUploads: PropTypes.func.isRequired,
  handleGetTotalChanges: PropTypes.func.isRequired,
  handleDeleteMetadata: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetDomains: getDomains,
  handleGetYears: getYears,
  handleGetDashboardCounts: getDashboardCounts,
  handleGetDashboardForms: getDashboardForms,
  handleGetTotalCollections: getTotalDataCollection,
  handleGetTotalUploads: getTotalUploads,
  handleGetTotalChanges: getTotalChanges,
  handleDeleteMetadata: deleteMetadata
};

const mapStateToProps = state => ({
  loginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MetaDataManagerDashboard);
