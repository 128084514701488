export const GET_METADATA_SEGMENT_TYPES_SUCCESS =
  'GET_METADATA_SEGMENT_TYPES_SUCCESS';
export const GET_METADATA_SEGMENT_TYPES_FAILURE =
  'GET_METADATA_SEGMENT_TYPES_FAILURE';

export const GET_METADATA_DATA_POINT_TYPES_SUCCESS =
  'GET_METADATA_DATA_POINT_TYPES_SUCCESS';
export const GET_METADATA_DATA_POINT_TYPES_FAILURE =
  'GET_METADATA_DATA_POINT_TYPES_FAILURE';

export const ADD_META_DATA_SUCCESS = 'ADD_META_DATA_SUCCESS';
export const ADD_META_DATA_FAILURE = 'ADD_META_DATA_FAILURE';

export const UPDATE_META_DATA_SUCCESS = 'UPDATE_META_DATA_SUCCESS';
export const UPDATE_META_DATA_FAILURE = 'UPDATE_META_DATA_FAILURE';

export const DELETE_META_DATA_SUCCESS = 'DELETE_META_DATA_SUCCESS';
export const DELETE_META_DATA_FAILURE = 'DELETE_META_DATA_FAILURE';

export const GET_META_DATA_SUCCESS = 'GET_META_DATA_SUCCESS';
export const GET_META_DATA_FAILURE = 'GET_META_DATA_FAILURE';
