/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
  getSiteinformationList,
  getSiteinformationData
} from '@redux/siteinformation/actions';
import { SiteinformationListSelector } from '@redux/siteinformation/selectors';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import get from 'lodash.get';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import Help from '@modules/shared/components/reusable-components/help';
import { APP_LABELS } from '@config/app-config';
import helpicon from '@static/images/caretdoubleright.svg';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const SiteInformationTab = props => {
  const {
    handleSiteinformationData,
    LoginUserData,
    setLoader,
    status,
    handleGetAnnualReportsDashboard
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');

  const sectionName = 'Site Information';

  const { getSiteinformationListHandler, SiteinformationListData } = props;
  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [instructions, setInstructions] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [resStatus, setResStatus] = useState();
  const [help, setHelp] = useState(false);

  const [initialValues, setInitialValues] = useState({
    totHealthWellnessSites: '',
    totHealthWellnessSitesNotAtY: '',
    totDayCampSites: '',
    totDayCampSitesNotAtY: '',
    totBfrAftrSchoolProgramSites: '',
    totBfrAftrSchoolProgramSitesNotAtY: '',
    totPreschoolChildDevSites: '',
    totPreschoolChildDevSitesNotAtY: '',
    totOvernightCampSites: '',
    totOvernightCampSitesNotAtY: '',
    totCommunityEngageSites: '',
    totCommunityEngageSitesNotAtY: '',
    totNativeHawaiOthrPacificIslander: '',
    totNativeHawaiOthrPacificIslanderNotAtY: ''
  });

  const getSiteInfoData = (loader, successpopup) => {
    if (loader) setLoader(true);
    getSiteinformationListHandler(
      role === APP_LABELS.YUSA_DATA_STEWARD ||
        role === APP_LABELS.YUSA_DATA_VIEWER ||
        role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
        role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0')
    ).then(res => {
      if (loader) setLoader(false);
      if (res?.data?.isSuccess) {
        setInitialValues({
          totHealthWellnessSites: res?.data?.data?.totHealthWellnessSites,
          totHealthWellnessSitesNotAtY:
            res?.data?.data?.totHealthWellnessSitesNotAtY,
          totDayCampSites: res?.data?.data?.totDayCampSites,
          totDayCampSitesNotAtY: res?.data?.data?.totDayCampSitesNotAtY,
          totBfrAftrSchoolProgramSites:
            res?.data?.data?.totBfrAftrSchoolProgramSites,
          totBfrAftrSchoolProgramSitesNotAtY:
            res?.data?.data?.totBfrAftrSchoolProgramSitesNotAtY,
          totPreschoolChildDevSites: res?.data?.data?.totPreschoolChildDevSites,
          totPreschoolChildDevSitesNotAtY:
            res?.data?.data?.totPreschoolChildDevSitesNotAtY,
          totOvernightCampSites: res?.data?.data?.totOvernightCampSites,
          totOvernightCampSitesNotAtY:
            res?.data?.data?.totOvernightCampSitesNotAtY,
          totCommunityEngageSites: res?.data?.data?.totCommunityEngageSites,
          totCommunityEngageSitesNotAtY:
            res?.data?.data?.totCommunityEngageSitesNotAtY
        });
        if (successpopup) {
          setSuccessPopup(true);
        }
      } else {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      }
    });
  };

  useEffect(() => {
    getSiteInfoData(true);
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))['site-information']
        ?.Instruction
    );
  }, []);

  const validationSchema = Yup.object({
    totHealthWellnessSites: Yup.string().nullable(),
    totHealthWellnessSitesNotAtY: Yup.string().nullable(),
    totDayCampSites: Yup.string().nullable(),
    totDayCampSitesNotAtY: Yup.string().nullable(),
    totBfrAftrSchoolProgramSites: Yup.string().nullable(),
    totBfrAftrSchoolProgramSitesNotAtY: Yup.string().nullable(),
    totPreschoolChildDevSites: Yup.string().nullable(),
    totPreschoolChildDevSitesNotAtY: Yup.string().nullable(),
    totOvernightCampSites: Yup.string().nullable(),
    totOvernightCampSitesNotAtY: Yup.string().nullable(),
    totCommunityEngageSites: Yup.string().nullable(),
    totCommunityEngageSitesNotAtY: Yup.string().nullable()
  });

  const convertedValue = value => (value === 0 || value ? value : null);

  const onSubmit = values => {
    setSuccessPopup(false);
    setLoader(true);

    let object = {
      createdByName: SiteinformationListData?.data?.createdByName,
      createdByPersonId: SiteinformationListData?.data?.createdByPersonId,
      createdDatetime: SiteinformationListData?.data?.createdDatetime,
      siteInformationId: SiteinformationListData?.data?.siteInformationId,
      associationNumber: String(
        role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD
          ? get(associateData, 'associationNumber', '')
          : get(LoginUserData, 'user.associations[0]', '0')
      ),
      year: sessionStorage.getItem('selectedBatchYear'),
      recordStatus: SiteinformationListData?.data?.recordStatus,
      associationBranch: SiteinformationListData?.data?.associationBranch,
      reportBatchId: SiteinformationListData?.data?.reportBatchId,
      totHealthWellnessSites: convertedValue(values.totHealthWellnessSites),
      totHealthWellnessSitesNotAtY: convertedValue(
        values.totHealthWellnessSitesNotAtY
      ),
      totDayCampSites: convertedValue(values.totDayCampSites),
      totDayCampSitesNotAtY: convertedValue(values.totDayCampSitesNotAtY),
      totBfrAftrSchoolProgramSites: convertedValue(
        values.totBfrAftrSchoolProgramSites
      ),
      totBfrAftrSchoolProgramSitesNotAtY: convertedValue(
        values.totBfrAftrSchoolProgramSitesNotAtY
      ),
      totPreschoolChildDevSites: convertedValue(
        values.totPreschoolChildDevSites
      ),
      totPreschoolChildDevSitesNotAtY: convertedValue(
        values.totPreschoolChildDevSitesNotAtY
      ),
      totOvernightCampSites: convertedValue(values.totOvernightCampSites),
      totOvernightCampSitesNotAtY: convertedValue(
        values.totOvernightCampSitesNotAtY
      ),
      totCommunityEngageSites: convertedValue(values.totCommunityEngageSites),
      totCommunityEngageSitesNotAtY: convertedValue(
        values.totCommunityEngageSitesNotAtY
      ),
      updatedByPersonId: 0,
      updatedByName: String(get(LoginUserData, 'user.userName', '0')),
      updatedDatetime: new Date()
    };

    handleSiteinformationData(object).then(res => {
      setLoader(false);
      if (res?.data?.isSuccess) {
        setResStatus(res.data.data?.recordStatus);
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
        getSiteInfoData(false, true);
      } else {
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const handleChange = (e, setFieldValue, name) => {
    const result = e.target.value.replace(/\D+/g, '');
    setFieldValue(name, result);
  };

  const handleShowHelp = () => {
    setHelp(true);
  };
  const hideShowHelp = () => {
    setHelp(false);
  };

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  return (
    <div>
      <button
        type="button"
        className="ymca-help-link text-decoration-none ymca-up-data-help-link annual-report-help"
        onClick={handleShowHelp}
      >
        HELP{/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, errors, dirty }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form>
              <div className="ymca-program">
                <div className="ymca-completeBox">
                  <div className="ymca-sorroundings">
                    <h4 className="ymca-informationSiteOff">
                      Site Information
                    </h4>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                      <div className="ymca-instructionBoxes">
                        <h4 className="ymca-instructHeadline">
                          {APP_LABELS.INSTRUCTIONS}
                        </h4>
                        <p className="ymca-instructTitle">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: instructions
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="ymc-alert">
                    {SiteinformationListData?.data?.recordStatus?.toUpperCase() ===
                      APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}
                  </div>
                  <div className="ymca-tableResponsive mb-4">
                    <table className="table ymca-table table-striped">
                      <thead>
                        <tr className="ymca-cuttingData">
                          <th>&nbsp;</th>
                          <th>
                            {'Total No. of Locations'}
                            <br />
                            <span className="ymca-cachet-normal-dark-text">
                              {'(including at program sites, '}
                              <br />
                              {'branches or corporate Y)'}
                            </span>
                          </th>
                          <th>
                            {'Total No. of Locations '}
                            <br />
                            {'Not a Branch or Corporate Y'}
                          </th>
                          <th className="ymca-defaultSpace">&nbsp;</th>
                          <th className="ymca-defaultSpace">&nbsp;</th>
                          <th className="ymca-defaultSpace">&nbsp;</th>
                        </tr>
                      </thead>

                      <tbody className="ymca-cuttingBody">
                        <tr>
                          <td className="txt-mandatory">
                            Health &amp; Wellness Program Sites
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              className="ymca-field"
                              id="totHealthWellnessSites"
                              name="totHealthWellnessSites"
                              type="text"
                              maxLength={9}
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'totHealthWellnessSites'
                                )
                              }
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              className="ymca-field"
                              id="totHealthWellnessSitesNotAtY"
                              name="totHealthWellnessSitesNotAtY"
                              maxLength={9}
                              type="text"
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'totHealthWellnessSitesNotAtY'
                                )
                              }
                            />
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>
                        <tr>
                          <td className="txt-mandatory">
                            Day Camp Program Sites
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              className="ymca-field"
                              id="totDayCampSites"
                              name="totDayCampSites"
                              maxLength={9}
                              type="text"
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'totDayCampSites'
                                )
                              }
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              className="ymca-field"
                              id="totDayCampSitesNotAtY"
                              name="totDayCampSitesNotAtY"
                              maxLength={9}
                              type="text"
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'totDayCampSitesNotAtY'
                                )
                              }
                            />
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>
                        <tr>
                          <td className="txt-mandatory">
                            Before &amp; After School Program Sites
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              className="ymca-field"
                              id="totBfrAftrSchoolProgramSites"
                              name="totBfrAftrSchoolProgramSites"
                              maxLength={9}
                              type="text"
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'totBfrAftrSchoolProgramSites'
                                )
                              }
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              className="ymca-field"
                              id="totBfrAftrSchoolProgramSitesNotAtY"
                              name="totBfrAftrSchoolProgramSitesNotAtY"
                              maxLength={9}
                              type="text"
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'totBfrAftrSchoolProgramSitesNotAtY'
                                )
                              }
                            />
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>
                        <tr>
                          <td className="txt-mandatory">
                            Preschool / Early Childhood Development Program
                            Sites
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              className="ymca-field"
                              id="totPreschoolChildDevSites"
                              name="totPreschoolChildDevSites"
                              maxLength={9}
                              type="text"
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'totPreschoolChildDevSites'
                                )
                              }
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              className="ymca-field"
                              id="totPreschoolChildDevSitesNotAtY"
                              name="totPreschoolChildDevSitesNotAtY"
                              maxLength={9}
                              type="text"
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'totPreschoolChildDevSitesNotAtY'
                                )
                              }
                            />
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>
                        {selectedYear <= '2023' && (
                          <tr>
                            <td className="txt-mandatory">
                              Overnight Camp Program Sites
                            </td>
                            <td className="ymca-fieldLine">
                              <Field
                                className="ymca-field"
                                id="totOvernightCampSites"
                                name="totOvernightCampSites"
                                maxLength={9}
                                type="text"
                                onChange={e =>
                                  handleChange(
                                    e,
                                    setFieldValue,
                                    'totOvernightCampSites'
                                  )
                                }
                              />
                            </td>
                            <td className="ymca-fieldLine">
                              <Field
                                className="ymca-field"
                                id="totOvernightCampSitesNotAtY"
                                name="totOvernightCampSitesNotAtY"
                                maxLength={9}
                                type="text"
                                onChange={e =>
                                  handleChange(
                                    e,
                                    setFieldValue,
                                    'totOvernightCampSitesNotAtY'
                                  )
                                }
                              />
                            </td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                            <td className="ymca-defaultSpace">&nbsp;</td>
                          </tr>
                        )}
                        <tr>
                          <td className="txt-mandatory">
                            Community Development / Engagement Sites
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              className="ymca-field"
                              id="totCommunityEngageSites"
                              name="totCommunityEngageSites"
                              maxLength={9}
                              type="text"
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'totCommunityEngageSites'
                                )
                              }
                            />
                          </td>
                          <td className="ymca-fieldLine">
                            <Field
                              className="ymca-field"
                              id="totCommunityEngageSitesNotAtY"
                              name="totCommunityEngageSitesNotAtY"
                              maxLength={9}
                              type="text"
                              onChange={e =>
                                handleChange(
                                  e,
                                  setFieldValue,
                                  'totCommunityEngageSitesNotAtY'
                                )
                              }
                            />
                          </td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                          <td className="ymca-defaultSpace">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="ymca-submitHighlighter">
                    <LastUpdateName
                      Username={SiteinformationListData?.data?.updatedByName}
                      Time={SiteinformationListData?.data?.updatedDatetime}
                    />
                  </div>
                </div>

                {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                  role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                    <button
                      type="submit"
                      className="btn ymca-savingBTN ymca-btnRightTitle float-end"
                      onClick={() => scrollToErrors(errors)}
                      disabled={
                        status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                      }
                    >
                      Save
                    </button>
                  )}
              </div>
            </Form>
          );
        }}
      </Formik>

      {successPopup && <SuccessToaster Status={resStatus} />}

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />

      {help && <Help hidePopup={hideShowHelp} keyValue="site-information" />}
    </div>
  );
};

SiteInformationTab.propTypes = {
  SiteinformationListData: PropTypes.objectOf(PropTypes.any).isRequired,
  getSiteinformationListHandler: PropTypes.func.isRequired,
  handleSiteinformationData: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  getSiteinformationListHandler: getSiteinformationList,
  handleSiteinformationData: getSiteinformationData,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData
};

const mapStateToProps = state => ({
  SiteinformationListData: SiteinformationListSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(SiteInformationTab));
