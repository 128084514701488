import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import BackIcon from '@static/images/back-icon.svg';
import { Link } from 'react-router-dom';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import SearchIcon from '@static/images/search-icon.svg';
import { getDataScoringFormView } from '@redux/metadata-manager/data-scoring/actions';
import FORMS_COLUMN from './forms-column';
import Table from './table';

const MetaDataManagerForms = ({ handleGetDataScoringFormView, location }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const { Year, dataDomainName, DataDomain, Association, associationName } =
    location?.state;
  const initializeDataScoringFormView = async () => {
    setIsLoading(true);
    try {
      const response = await handleGetDataScoringFormView(
        Year,
        DataDomain,
        Association
      );
      if (response?.data?.data) {
        setTableData(response.data.data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initializeDataScoringFormView();
  }, []);

  const renderTabledata = tableData?.map(data => {
    const location1 = {
      pathname: '/metadata-manager/datascoring/forms/subforms',
      state: {
        Year: Year,
        DataDomain: DataDomain,
        Association: Association,
        dataDomainName: dataDomainName,
        associationName: associationName,
        Form: data?.formId,
        formName: data?.formName
      }
    };
    return {
      Association: Association,
      Form: data?.formName,
      FormId: data?.formId,
      FormDataScore: data.formDataScore,
      MaxScorePossible: data.maxScorePossible,
      ViewSubForms: location1
    };
  });
  return (
    <BlockUi
      blocking={isLoading}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper">
        <main className="page-container">
          <div className="container">
            <div className="d-flex justify-content-between">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb ymca-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/metadata-manager/dashboard">
                      <span>Dashboard</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/metadata-manager/datascoring">
                      <span> Data Scoring</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item active">Forms</li>
                </ol>
              </nav>
              <Link
                className="ymca-title-link"
                to="/metadata-manager/datascoring"
              >
                <img src={BackIcon} className="me-1 mb-2" alt="back-icon" />{' '}
                Back
              </Link>
            </div>
            <h5 className="ymca-sub-heading">Forms</h5>
          </div>

          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card outline-card">
                  <div className="card-body p-0">
                    <div className="ymca-dark-para-2">Year</div>
                    <div className="ymca-dark-para-5 mb-0">
                      {location?.state?.Year}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card outline-card">
                  <div className="card-body p-0">
                    <div className="ymca-dark-para-2">Data Domain</div>
                    <div className="ymca-dark-para-5 mb-0">
                      {location?.state?.dataDomainName}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card outline-card">
                  <div className="card-body p-0">
                    <div className="ymca-dark-para-2">Association</div>
                    <div className="ymca-dark-para-5 mb-0">
                      {location?.state?.associationName}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="container mb-4">
            <div className="d-flex justify-content-between align-items-center">
              <div className="ymca-section-heading"> Forms Details</div>
              <div className="search-form-wrapper mb-0">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <img src={SearchIcon} alt="SearchIcon" />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control search-form-control"
                    placeholder="Search"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={globalFilter}
                    onChange={e => setGlobalFilter(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* <div className="table-responsive custom-scrollbar mt-3">
              <table className="table ymca-table ymca-tableHeight">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Form </th>
                    <th>FormId</th>
                    <th>Form Data Score</th>
                    <th>Max Score Possible</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>YMCA Directory Information</td>
                    <td>Q1</td>
                    <td>-</td>
                    <td>-</td>
                    <td>
                      <a href="" className="table-link">
                        {' '}
                        View Sub Form
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>YMCA Directory Information</td>
                    <td>Q1</td>
                    <td>-</td>
                    <td>-</td>
                    <td>
                      <a href="" className="table-link">
                        {' '}
                        View Sub Form
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>YMCA Directory Information</td>
                    <td>Q1</td>
                    <td>-</td>
                    <td>-</td>
                    <td>
                      <a href="" className="table-link">
                        {' '}
                        View Sub Form
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>YMCA Directory Information</td>
                    <td>Q1</td>
                    <td>-</td>
                    <td>-</td>
                    <td>
                      <a href="" className="table-link">
                        {' '}
                        View Sub Form
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
          </div>
          {tableData && (
            <Table
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              rows={renderTabledata}
              headers={FORMS_COLUMN()}
              showpagination
            />
          )}
        </main>
      </div>
    </BlockUi>
  );
};

MetaDataManagerForms.propTypes = {
  handleGetDataScoringFormView: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetDataScoringFormView: getDataScoringFormView
};
export default connect(null, mapDispatchToProps)(MetaDataManagerForms);
