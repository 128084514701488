import React from 'react';
import { Link } from 'react-router-dom';

const SUBFORMS_COLUMN = () => [
  {
    Header: 'Association',
    accessor: 'Association',
    className: 'notranslate'
  },
  {
    Header: 'Sub-Form',
    accessor: 'SubForm',
    className: 'notranslate'
  },
  {
    Header: 'Sub-FormId',
    accessor: 'SubFormId',
    className: 'notranslate'
  },
  {
    Header: 'Sub-Form Data Score',
    accessor: 'SubFormDataScore',
    className: 'notranslate'
  },
  {
    Header: 'Points Possible',
    accessor: 'PointsPossible',
    className: 'notranslate'
  },
  {
    Header: 'Actions',
    accessor: 'ShowEvalGroups',
    Cell: v => (
      <>
        <div className="d-flex">
          <Link
            to={v.value}
            className="table-link"
            onClick={() =>
              sessionStorage.setItem('metaData_categoryItem', v.value)
            }
          >
            Show Eval Groups
          </Link>
        </div>
      </>
    )
  }
];

export default SUBFORMS_COLUMN;
