import React, { useCallback, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Loader } from 'react-loaders';
import BlockUi from 'react-block-ui';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  getDashboardForms,
  getDomains,
  getYears
} from '@redux/metadata-manager/dashboard/actions';
import { getCategoryMappingBySubForms } from '@redux/metadata-manager/categories/actions';
import {
  addMetadata,
  getSegmentTypes,
  updateMetadata
} from '@redux/metadata-manager/manage-metadata/actions';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import TextError from '@modules/shared/components/reusable-components/text-error';
import BackIcon from '@static/images/back-icon.svg';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import { APP_LABELS, META_DATA } from '@config/app-config';
import CustomModal from '@utilities/CustomModal';
import { handleCatch, handleResponse } from '../shared/index';
import Table from './table';
import { METADATA_SCORING_COLUMNS } from './columns';
import { addMetadataScoring , getEvalDataBySubform ,getEvalDataByIds} from '@redux/metadata-manager/data-scoring/actions';

const initialValues = {
  year: '',
  dataDomainId: '',
  dataDomainName: '',
  formName: '',
  formId: '',
  subformName: '',
  subformId: '',
  category1Name: '',
  category1Id: '',
  category2Name: '',
  category2Id: '',
  category3Name: '',
  category3Id: '',
  dataSegmentType: '',
  minPointsReq: '',
  earnedScore: '',
  evelGroup: ''
};

const ScoringMetaData = ({
  handleGetDomains,
  handleGetYears,
  handleGetSegmentTypes,
  handleGetDashboardForms,
  handleGetCategoryMappings,
  handleGetMetadata,
  handleGetEvalDataByIds,
  handleAddMetadataScoring,
  handleGetEvalDataBySubform
}) => {
  // const [initialValues, setInitialValues] = useState({ ...initialData });

  const validationSchema = Yup.object({
    dataDomainId: Yup.string().nullable().required('Data Domain is Required'),
    year: Yup.string().nullable().required('Year is Required'),
    formId: Yup.string().nullable().required('Form is Required'),
    subformId: Yup.string().nullable().required('Subform is Required'),
    category1Id: Yup.string().nullable().required('Category 1 is Required'),
    evelGroup: Yup.string().nullable().required('Eval Group is Required'),
    minPointsReq: Yup.number()
      .typeError('Min Points Required must be a valid number')
      .nullable(),
    dataSegmentType: Yup.string()
      .nullable()
      .required('Eval Group Type is Required'),
    earnedScore: Yup.number()
      .typeError('Earned Score must be a valid number')
      .nullable()
      .required('Earned Score is Required')
  });

  const [isloading, setIsLoading] = useState('');
  const [scoringData, setScoringData] = useState([]);
  const [selectedScoreData,setSelectedScoreData] = useState({})

  const [state, setState] = useState({
    listOfYears: [],
    listOfDataDomains: [],
    listOfForms: [],
    listOfSubForms: [],
    listOfAllSubForms: [],
    listOfCategoryMappings: [],
    listOfSegmentTypes: [],
    listOfCategory1: [],
    listOfCategory2: [],
    listOfCategory3: [],
    listOfFormSubForm: [],
    listOfCategories: [],
    dataDomainId: '',
    year: '',
    formId: '',
    subFormId: '',
    category1: '',
    category2: '',
    category3: '',
    segmentType: '',
    evalGroup: '',
    dataEvalMinScore: '',
    earnedScore: 0,
    createdDttm: '',
    createdBy: '',
    updatedDttm: '',
    updatedBy: '',
    isLoading: false,
    showSuccess: false,
    showFailure: false,
    errorMessage: '',
    metadataId: undefined,

    tableData: [],
    errors: {} // State for form errors
  });

  const {
    listOfYears,
    listOfDataDomains,
    listOfForms,
    listOfSubForms,
    listOfSegmentTypes,
    listOfCategory1,
    listOfCategory2,
    listOfCategory3,
    listOfFormSubForm,
    listOfCategories,
    showSuccess,
    showFailure,
    errorMessage,
    isLoading,
    metadataId
  } = state;

  // Helper to update state
  const updateState = updates => setState(prev => ({ ...prev, ...updates }));

  const loadForms = useCallback(
    async (_domainId, _year) => {
      updateState({ isLoading: true });
      try {
        const response = await handleGetDashboardForms(_domainId, _year);
        const result = handleResponse(response);
        if (result.isSuccess) {
          const formsData = result.data.flatMap(({ form }) => form);
          const subFormsData = result.data.flatMap(({ subForms }) => subForms);
          const formWithSubForms = result.data.flatMap(({ form, subForms }) => {
            return {
              form: {
                ...form,
                subForms: subForms.filter(
                  subForm => subForm.formId === form.formId
                )
              }
            };
          });
          updateState({
            listOfForms: formsData,
            listOfSubForms: subFormsData,
            listOfAllSubForms: subFormsData,
            listOfFormSubForm: formWithSubForms
          });
        }
      } finally {
        updateState({ isLoading: false });
      }
    },
    [handleGetDashboardForms]
  );

  const loadMetadata = useCallback(async id => {
    updateState({ isLoading: true, metadataId: id });

    const response = await handleGetMetadata(id);
    const result = handleResponse(response);
    if (result.isSuccess) {
      const { data } = result;

      await loadForms(data.dataDomainId, data.year);

      updateState({
        dataDomainId: data.dataDomainId || '',
        year: data.year || '',
        formId: data.formId || '',
        subFormId: data.subformId || '',
        category1: data.category1Id || '',
        category2: data.category2Id || '',
        category3: data.category3Id || '',
        segmentType: data.dataSegmentType || '',
        earnedScore: data.earnedScore || '',
        dataEvalMinScore: data.minPointsReq || '',
        updatedDttm: data.updatedDttm || '',
        updatedBy: data.updatedBy || '',
        createdDttm: data.createdDttm || '',
        createdBy: data.createdBy || ''
      });
    }

    updateState({ isLoading: false });
  }, []);

  const initializeMetadata = useCallback(async () => {
    updateState({ isLoading: true });
    try {
      const [domainsRes, yearsRes, segmentRes] = await Promise.all([
        handleGetDomains(),
        handleGetYears(),
        handleGetSegmentTypes()
      ]);

      updateState({
        listOfDataDomains: handleResponse(domainsRes).data || [],
        listOfYears: handleResponse(yearsRes).data || [],
        listOfSegmentTypes: handleResponse(segmentRes).data || []
      });

      const path = window.location.pathname;
      const id = path.substring(path.lastIndexOf('/') + 1);
      if (id && id > 0) {
        await loadMetadata(id);
      }
    } catch (error) {
      const errorMsg = handleCatch(error);
      updateState({
        showDownloadMessage: errorMsg || APP_LABELS.ERROR_MESSAGE,
        showDownloadStatus: true
      });
    } finally {
      updateState({ isLoading: false });
    }
  }, []);

  const updateCategoryMappings = data => {
    const nestedCategories = [];
    data.forEach(
      ({
        category1Id,
        category1Name,
        category1SequenceNo,
        category2Id,
        category2Name,
        category2SequenceNo,
        category3Id,
        category3Name,
        category3SequenceNo
      }) => {
        // Find or create category1
        // eslint-disable-next-line no-shadow
        let category1 = nestedCategories.find(
          cat1 => cat1.category1Id === category1Id
        );
        if (!category1) {
          category1 = {
            category1Id,
            category1Name,
            category1SequenceNo,
            category2: []
          };
          nestedCategories.push(category1);
        }

        // Find or create category2 under category1
        // eslint-disable-next-line no-shadow
        let category2 = category1.category2.find(
          cat2 => cat2.category2Id === category2Id
        );
        if (!category2) {
          category2 = {
            category2Id,
            category2Name,
            category2SequenceNo,
            category1SequenceNo,
            category3: []
          };
          category1.category2.push(category2);
        }

        // Find or create category3 under category2
        // eslint-disable-next-line no-shadow
        let category3 = category2.category3.find(
          cat3 => cat3.category3Id === category3Id
        );
        if (!category3) {
          category3 = {
            category3Id,
            category3Name,
            category3SequenceNo,
            category1SequenceNo,
            category2SequenceNo
          };
          category2.category3.push(category3);
        }
      }
    );
    updateState({
      listOfCategories: nestedCategories,
      listOfCategory1: [...nestedCategories]
    });
  };

  const loadSubForms = useCallback(
    _formId => {
      const subForms = listOfFormSubForm.find(
        c => c.form.sequenceNo.toString() === _formId.toString()
      )?.form?.subForms;
      updateState({ listOfSubForms: subForms });
    },
    [listOfFormSubForm]
  );

  const loadCategories = useCallback(
    async (_formId, _subFormId) => {
      const localFormId = listOfFormSubForm.find(
        c => c.form.sequenceNo.toString() === _formId.toString()
      )?.form?.formId;
      const localSubFormId = listOfFormSubForm
        .find(c => c.form.sequenceNo.toString() === _formId.toString())
        ?.form?.subForms.find(
          c => c.sequenceNo.toString() === _subFormId.toString()
        )?.subFormId;
      const response = await handleGetCategoryMappings(
        localFormId,
        localSubFormId
      );
      const result = handleResponse(response);
      updateCategoryMappings(result.data);
    },
    [listOfFormSubForm]
  );

  const loadCategory2 = useCallback(
    _category1 => {
      const category2List = listOfCategory1.find(
        c => c.category1SequenceNo.toString() === _category1.toString()
      )?.category2;
      updateState({ listOfCategory2: category2List });
    },
    [listOfCategories]
  );

  const loadCategory3 = useCallback(
    _category2 => {
      const category3List = listOfCategory2?.find(
        c => c.category2SequenceNo.toString() === _category2.toString()
      )?.category3;
      updateState({ listOfCategory3: category3List });
    },
    [listOfCategory2]
  );

  const calculateEvlDataId = (
    formId,
    subFormId,
    categoryId1,
    categoryId2,
    categoryId3,
    dataEvalGroupType,
    evelGroup
  ) => {
    // Check categorization levels
    const hasCategory1 = categoryId1 && categoryId1.trim() !== '';
    const hasCategory2 =
      categoryId2 && categoryId2.trim() !== '' && !(evelGroup == 1);
    const hasCategory3 =
      categoryId3 && categoryId3.trim() !== '' && evelGroup == 3;

    // Rule 3: If there is only one (1) Categorization Level defined

    // Rule 4: If there are two (2) Categorization Levels defined, and dataEvalGroupType is SINGLE_CHOICE_GROUP or MULTI_CHOICE_GROUP

    if (hasCategory1 && !hasCategory2 && !hasCategory3) {
      return `Q${formId}.${subFormId}#${categoryId1}`;
    }

    if (
      hasCategory1 &&
      hasCategory2 &&
      !hasCategory3 &&
      (dataEvalGroupType === 'SINGLE_CHOICE_SEGMENT' ||
        dataEvalGroupType === 'MULTI_CHOICE_SEGMENT')
    ) {
      return `Q${formId}.${subFormId}#${categoryId1}`;
    }

    // Rule 5: If there are two (2) Categorization Levels defined, and dataEvalGroupType is SINGLE_CHOICE

    // Rule 6: If there are three (3) Categorization Levels defined, and dataEvalGroupType is SINGLE_CHOICE_GROUP or MULTI_CHOICE_GROUP

    if (
      (hasCategory1 &&
        hasCategory2 &&
        !hasCategory3 &&
        dataEvalGroupType === 'SINGLE_CHOICE') ||
      (hasCategory1 &&
        hasCategory2 &&
        hasCategory3 &&
        (dataEvalGroupType === 'SINGLE_CHOICE_SEGMENT' ||
          dataEvalGroupType === 'MULTI_CHOICE_SEGMENT'))
    ) {
      return `Q${formId}.${subFormId}#${categoryId1}_${categoryId2}`;
    }

    if (
      hasCategory1 &&
      hasCategory2 &&
      hasCategory3 &&
      (dataEvalGroupType === 'SINGLE_CHOICE_SEGMENT' ||
        dataEvalGroupType === 'MULTI_CHOICE_SEGMENT')
    ) {
      return `Q${formId}.${subFormId}#${categoryId1}_${categoryId2}`;
    }

    // Rule 7: If there are three (3) Categorization Levels defined, and dataEvalGroupType is SINGLE_CHOICE

    if (
      hasCategory1 &&
      hasCategory2 &&
      hasCategory3 &&
      dataEvalGroupType === 'SINGLE_CHOICE'
    ) {
      return `Q${formId}.${subFormId}#${categoryId1}_${categoryId2}_${categoryId3}`;
    }
    return '';
  };

const returnEvalName = (evelGroup,category1Name,category2Name,category3Name) => {
  if(evelGroup && category3Name) return category3Name
  if(evelGroup && category2Name) return category2Name
  if(evelGroup && category1Name) return category1Name

}
     

  const handleFormSubmit = async (values, { resetForm, setFieldValue }) => {
    const {
      dataDomainId,
      year,
      formId,
      subformId,
      category1Id,
      category2Id,
      category3Id,
      dataSegmentType,
      minPointsReq,
      earnedScore,
      evelGroup,
      category3Name,
      category2Name,
      category1Name
    } = values;

    const payload = { ...values };
    delete payload.evelGroup;

    (evelGroup == 1 || !category2Id) && delete payload.category2Id;
    (evelGroup == 1 || !category2Id) && delete payload.category2Name;
    (evelGroup == 1 || evelGroup == 2 || !category3Id) &&
      delete payload.category3Id;
    (evelGroup == 1 || evelGroup == 2 || !category3Id) &&
      delete payload.category3Name;

    setIsLoading(true);

    try {
      const response = await handleAddMetadataScoring(payload);

      const result = handleResponse(response);
      const scoringTableData = {
        evalGroupType :dataSegmentType,
        minPointsReq,
        earnedScore,
        evalGroup: `Category ${evelGroup}`,
        evalGroupName:returnEvalName(evelGroup,category1Name,category2Name,category3Name),
        evalGroupId: calculateEvlDataId(
          formId,
          subformId,
          category1Id,
          category2Id,
          category3Id,
          dataSegmentType,
          evelGroup
        )
      };

      if (result?.isSuccess) {
        resetForm();
        handleScoreFields(null,setFieldValue)
        setFieldValue('formId', formId);
        setFieldValue('subformId', subformId);
        setFieldValue('dataDomainId', dataDomainId);
        setFieldValue('year', year);
        setScoringData([scoringTableData , ...scoringData]);
        updateState({ showSuccess: true });
      } else {
        updateState({
          showFailure: true,
          errorMessage: result?.message
        });
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const clearCategoriesData = setFieldValue => {
    setFieldValue('category1Id', '');
    setFieldValue('category1Name', '');
    setFieldValue('category2Id', '');
    setFieldValue('category2Name', '');
    setFieldValue('category3Id', '');
    setFieldValue('category3Name', '');
  };

  const handleForm = (value, setFieldValue) => {
    if (value) {
      loadSubForms(value);
      setFieldValue('formId', value);
      setFieldValue('subformId', '');
      clearCategoriesData(setFieldValue);
      const name = listOfForms?.find(res => res.sequenceNo == value)?.formName;
      setFieldValue('formName', name);
    }
    handleScoreFields(null,setFieldValue)
  };

  const handleScoreFields = (data,setFieldValue) => {
    setSelectedScoreData(data)
    setFieldValue('minPointsReq', data?.minPointsReq || "");
    setFieldValue("earnedScore",data?.earnedScore || "")
    setFieldValue('evelGroup', data?.evalGroup?.replace("Category","")?.trim() || "");
    setFieldValue("dataSegmentType",data?.evalGroupType || "")
  }

  const handleEvalDataByIds = async (values,setFieldValue) => {
    const {  
      dataDomainId,
      year,
      formId,
      subformId,
      category1Id,
      category2Id,
      category3Id } = values
    try {
      const response = await handleGetEvalDataByIds(dataDomainId,
        year,
        formId,
        subformId,
        category1Id,
        category2Id,
        category3Id)
      const result = handleResponse(response);
      if (result?.isSuccess) {
        handleScoreFields(result?.data,setFieldValue)
      }else{
        handleScoreFields(null,setFieldValue)
      }
    }
    catch(error){
      handleScoreFields(null,setFieldValue)
    }
  }

  const handleCategory2List = (value, setFieldValue,values) => {
    if (value) {
      setFieldValue('category1Id', value);
      setFieldValue('category2Id', '');
      setFieldValue('category2Name', '');
      setFieldValue('category3Id', '');
      setFieldValue('category3Name', '');
      loadCategory2(value);
      const name = listOfCategory1?.find(
        res => res.category1SequenceNo == value
      )?.category1Name;
      setFieldValue('category1Name', name);
      let data = scoringData?.find((res) => res?.evalGroupName === name)
      handleEvalDataByIds({...values,category1Id:value},setFieldValue)
      // setSelectedScoreData(data)
     // handleScoreFields(data,setFieldValue)
    }else{
      handleScoreFields(null,setFieldValue)
    }
  };

  const handleCategory3List = (value, setFieldValue,values) => {
    if (value) {
      setFieldValue('category2Id', value);
      setFieldValue('category3Id', '');
      setFieldValue('category3Name', '');
      const name = listOfCategory2?.find(
        res => res.category2SequenceNo == value
      )?.category2Name;
      setFieldValue('category2Name', name);
      handleEvalDataByIds({...values,category2Id:value},setFieldValue)
      loadCategory3(value);
    }
  };

  const handle3Form = (value, setFieldValue,values) => {
    if (value) {
      setFieldValue('category3Id', value);
      const name = listOfCategory3?.find(
        res => res.category3SequenceNo == value
      )?.category3Name;
      setFieldValue('category3Name', name);
      handleEvalDataByIds({...values,category3Id:value},setFieldValue)
    }
  };

  const handledataDomain = (value, setFieldValue) => {
    if (value) {
      setFieldValue('dataDomainId', value);
      const name = listOfDataDomains?.find(
        res => res.dataDomainId == value
      )?.dataDomainName;
      setFieldValue('dataDomainName', name);
    }
  };

  const handleSubForm = (value, setFieldValue) => {
    if (value) {
      setFieldValue('subformId', value);
      clearCategoriesData(setFieldValue);
      const name = listOfSubForms?.find(
        res => res.sequenceNo == value
      )?.subFormName;
      setFieldValue('subformName', name);
    }
  };

  const getEvalDataBySubform = async (values, subformId) =>{
    const { dataDomainId , year ,formId } = values
    try {
       const response = await handleGetEvalDataBySubform(dataDomainId , year , formId , subformId);
       console.log("reponse",response)
       const result = handleResponse(response);
       if (result.isSuccess) {
        setScoringData(result.data);
       }else{
        setScoringData([]);
       }
    }
    catch (error){
      setScoringData([])
    }
  }

  useEffect(() => {
    initializeMetadata();
    window.scrollTo(0,0)
  }, [initializeMetadata]);

  return (
    <BlockUi
      blocking={isLoading || isloading}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper manage-metadata-page">
        <main className="page-container">
          <div className="container">
            <div className="d-flex justify-content-between">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb ymca-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/metadata-manager/dashboard">
                      <span>Dashboard</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item active">
                    {metadataId ? 'Edit' : 'Add'} Scoring Metadata
                  </li>
                </ol>
              </nav>
              <Link
                className="ymca-title-link"
                to="/metadata-manager/dashboard"
              >
                <img src={BackIcon} className="me-1 mb-2" alt="back-icon" />
                Back
              </Link>
            </div>
            <h5 className="ymca-sub-heading mb-0">Add Scoring Metadata</h5>
          </div>
          <hr />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            enableReinitialize
          >
            {({ setFieldValue, values }) => {
              const {
                dataDomainId,
                year,
                formId,
                subformId,
                category1Id,
                category2Id,
                category3Id
              } = values;

              useEffect(() => {
                if (dataDomainId && year) {
                  setFieldValue('category1Id', '');
                  loadForms(dataDomainId, year);
                }
              }, [dataDomainId, year]);

              useEffect(() => {
                if (formId && subformId) loadCategories(formId, subformId);
              }, [formId, subformId]);
              return (
                <Form autoComplete="off" className="ymca-wrapper">
                  <div className="container border-wrapper">
                    <div className="row ymca-form">
                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="form-group">
                          <label htmlFor="ddlDoamins" className="form-label">
                            Data Domain{' '}
                            <span className="text-mandatory ms-1">*</span>
                          </label>
                          <Field
                            as="select"
                            name="dataDomainId"
                            className="form-select"
                            aria-label="Default select example"
                            onChange={e =>
                              handledataDomain(e.target.value, setFieldValue)
                            }
                          >
                            <option value=""> Select </option>
                            {listOfDataDomains.map(item => (
                              <option value={item.dataDomainId}>
                                {item.dataDomainName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component={TextError}
                            name="dataDomainId"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="form-group">
                          <label htmlFor="ddlYears" className="form-label">
                            Year<span className="text-mandatory ms-1">*</span>
                          </label>
                          <Field
                            as="select"
                            name="year"
                            className="form-select"
                            aria-label="Default select example"
                            //  onChange={e => handleForm(e, setFieldValue)}
                          >
                            <option value=""> Select</option>
                            {listOfYears.map(item => (
                              <option value={item}>{item}</option>
                            ))}
                          </Field>
                          <ErrorMessage component={TextError} name="year" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="form-group">
                          <label htmlFor="ddlForms" className="form-label">
                            Form Name
                            <span className="text-mandatory ms-1">*</span>
                          </label>
                          <Field
                            as="select"
                            name="formId"
                            className="form-select"
                            aria-label="Default select example"
                            onChange={e =>
                              handleForm(e.target.value, setFieldValue)
                            }
                          >
                            <option value=""> Select </option>
                            {listOfForms.map(item => (
                              <option
                                value={item.sequenceNo}
                                formId={item.formId}
                              >
                                {item.formName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage component={TextError} name="formId" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="form-group">
                          <label htmlFor="ddlSubForms" className="form-label">
                            Sub Form Name
                            <span className="text-mandatory ms-1">*</span>
                          </label>
                          <Field
                            as="select"
                            name="subformId"
                            className="form-select"
                            aria-label="Default select example"
                            onChange={e =>{
                              handleSubForm(e.target.value, setFieldValue)
                              getEvalDataBySubform(values,e.target.value)
                              handleScoreFields(null,setFieldValue)
                            }
                             
                            }
                          >
                            <option value=""> Select </option>
                            {listOfSubForms &&
                              listOfSubForms?.map(item => (
                                <option value={item.sequenceNo}>
                                  {item.subFormName}
                                </option>
                              ))}
                          </Field>
                          <ErrorMessage
                            component={TextError}
                            name="subformId"
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="form-group">
                          <label htmlFor="ddlCategroy1" className="form-label">
                            Category 1
                            <span className="text-mandatory ms-1">*</span>
                          </label>
                          <Field
                            as="select"
                            name="category1Id"
                            className="form-select"
                            aria-label="Default select example"
                            onChange={e =>
                              handleCategory2List(e.target.value, setFieldValue,values)
                            }
                          >
                            <option value=""> Select </option>
                            {listOfCategory1.map(item => (
                              <option value={item.category1SequenceNo}>
                                {item.category1Name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component={TextError}
                            name="category1Id"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="form-group">
                          <label htmlFor="ddlCategroy2" className="form-label">
                            Category 2
                          </label>
                          <Field
                            as="select"
                            name="category2Id"
                            className="form-select"
                            aria-label="Default select example"
                            onChange={e =>
                              handleCategory3List(e.target.value, setFieldValue,values)
                            }
                          >
                            <option value=""> Select </option>
                            {listOfCategory2 &&
                              listOfCategory2?.map(item => (
                                <option value={item.category2SequenceNo}>
                                  {item.category2Name}
                                </option>
                              ))}
                          </Field>
                          <ErrorMessage
                            component={TextError}
                            name="category2Id"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="form-group">
                          <label htmlFor="ddlCategroy3" className="form-label">
                            Category 3
                          </label>
                          <Field
                            as="select"
                            name="category3Id"
                            className="form-select"
                            aria-label="Default select example"
                            onChange={e =>{
                              handle3Form(e.target.value, setFieldValue,values)
                            }
                             
                            }
                          >
                            <option value=""> Select </option>
                            {listOfCategory3 &&
                              listOfCategory3?.map(item => (
                                <option value={item.category3SequenceNo}>
                                  {item.category3Name}
                                </option>
                              ))}
                          </Field>
                          <ErrorMessage
                            component={TextError}
                            name="category3Id"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3"></div>
                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="form-group">
                          <label htmlFor="ddlEvalGroup" className="form-label">
                            Eval Group
                            <span className="text-mandatory ms-1">*</span>
                          </label>
                          
                          <Field
                            as="select"
                            name="evelGroup"
                            className="form-select"
                            aria-label="Default select example"
                            disabled={selectedScoreData && selectedScoreData?.evalGroup}
                            //  onChange={e => handleForm(e, setFieldValue)}
                          >
                            <option value=""> Select </option>
                            {category1Id && (
                              <option value={1}>Category 1</option>
                            )}
                            {category2Id && (
                              <option value={2}>Category 2</option>
                            )}
                            {category3Id && (
                              <option value={3}>Category 3</option>
                            )}
                          </Field>
                          <ErrorMessage
                            component={TextError}
                            name="evelGroup"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="form-group">
                          <label
                            htmlFor="ddlEvalGroupType"
                            className="form-label"
                          >
                            Eval Group Type
                            <span className="text-mandatory ms-1">*</span>
                          </label>
                          <Field
                            as="select"
                            name="dataSegmentType"
                            className="form-select"
                            aria-label="Default select example"
                            disabled={selectedScoreData && selectedScoreData?.evalGroupType}
                            //  onChange={e => handleForm(e, setFieldValue)}
                          >
                            <option value=""> Select </option>
                            {listOfSegmentTypes.map(item => (
                              <option value={item?.replace("-","_")}>{item?.replace("-","_")}</option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component={TextError}
                            name="dataSegmentType"
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="form-group">
                          <label
                            htmlFor="txtDataPointScore"
                            className="form-label"
                          >
                            Min Points Required
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            id="txtDataPointScore"
                            name="minPointsReq"
                            placeholder="Enter"
                            disabled={selectedScoreData && selectedScoreData?.minPointsReq}
                          />
                          <ErrorMessage
                            component={TextError}
                            name="minPointsReq"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="form-group">
                          <label
                            htmlFor="txtDataEvalMin"
                            className="form-label"
                          >
                            Earned Score
                            <span className="text-mandatory ms-1">*</span>
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            id="txtDataEvalMin"
                            name="earnedScore"
                            placeholder="Enter"
                            disabled={selectedScoreData && selectedScoreData?.earnedScore}
                          />
                          <ErrorMessage
                            component={TextError}
                            name="earnedScore"
                          />
                        </div>
                      </div>
                    </div>
                    {metadataId && (
                      <div className="updated-by-section">
                        <div>
                          Last Updated By:
                          <b className="ms-1">
                            {state.updatedBy || state.createdBy}
                          </b>
                        </div>
                        <div className="ms-3">
                          On:
                          <b className="ms-1">
                            {moment
                              .utc(state.updatedDttm)
                              .local()
                              .format('DD MMM YYYY, hh:mm a') ||
                              moment
                                .utc(state.createdDttm)
                                .local()
                                .format('DD MMM YYYY, hh:mm a')}
                          </b>
                        </div>
                      </div>
                    )}

                    <div className="d-flex justify-content-end mt-4">
                      <Link
                        className="btn ymca-primary-outline-btn"
                        type="button"
                        to="/metadata-manager/dashboard"
                      >
                        Cancel
                      </Link>
                      <button
                        className="btn ymca-primary-btn ms-3"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <hr />
          <div className="container">
            <div className="row align-items-center mt-3 mb-3">
              <div className="col-lg-12">
                <div className="ymca-sub-heading ps-3">
                  {' '}
                  Sub Form Scoring Metadata
                </div>
              </div>
            </div>
            <Table rows={scoringData} headers={METADATA_SCORING_COLUMNS()} />
          </div>
        </main>
      </div>

      <CustomModal
        show={showSuccess}
        type={APP_LABELS.SUCCESS}
        message={META_DATA.ADD_META_DATA_SUCCESS}
        submitButtonName="Ok"
        onSubmit={() => updateState({ showSuccess: false })}
      />

      <CustomModal
        show={showFailure}
        type={APP_LABELS.ERROR}
        message={errorMessage || APP_LABELS.ERROR_MESSAGE}
        submitButtonName="Ok"
        onSubmit={() => updateState({ showFailure: false })}
      />
    </BlockUi>
  );
};

ScoringMetaData.propTypes = {
  handleGetDomains: PropTypes.func.isRequired,
  handleGetYears: PropTypes.func.isRequired,
  handleGetDashboardForms: PropTypes.func.isRequired,
  handleGetCategoryMappings: PropTypes.func.isRequired,
  handleGetSegmentTypes: PropTypes.func.isRequired,
  handleAddMetadata: PropTypes.func.isRequired,
  handleUpdateMetadata: PropTypes.func.isRequired,
  handleAddMetadataScoring: PropTypes.func.isRequired,
  handleGetEvalDataBySubform: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetDomains: getDomains,
  handleGetYears: getYears,
  handleGetDashboardForms: getDashboardForms,
  handleGetSegmentTypes: getSegmentTypes,
  handleAddMetadata: addMetadata,
  handleUpdateMetadata: updateMetadata,
  handleGetCategoryMappings: getCategoryMappingBySubForms,
  handleAddMetadataScoring: addMetadataScoring,
  handleGetEvalDataBySubform:getEvalDataBySubform,
  handleGetEvalDataByIds:getEvalDataByIds
};

export default connect(null, mapDispatchToProps)(ScoringMetaData);
