import React from 'react';
import viewIconBlue from '@static/images/view-blue-icon.svg';
import moment from 'moment';

export const METADATA_REVIEW_COLUMNS = ({ handleViewClick, isValid }) => [
  {
    Header: 'Data Domain',
    accessor: 'dataDomainName',
    className: 'notranslate'
  },
  {
    Header: 'Form Name',
    accessor: 'formName',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Subform Name',
    accessor: 'subformName',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Category 1',
    accessor: 'category1Name',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Category 2',
    accessor: 'category2Name',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Category 3',
    accessor: 'category3Name',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  ...(isValid
    ? [
        {
          Header: 'Error Summary',
          accessor: 'errorSummary',
          className: 'notranslate ymca-word-break ymca-ellipse',
          Cell: v => (
            <>
              {v?.value?.split(',').map(res => {
                return <p className="m-0 p-0 ymca-warning-text">{res}</p>;
              })}
            </>
          )
        }
      ]
    : []),
  {
    Header: 'Action',
    accessor: 'metaDataFileUploadMappingId',
    Cell: v => (
      <>
        <a
          className="table-link"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          tabIndex={0}
          onKeyDown={e => e.preventDefault()}
          onClick={() => handleViewClick(v)}
        >
          <img src={viewIconBlue} className="img-fluid " alt="view-icon-img" />
        </a>
      </>
    )
  }
];

export const METADATA_REVIEW_DETAIL = () => [
  {
    label: 'Form ID',
    accessor: 'formID',
    className: 'notranslate'
  },
  {
    label: 'Subform ID',
    accessor: 'subformID',
    className: 'notranslate'
  },
  {
    label: 'Form Name',
    accessor: 'formName',
    className: 'notranslate'
  },
  {
    label: 'Subform Name',
    accessor: 'subformName',
    className: 'notranslate'
  },
  {
    label: 'Year',
    accessor: 'year',
    className: 'notranslate'
  },

  {
    label: 'Category 1 Name',
    accessor: 'category1Name',
    className: 'notranslate'
  },
  {
    label: 'Category 1 ID',
    accessor: 'category1ID',
    className: 'notranslate'
  },
  {
    label: 'Category 2 Name',
    accessor: 'category2Name',
    className: 'notranslate'
  },
  {
    label: 'Category 2 ID',
    accessor: 'category2ID',
    className: 'notranslate'
  },
  {
    label: 'Category 3 Name',
    accessor: 'category3Name',
    className: 'notranslate'
  },
  {
    label: 'Category 3 ID',
    accessor: 'category3ID',
    className: 'notranslate'
  },

  {
    label: 'Data Domain ID',
    accessor: 'dataDomainID',
    className: 'notranslate'
  },
  {
    label: 'Data Domain',
    accessor: 'dataDomainName',
    className: 'notranslate'
  },
  {
    label: 'Data Point ID',
    accessor: 'dataPointID',
    className: 'notranslate'
  },
  {
    label: 'Data Point Description',
    accessor: 'dataPointDescription',
    className: 'notranslate'
  },
  {
    label: 'Data Point Type',
    accessor: 'dataPointType',
    className: 'notranslate'
  },
  {
    label: 'Data Point Granularity',
    accessor: 'dataPointGranularity',
    className: 'notranslate'
  },
  {
    label: 'Source Mapping',
    accessor: 'sourceMapping',
    className: 'notranslate'
  },
  {
    label: 'Target Mapping',
    accessor: 'targetMapping',
    className: 'notranslate'
  },
  {
    label: 'Data Segment Type',
    accessor: 'dataSegmentType',
    className: 'notranslate'
  },
  {
    label: 'Data Points',
    accessor: 'dataPoints',
    className: 'notranslate'
  },
  {
    label: 'Min Points Required',
    accessor: 'minPointsReq',
    className: 'notranslate'
  },
  {
    label: 'Data Evaluation ID',
    accessor: 'dataEvalID',
    className: 'notranslate'
  },
  {
    label: 'Qualtrics ID',
    accessor: 'qualtricsID',
    className: 'notranslate'
  },
  {
    label: 'Form Points Possible',
    accessor: 'formPointsPossible',
    className: 'notranslate'
  }
  // {
  //   label: 'Error Summary',
  //   accessor: 'errorSummary',
  //   className: 'notranslate'
  // }
];
