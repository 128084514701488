import * as types from './types';

const initialState = {
  dataScoringAssociations: {},
  dataScoringFormView: {},
  dataScoringSubFormView: {},
  dataScoringEvaluationGroupView: {},
  dataScoringEvaluationDataPointsView: {}
};

export default function clientsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_METADATA_DATASCORING_SUCCESS: {
      return {
        ...state,
        dataScoringAssociations: action.payload
      };
    }
    case types.GET_METADATA_DATASCORING_FAILURE:
      return { ...state, error: action.payload };
    case types.GET_METADATA_DATASCORING_FORM_VIEW_SUCCESS: {
      return {
        ...state,
        dataScoringFormView: action.payload
      };
    }
    case types.GET_METADATA_DATASCORING_FORM_VIEW_FAILURE:
      return { ...state, error: action.payload };
    case types.GET_METADATA_DATASCORING_SUBFORM_VIEW_SUCCESS: {
      return {
        ...state,
        dataScoringSubFormView: action.payload
      };
    }
    case types.GET_METADATA_DATASCORING_SUBFORM_VIEW_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_METADATA_DATASCORING_EVALUATION_GROUP_VIEW_SUCCESS: {
      return {
        ...state,
        dataScoringEvaluationGroupView: action.payload
      };
    }
    case types.GET_METADATA_DATASCORING_EVALUATION_GROUP_VIEW_FAILURE:
      return { ...state, error: action.payload };

    case types.GET_METADATA_DATASCORING_DATAPOINTS_VIEW_SUCCESS: {
      return {
        ...state,
        dataScoringEvaluationDataPointsView: action.payload
      };
    }
    case types.GET_METADATA_DATASCORING_DATAPOINTS_VIEW_FAILURE:
      return { ...state, error: action.payload };

    default:
      return state;
  }
}
