import React from 'react';
import moment from 'moment';

import PropTypes from 'prop-types';
import Modal from '@modules/shared/components/reusable-components/model-popup';
import {
  METADATA_DASHBOARD_NOOFUPLOAD_COLUMNS,
  METADATA_DETAIL_FIELDS
} from './table-columns';
import Table from './table';

const DashboardViewDetails = ({ row, activeKpi }) => {
  const getRowDate = () => {
    if (row.validTo)
      return `${row.updatedBy ?? row.createdBy} - ${moment
        .utc(row.validTo)
        .local()
        .format('DD MMM YYYY, hh:mm a')}`;

    if (row.updatedDttm)
      return `${row.updatedBy || ''} on ${moment
        .utc(row.updatedDttm)
        .local()
        .format('DD MMM YYYY, hh:mm a')}`;

    if (row.createdDttm)
      return `${row.createdBy || ''}  ${moment
        .utc(row.createdDttm)
        .local()
        .format('DD MMM YYYY, hh:mm a')}`;
    return <>-</>;
  };

  const renderFields = () => {
    return (
      <>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Form ID</div>
          <div className="ymca-modal-content notranslate">
            {row.formId || '-'}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Sub Form ID</div>
          <div className="ymca-modal-content notranslate">
            {row.subformId || '-'}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Last Updated</div>
          <div className="ymca-modal-content notranslate">{getRowDate()}</div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Form Name</div>
          <div className="ymca-modal-content notranslate">
            {row.formName || '-'}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Sub Form Name</div>
          <div className="ymca-modal-content notranslate">
            {row.subformName || '-'}
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-12 p-0">
          <hr />
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Category1 ID</div>
          <div className="ymca-modal-content notranslate">
            {row.category1Id || '-'}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Category2 ID</div>
          <div className="ymca-modal-content notranslate">
            {row.category2Id || '-'}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Category3 ID</div>
          <div className="ymca-modal-content notranslate">
            {row.category3Id || '-'}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Category1 Name</div>
          <div className="ymca-modal-content notranslate">
            {row.category1Name || '-'}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Category2 Name</div>
          <div className="ymca-modal-content notranslate">
            {row.category2Name || '-'}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Category3 Name</div>
          <div className="ymca-modal-content notranslate">
            {row.category3Name || '-'}
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-12 p-0">
          <hr />
        </div>

        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Data Point Type</div>
          <div className="ymca-modal-content notranslate">
            {row.dataPointType || '-'}
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Data Point ID</div>
          <div className="ymca-modal-content notranslate">
            {row.dataPointId || '-'}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Data Point Score</div>
          <div className="ymca-modal-content notranslate">
            {row.dataPoints || '-'}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Data Eval Group Type</div>
          <div className="ymca-modal-content notranslate">
            {row.dataSegmentType || '-'}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Data Eval Group ID</div>
          <div className="ymca-modal-content notranslate">
            {row.dataEvalId || '-'}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Data Eval Group Min Score</div>
          <div className="ymca-modal-content notranslate">
            {row.minPointsReq || '-'}
          </div>
        </div>

        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">QualtricsID</div>
          <div className="ymca-modal-content notranslate">
            {row.qualtricsId || '-'}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Form Points Possible</div>
          <div className="ymca-modal-content notranslate">
            {row.formPointsPossible || '-'}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Earned Score</div>
          <div className="ymca-modal-content notranslate">
            {row.earnedScore || '-'}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Data Source Mapping</div>
          <div className="ymca-modal-content notranslate">
            {row.sourceMapping || '-'}
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <div className="ymca-bold-heading">Data Target Mapping</div>
          <div className="ymca-modal-content notranslate">
            {row.targetMapping || '-'}
          </div>
        </div>
      </>
    );
  };

  let items = null;
  if (activeKpi === 2) {
    items =
      Array.isArray(row) && row.length > 0 ? (
        <Table rows={row} headers={METADATA_DASHBOARD_NOOFUPLOAD_COLUMNS()} />
      ) : (
        <></>
      );
  } else {
    items = renderFields();
  }

  return (
    <Modal
      popupTitle="View Details"
      showClose="true"
      classNames="metadata-modal"
    >
      {items}
    </Modal>
  );
};

DashboardViewDetails.propTypes = {
  row: PropTypes.node.isRequired,
  activeKpi: PropTypes.string.isRequired
};

export default DashboardViewDetails;
