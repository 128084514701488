import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { getImportData } from '@redux/import-data/actions';
import { UploadFileSelector } from '@redux/upload-file/selectors';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import KpiCard from '@modules/shared/components/reusable-components/kpi-card';
import DetailsCard from '@modules/shared/components/reusable-components/details-card';
import { APP_LABELS } from '@config/app-config';
import Help from '@modules/shared/components/reusable-components/help';

import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import BackIcon from '@static/images/back-icon.svg';
import TotalStaffPurpleIcon from '@static/images/total-staff-purple-icon.svg';
import TotalStaffWhiteIcon from '@static/images/total-staff-white-icon.svg';
import totalstaffvoileticon from '@static/images/total-staff-voilet-icon.svg';
import invalidrecordvoileticon from '@static/images/invalid-record-voilet-icon.svg';
import unchangedpinkicon from '@static/images/un-changed-pink-icon.svg';
import UnChangedwhiteicon from '@static/images/Un-Changed-white-icon.svg';
import newstaffyellowicon from '@static/images/new-staff-yellow-icon.svg';
import newstaffwhiteicon from '@static/images/new-staff-white-icon.svg';
import existingstaffblueicon from '@static/images/existing-staff-blue-icon.svg';
import existingstaffwhiteicon from '@static/images/existing-staff-white-icon.svg';
import expiredstafforangeicon from '@static/images/expired-staff-orange-icon.svg';
import terminatedstaffwhiteicon from '@static/images/terminated-staff-white-icon.svg';
import warningiconmedium from '@static/images/warning-icon-medium.svg';
import helpicon from '@static/images/caretdoubleright.svg';
import graywarningicon from '@static/images/gray-warning-icon.svg';
import convertUtcToLocal from '@utilities/ConvertTimezone';
import CustomModal from '@utilities/CustomModal';
import ViewDetailsPopup from '@modules/shared/components/reusable-components/view-details-popup';
import ErrorPopup from '../upload-file/error-popup';
import NewStaffColumns from './new-staff-columns';
import InvalidColumns from './invalid-columns';
import ExistingStaffColumns from './existing-staff-column';
import TerminatedStaffRoleAssignment from './terminated-staff-role-assignment';
import Table from './Table';

const UploadData = props => {
  const { UploadStaffData, handleImportData, location } = props;
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [instructions, setInstructions] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showViewDetailsPopup, setShowViewDetailsPopup] = useState(false);
  const comment = '';
  const [checkBox, setCheckBox] = useState(false);
  const [enableButton, setEnableButton] = useState(true);
  const selectedRows = {};
  const selectedRowsChangeStaff = {};
  const [key, setKey] = useState('newstaff');
  const [newStaffViewDetails, setNewStaffViewDetails] = useState();
  const [existingStaffViewDetails, setExistingStaffViewDetails] = useState();
  const [changedStaffViewDetails, setChangedStaffViewDetails] = useState();
  const [terminatedStaffViewDetails, setTerminatedStaffViewDetails] =
    useState();
  const [totalStaffDetails, setTotalStaffDetails] = useState();
  const [invalidStaffViewDetails, setInvalidStaffViewDetails] = useState();
  const [popupData, setPopupData] = useState(newStaffViewDetails);
  const [popupTitle, setPopupTitle] = useState('New Staff Details');
  const [redirect, setRedirect] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const selectableRows = {};
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showMessage, setShowMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [help, setHelp] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const invalidCount = get(
    UploadStaffData,
    'data.segregatedList.InvalidStaff',
    []
  ).length;

  const [showTotalStaff, setShowTotalStaff] = useState('');
  const [showNewStaff, setShowNewStaff] = useState('show active');
  const [showExistingStaff, setShowExistingStaff] = useState('');
  const [showChangedstaff, setShowChangedstaff] = useState('');
  const [showInvalidstaff, setShowInvalidstaff] = useState('');
  const [
    showTerminatedstaffroleassignment,
    setShowTerminatedstaffroleassignment
  ] = useState('');
  const [showAllSeperatedPartTimeStaff, setShowAllSeperatedPartTimeStaff] =
    useState(false);
  const [activeTotalStaff, setActiveTotalStaff] = useState('');
  const [activeNewStaff, setActiveNewStaff] = useState('active');
  const [activeExistingStaff, setActiveExistingStaff] = useState('');
  const [activeChangedstaff, setActiveChangedstaff] = useState('');
  const [activeInvalidstaff, setActiveInvalidstaff] = useState('');
  const [
    activeTerminatedstaffroleassignment,
    setActiveTerminatedstaffroleassignment
  ] = useState('');
  const [showAttentionPopup, setShowAttentionPopup] = useState(false);
  const handleViewDetailsPopup = v => {
    setPopupData(v);
  };

  const handleCloseNewStaffPopup = () => setShowViewDetailsPopup(false);
  const handleShowHelp = () => {
    setHelp(true);
  };
  const hideShowHelp = () => {
    setHelp(false);
  };
  const getStaffTypeLabel = staffTypeKey => {
    const staffType = APP_LABELS.STAFF_TYPE_ENUMS.find(
      type => type.staffKey === staffTypeKey
    );
    return staffType?.staffLabel;
  };
  const getMessage = () => {
    let data = get(UploadStaffData, 'data.staffSeperationSummary');
    const label = getStaffTypeLabel(get(data, 'staffType', ''));
    const partTime = get(data, 'partTimeStaffCount', '') || 0;
    const fullTime = get(data, 'fullTimeStaffCount', '') || 0;
    const boldText = `separating ${fullTime} full-time and ${partTime} part-time/seasonal/temporary staff.`;
    const unBoldText1 = `You selected that you are uploading a file that ${
      !label
        .toString()
        .toLowerCase()
        .includes('all staff (full-time and part-time/seasonal/temporary)')
        ? `only`
        : ''
    } includes ${label.toString().toLowerCase()} ${
      label.toString().toLowerCase().includes('part-time/seasonal/temporary')
        ? 'staff'
        : ''
    }. You will be `;
    const unBoldText2 = ` I understand and will review and verify before importing my data.`;
    return (
      <div>
        <p>
          <span className="ymca-modal-content"> {unBoldText1}</span>
          <span className="ymca-bold-heading">{boldText}</span>
          <span className="ymca-modal-content"> {unBoldText2}</span>
        </p>
      </div>
    );
  };
  useEffect(() => {
    let IsBlankElement = false;
    get(UploadStaffData, 'data.segregatedList.NewStaff', []).map(v => {
      if (v.gender === null || v.raceEthnicity === null) {
        IsBlankElement = true;
      }
    });

    get(UploadStaffData, 'data.segregatedList.ChangedStaff', []).map(v => {
      if (v.gender === null || v.raceEthnicity === null) {
        IsBlankElement = true;
      }
    });
    setIsEmpty(IsBlankElement);
    const NewStaffViewDetailsRow = get(
      UploadStaffData,
      'data.segregatedList.NewStaff',
      []
    ).map(v => ({
      ...v,
      name: `${v.firstName} ${v.lastName}`,
      viewDetails: (
        <button
          type="button"
          onClick={() => handleViewDetailsPopup(v)}
          className="btn btn-link table-link p-0 custom-align"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          {APP_LABELS.DASH_VIEW_DETAILS}
        </button>
      )
    }));
    setNewStaffViewDetails(NewStaffViewDetailsRow);

    const ChangedStaffRow = get(
      UploadStaffData,
      'data.segregatedList.ChangedStaff',
      []
    ).map(v => ({
      ...v,
      name: `${v.firstName} ${v.lastName}`,
      displayStaffRecordType: (
        <span
          className={`${
            v.staffRecordType === 'ChangedStaff'
              ? 'warning-badge'
              : 'success-badge'
          }`}
        >
          {v.displayStaffRecordType}
        </span>
      ),
      viewDetails: (
        <button
          type="button"
          onClick={() => handleViewDetailsPopup(v)}
          className="btn btn-link table-link p-0 custom-align"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          {APP_LABELS.DASH_VIEW_DETAILS}
        </button>
      )
    }));
    setChangedStaffViewDetails(ChangedStaffRow);

    const ExistingStaffViewDetailsRow = get(
      UploadStaffData,
      'data.segregatedList.UnChangedStaff',
      []
    ).map(v => ({
      ...v,
      name: `${v.firstName} ${v.lastName}`,
      displayStaffRecordType: (
        <span
          className={`${
            v.staffRecordType === 'ChangedStaff'
              ? 'warning-badge'
              : 'success-badge'
          }`}
        >
          {v.displayStaffRecordType}
        </span>
      ),
      viewDetails: (
        <button
          type="button"
          onClick={() => handleViewDetailsPopup(v)}
          className="btn btn-link table-link p-0 custom-align"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          {APP_LABELS.DASH_VIEW_DETAILS}
        </button>
      )
    }));
    setExistingStaffViewDetails(ExistingStaffViewDetailsRow);

    const TerminatedStaffViewDetailsRow = get(
      UploadStaffData,
      'data.segregatedList.TerminatedStaff',
      []
    ).map(v => ({
      ...v,
      name: `${v.firstName} ${v.lastName}`,
      displayStaffRecordType: (
        <span
          className={`${
            v.staffRecordType === 'StaffRoleChangedStaff'
              ? 'success-badge'
              : 'warning-badge'
          }`}
        >
          {v.displayStaffRecordType}
        </span>
      ),
      viewDetails: (
        <button
          type="button"
          onClick={() => handleViewDetailsPopup(v)}
          className="btn btn-link table-link p-0 custom-align"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          {APP_LABELS.DASH_VIEW_DETAILS}
        </button>
      )
    }));
    setTerminatedStaffViewDetails(TerminatedStaffViewDetailsRow);

    const InvalidStaffViewDetailsRow = get(
      UploadStaffData,
      'data.segregatedList.InvalidStaff',
      []
    ).map(v => ({
      ...v,
      name: `${v.firstName} ${v.lastName}`,
      errorSummary: v.errorSummary
        .split(',')
        .map(item => <div key={item}>{item}</div>),
      viewDetails: (
        <button
          type="button"
          onClick={() => handleViewDetailsPopup(v)}
          className="btn btn-link table-link p-0 custom-align"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          {APP_LABELS.DASH_VIEW_DETAILS}
        </button>
      )
    }));
    setInvalidStaffViewDetails(InvalidStaffViewDetailsRow);
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'employee-update-review'
      ]?.Instruction
    );

    const totalStaffDetailsRow = NewStaffViewDetailsRow.concat(
      ChangedStaffRow,
      ExistingStaffViewDetailsRow,
      InvalidStaffViewDetailsRow
    );
    setTotalStaffDetails(totalStaffDetailsRow);
    setShowWarningPopup(location?.state?.showWarningPopup);
  }, []);

  useEffect(() => {
    if (key === 'totalstaff') {
      setShowTotalStaff('show active');
      setShowNewStaff('');
      setShowExistingStaff('');
      setShowChangedstaff('');
      setShowInvalidstaff('');
      setShowTerminatedstaffroleassignment('');

      setActiveTotalStaff('active');
      setActiveNewStaff('');
      setActiveExistingStaff('');
      setActiveChangedstaff('');
      setActiveInvalidstaff('');
      setActiveTerminatedstaffroleassignment('');

      setPopupTitle('Total Staff Details');
    } else if (key === 'newstaff') {
      setShowTotalStaff('');
      setShowNewStaff('show active');
      setShowExistingStaff('');
      setShowChangedstaff('');
      setShowInvalidstaff('');
      setShowTerminatedstaffroleassignment('');

      setActiveNewStaff('active');
      setActiveTotalStaff('');
      setActiveExistingStaff('');
      setActiveChangedstaff('');
      setActiveInvalidstaff('');
      setActiveTerminatedstaffroleassignment('');

      setPopupTitle('New Staff Details');
    } else if (key === 'existingstaff') {
      setShowTotalStaff('');
      setShowNewStaff('');
      setShowExistingStaff('show active');
      setShowChangedstaff('');
      setShowInvalidstaff('');
      setShowTerminatedstaffroleassignment('');

      setActiveNewStaff('');
      setActiveTotalStaff('');
      setActiveExistingStaff('active');
      setActiveChangedstaff('');
      setActiveInvalidstaff('');
      setActiveTerminatedstaffroleassignment('');

      setPopupTitle('Un-Changed Staff Details');
    } else if (key === 'changedstaff') {
      setShowTotalStaff('');
      setShowNewStaff('');
      setShowExistingStaff('');
      setShowChangedstaff('show active');
      setShowTerminatedstaffroleassignment('');
      setShowInvalidstaff('');

      setActiveNewStaff('');
      setActiveTotalStaff('');
      setActiveExistingStaff('');
      setActiveChangedstaff('active');
      setActiveTerminatedstaffroleassignment('');
      setActiveInvalidstaff('');
      setPopupTitle('Changed Staff Details');
    } else if (key === 'terminatedstaffroleassignment') {
      setShowTotalStaff('');
      setShowNewStaff('');
      setShowExistingStaff('');
      setShowChangedstaff('');
      setShowInvalidstaff('');
      setShowTerminatedstaffroleassignment('show active');

      setActiveNewStaff('');
      setActiveTotalStaff('');
      setActiveExistingStaff('');
      setActiveChangedstaff('');
      setActiveInvalidstaff('');
      setActiveTerminatedstaffroleassignment('active');
      setPopupTitle('Seperated Staff Details');
    } else if (key === 'invaliddata') {
      setShowTotalStaff('');
      setShowNewStaff('');
      setShowExistingStaff('');
      setShowChangedstaff('');
      setShowInvalidstaff('show active');
      setShowTerminatedstaffroleassignment('');

      setActiveNewStaff('');
      setActiveTotalStaff('');
      setActiveExistingStaff('');
      setActiveChangedstaff('');
      setActiveInvalidstaff('active');
      setActiveTerminatedstaffroleassignment('');

      setPopupTitle('Invalid Staff Details');
    }
  }, [key, showViewDetailsPopup]);

  const redirectToDashBoard = () => {
    setRedirectUrl('/employee/dashboard');
    setRedirect(true);
  };

  const onSelectedRowsChange = row => {
    const newRows = row?.map(data => {
      const newRow = data.original;
      return newRow;
    });
    selectableRows.newStaff = newRows;
  };

  const onSelectedRowsExistingStaff = row => {
    const newRows = row?.map(data => {
      const newRow = data.original;
      return newRow;
    });
    selectableRows.changeStaff = newRows;
  };
  const confirmImportData = () => {
    setShowAllSeperatedPartTimeStaff(false);
    const finalData = [
      ...get(UploadStaffData, 'data.segregatedList.InvalidStaff', []),
      ...get(UploadStaffData, 'data.segregatedList.NewStaff', []),
      ...get(UploadStaffData, 'data.segregatedList.ChangedStaff', []),
      ...get(UploadStaffData, 'data.segregatedList.UnChangedStaff', []),
      ...get(UploadStaffData, 'data.segregatedList.TerminatedStaff', [])
    ];
    const partTimeStaff = finalData.filter(
      staff => staff.staffType === 'Part-Time/Seasonal/Temporary'
    );
    const partTimeTerminatedStaff = finalData.filter(
      staff =>
        staff.staffType === 'Part-Time/Seasonal/Temporary' &&
        staff.staffRecordType === 'TerminatedStaff'
    );
    if (
      partTimeTerminatedStaff.length > 0 &&
      partTimeStaff?.length === partTimeTerminatedStaff?.length
    ) {
      setShowAllSeperatedPartTimeStaff(true);
    }
    setShowAttentionPopup(true);
  };

  const postImportDataFile = () => {
    setShowAttentionPopup(false);
    setEnableButton(true);
    setLoader(true);
    const finalData = [
      ...get(UploadStaffData, 'data.segregatedList.InvalidStaff', []),
      ...get(UploadStaffData, 'data.segregatedList.NewStaff', []),
      ...get(UploadStaffData, 'data.segregatedList.ChangedStaff', []),
      ...get(UploadStaffData, 'data.segregatedList.UnChangedStaff', []),
      ...get(UploadStaffData, 'data.segregatedList.TerminatedStaff', [])
    ];
    const rowData = finalData?.map(row => {
      if (
        row.staffRecordType === 'NewStaff' ||
        row.staffRecordType === 'ChangedStaff'
      ) {
        return { ...row, isRecordSelected: true };
      }
      return { ...row, isRecordSelected: false };
    });

    const obj = {
      empDataImport: rowData,
      comment
    };

    handleImportData(obj).then(res => {
      if (res.status === 200) {
        if (res.data?.success === 'Success') {
          setEnableButton(false);
          setShowSuccessPopup(true);
          setShowMessage(res.data?.message);
        } else {
          setEnableButton(false);
          setShowErrorPopup(true);
          setShowMessage(res.data?.message);
        }
      } else {
        setShowErrorPopup(true);
        setShowMessage(res?.message);
      }
      setLoader(false);
    });
  };

  const handelCheckBox = event => {
    setCheckBox(true);

    if (invalidCount === 0 && event.target.checked) {
      setEnableButton(false);
    } else {
      setEnableButton(true);
    }
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false);
    setRedirectUrl('/employee/dashboard/upload/file');
    setRedirect(true);
  };
  const onkipclick = type => {
    setKey(type);
  };
  const TerminatedStaffTitle = (
    <span>
      {APP_LABELS.DETAIL_TERMINATED}
      <br />
      {APP_LABELS.DETAIL_ASSIGNMENT}
    </span>
  );

  return (
    <>
      <BlockUi
        blocking={loader}
        message="Loading, please wait"
        loader={<Loader active type="semi-circle-spin" />}
      >
        <main className="page-container">
          <button
            type="button"
            className="ymca-help-link text-decoration-none ymca-up-data-help-link  "
            onClick={handleShowHelp}
          >
            HELP{/* */}
            <img src={helpicon} className="img-fluid" alt="caret-icon" />
          </button>
          <div className="ymca-data-wrapper ymca-upload-data-wrapper">
            <div className="container">
              <div className="row mb-3 align-items-center">
                <div className="col-11">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb ymca-breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/employee/dashboard">
                          {APP_LABELS.NAV_DASHBOARD}
                        </a>
                        <img
                          src={BreadcrumbIcon}
                          className="breadcrumb-icon"
                          alt=""
                        />
                      </li>
                      <li className="breadcrumb-item active">
                        {APP_LABELS.DETAIL_UPLOAD_DATA}
                      </li>
                    </ol>
                  </nav>
                </div>
                <div className="col-1 text-end">
                  <Link
                    type="button"
                    to="/employee/dashboard/upload/file"
                    className="ymca-title-link d-flex align-items-center"
                  >
                    <img src={BackIcon} className="me-1" alt="back-icon" />
                    {APP_LABELS.DASH_BACK}
                  </Link>
                </div>
              </div>
              <div className="user-name bg-transparent d-flex align-items-center">
                <h2 className="ymca-sub-heading mt-3">
                  {APP_LABELS.UPLOAD_DATA_TITLE}
                </h2>
              </div>

              <div className="ymca-instructionBoxes mb-3">
                <h4 className="ymca-instructHeadline">
                  {APP_LABELS.INSTRUCTIONS}
                </h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: instructions
                  }}
                />
              </div>
              <section className="info-cards">
                <div className="row">
                  <DetailsCard
                    cardTitle="Association"
                    cardValue={get(
                      UploadStaffData,
                      'data.associationName',
                      'Association Name'
                    )}
                  />

                  <DetailsCard
                    cardTitle="Branch List"
                    cardValue={UploadStaffData?.data?.branches.map(data => (
                      <li key={data}>{data}</li>
                    ))}
                  />

                  <DetailsCard
                    cardTitle="Last Updated"
                    cardValue={convertUtcToLocal(
                      get(UploadStaffData, 'data.lastUpdatedDatetime')
                    )}
                  />
                </div>
              </section>
            </div>

            <hr className="custom-hr" />

            <section className="stats-data">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    {isEmpty && (
                      <div className="ymca-instructionBoxes mb-3 d-flex">
                        <img src={graywarningicon} alt="" className="pe-2" />
                        {/* */}
                        Fields have been left blank for one or more records in
                        the uploaded file, please provide gender and
                        race/ethnicity information.
                      </div>
                    )}
                  </div>
                  <div className="row g-1">
                    <div className="col-lg-auto col-md-6 col-sm-12">
                      <KpiCard
                        id="totalstaff-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#totalstaff"
                        role="tab"
                        ariacontrols="totalstaff"
                        ariaselected="true"
                        activeClass={activeTotalStaff}
                        darkIcon={TotalStaffPurpleIcon}
                        lightIcon={TotalStaffWhiteIcon}
                        cardClass="col-lg-2 "
                        cardHoverClass="w-auto associations  c-pointer"
                        onkipclick={() => onkipclick('totalstaff')}
                        cardName="Total Staff"
                        cardValue={get(
                          UploadStaffData,
                          'data.fileUploadSummary.totalStaff',
                          'Total Staff'
                        )}
                      />
                    </div>
                    <div className="col-lg-auto col-md-6 col-sm-12">
                      <KpiCard
                        activeClass={activeInvalidstaff}
                        id="invaliddata-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#invaliddata"
                        role="tab"
                        ariacontrols="invaliddata"
                        ariaselected="true"
                        darkIcon={totalstaffvoileticon}
                        lightIcon={invalidrecordvoileticon}
                        onkipclick={() => onkipclick('invaliddata')}
                        cardClass="col-lg-2"
                        cardHoverClass="w-auto branches c-pointer"
                        cardName="Errors"
                        cardValue={get(
                          UploadStaffData,
                          'data.fileUploadSummary.invalidStaff',
                          'Invalid'
                        )}
                      />
                    </div>
                    <div className="col-lg-auto col-md-6 col-sm-12">
                      <KpiCard
                        activeClass={activeExistingStaff}
                        id="existingstaff-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#existingstaff"
                        role="tab"
                        ariacontrols="existingstaff"
                        ariaselected="true"
                        darkIcon={unchangedpinkicon}
                        lightIcon={UnChangedwhiteicon}
                        onkipclick={() => onkipclick('existingstaff')}
                        cardClass="col-lg-2"
                        cardHoverClass="w-auto profiles c-pointer"
                        cardName="Un-Changed"
                        cardValue={get(
                          UploadStaffData,
                          'data.fileUploadSummary.unChangedStaff',
                          'Un-Changed'
                        )}
                      />
                    </div>
                    <div className="col-lg-auto col-md-6 col-sm-12">
                      <KpiCard
                        activeClass={activeChangedstaff}
                        id="changedstaff-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#changedstaff"
                        role="tab"
                        ariacontrols="changedstaff"
                        ariaselected="true"
                        darkIcon={existingstaffblueicon}
                        lightIcon={existingstaffwhiteicon}
                        onkipclick={() => onkipclick('changedstaff')}
                        cardClass="col-lg-2"
                        cardHoverClass="w-auto programs c-pointer"
                        cardName="Changed Staff"
                        cardValue={get(
                          UploadStaffData,
                          'data.fileUploadSummary.changedStaff',
                          'Changed Staff'
                        )}
                      />
                    </div>
                    <div className="col-lg-auto col-md-6 col-sm-12">
                      <KpiCard
                        activeClass={activeNewStaff}
                        id="newstaff-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#newstaff"
                        role="tab"
                        ariacontrols="newstaff"
                        ariaselected="true"
                        darkIcon={newstaffyellowicon}
                        lightIcon={newstaffwhiteicon}
                        onkipclick={() => onkipclick('newstaff')}
                        cardClass="col-lg-2"
                        cardHoverClass="w-auto new-staff c-pointer"
                        cardName="New Staff"
                        cardValue={get(
                          UploadStaffData,
                          'data.fileUploadSummary.newStaff',
                          'newStaff'
                        )}
                      />
                    </div>
                    <div className="col-lg-auto col-md-6 col-sm-12">
                      <KpiCard
                        activeClass={activeTerminatedstaffroleassignment}
                        id="terminatedstaffroleassignment-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#terminatedstaffroleassignment"
                        role="tab"
                        ariacontrols="terminatedstaffroleassignment"
                        ariaselected="true"
                        darkIcon={expiredstafforangeicon}
                        lightIcon={terminatedstaffwhiteicon}
                        onkipclick={() =>
                          onkipclick('terminatedstaffroleassignment')
                        }
                        cardClass="col-lg-2"
                        cardHoverClass="w-auto staff-assign c-pointer"
                        cardName={TerminatedStaffTitle}
                        cardValue={get(
                          UploadStaffData,
                          'data.fileUploadSummary.terminatedStaffRoleAssignment',
                          'terminatedStaffRoleAssignment'
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="ymca-tabs">
              <div className="container">
                <div className="tab-content" id="myTabContent">
                  <div
                    className={`tab-pane fade ${showTotalStaff}`}
                    id="totalstaff"
                    role="tabpanel"
                    aria-labelledby="totalstaff-tab"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <h3 className="ymca-section-heading my-3 ms-3">
                          {APP_LABELS.DETAIL_TOTAL_STAFF}
                        </h3>
                      </div>

                      {totalStaffDetails !== undefined &&
                      totalStaffDetails?.length !== 0 ? (
                        <div className="col-lg-12 col-sm-12">
                          <Table
                            datarows={totalStaffDetails}
                            headers={NewStaffColumns}
                            checkbox
                            onSelectedRowsChange={onSelectedRowsChange}
                            selectedRows={selectedRows}
                            defaultSorted={[
                              {
                                id: 'name',
                                desc: false
                              }
                            ]}
                          />
                        </div>
                      ) : (
                        <div className="col-lg-12 col-sm-12">
                          <div className="record-not-found">
                            <p className="ymca-warning-text">
                              {APP_LABELS.DETAIL_NO_RECORD_FOUND}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade ${showNewStaff}`}
                    id="newstaff"
                    role="tabpanel"
                    aria-labelledby="newstaff-tab"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <h3 className="ymca-section-heading my-3 ms-3">
                          {APP_LABELS.DETAIL_NEW_STAFF}
                        </h3>
                      </div>

                      {newStaffViewDetails !== undefined &&
                      newStaffViewDetails?.length !== 0 ? (
                        <div className="col-lg-12 col-sm-12">
                          <Table
                            datarows={newStaffViewDetails}
                            headers={NewStaffColumns}
                            checkbox
                            onSelectedRowsChange={onSelectedRowsChange}
                            selectedRows={selectedRows}
                            defaultSorted={[
                              {
                                id: 'name',
                                desc: false
                              }
                            ]}
                          />
                        </div>
                      ) : (
                        <div className="col-lg-12 col-sm-12">
                          <div className="record-not-found">
                            <p className="ymca-warning-text">
                              {APP_LABELS.DETAIL_NO_RECORD_FOUND}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade ${showExistingStaff}`}
                    id="existingstaff"
                    role="tabpanel"
                    aria-labelledby="existingstaff-tab"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <h3 className="ymca-section-heading my-3 ms-3">
                          {APP_LABELS.DETAIL_EXISTING_STAFF}
                        </h3>
                      </div>

                      {existingStaffViewDetails !== undefined &&
                      existingStaffViewDetails?.length !== 0 ? (
                        <div className="col-lg-12 col-sm-12">
                          <Table
                            datarows={existingStaffViewDetails}
                            headers={ExistingStaffColumns}
                            checkbox
                            onSelectedRowsChange={onSelectedRowsExistingStaff}
                            selectedRows={selectedRowsChangeStaff}
                            defaultSorted={[
                              {
                                id: 'name',
                                desc: true
                              }
                            ]}
                          />
                        </div>
                      ) : (
                        <div className="col-lg-12 col-sm-12">
                          <div className="record-not-found">
                            <p className="ymca-warning-text">
                              {APP_LABELS.DETAIL_NO_RECORD_FOUND}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade ${showChangedstaff}`}
                    id="changedstaff"
                    role="tabpanel"
                    aria-labelledby="changedstaff-tab"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <h3 className="ymca-section-heading my-3 ms-3">
                          {APP_LABELS.DETAIL_CHANGED_STAFF}
                        </h3>
                      </div>

                      {changedStaffViewDetails !== undefined &&
                      changedStaffViewDetails?.length !== 0 ? (
                        <div className="col-lg-12 col-sm-12">
                          <Table
                            datarows={changedStaffViewDetails}
                            headers={ExistingStaffColumns}
                            checkbox
                            onSelectedRowsChange={onSelectedRowsExistingStaff}
                            selectedRows={selectedRowsChangeStaff}
                            defaultSorted={[
                              {
                                id: 'name',
                                desc: true
                              }
                            ]}
                          />
                        </div>
                      ) : (
                        <div className="col-lg-12 col-sm-12">
                          <div className="record-not-found">
                            <p className="ymca-warning-text">
                              {APP_LABELS.DETAIL_NO_RECORD_FOUND}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade ${showTerminatedstaffroleassignment}`}
                    id="terminatedstaffroleassignment"
                    role="tabpanel"
                    aria-labelledby="terminatedstaffroleassignment-tab"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <h3 className="ymca-section-heading my-3 ms-3">
                          {APP_LABELS.UPLOAD_DATA_TERMINATED}
                        </h3>
                      </div>

                      {terminatedStaffViewDetails !== undefined &&
                      terminatedStaffViewDetails?.length !== 0 ? (
                        <div className="col-lg-12 col-sm-12">
                          <Table
                            datarows={terminatedStaffViewDetails}
                            headers={TerminatedStaffRoleAssignment}
                            defaultSorted={[
                              {
                                id: 'name',
                                desc: false
                              }
                            ]}
                          />
                        </div>
                      ) : (
                        <div className="col-lg-12 col-sm-12">
                          <div className="record-not-found">
                            <p className="ymca-warning-text">
                              {APP_LABELS.DETAIL_NO_RECORD_FOUND}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade ${showInvalidstaff}`}
                    id="invaliddata"
                    role="tabpanel"
                    aria-labelledby="invaliddata-tab"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <h3 className="ymca-section-heading my-3 ms-3">
                          {APP_LABELS.UPLOAD_DATA_INVALID}
                        </h3>
                      </div>
                      {invalidStaffViewDetails !== undefined &&
                      invalidStaffViewDetails?.length !== 0 ? (
                        <>
                          <div className="col-lg-6 col-md-6">
                            <div className="ymca-warning-text text-lg-end text-sm-start mt-4 me-4">
                              <img
                                className="img-fluid"
                                alt="warning-icon"
                                src={warningiconmedium}
                              />
                              {APP_LABELS.UPLOAD_DATA_RECORDS}
                            </div>
                          </div>

                          <div className="col-lg-12 col-sm-12">
                            <Table
                              datarows={invalidStaffViewDetails}
                              headers={InvalidColumns}
                              defaultSorted={[
                                {
                                  id: 'name',
                                  desc: false
                                }
                              ]}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="col-lg-12 col-sm-12">
                          <div className="record-not-found">
                            <p className="ymca-warning-text">
                              {APP_LABELS.DETAIL_NO_RECORD_FOUND}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="ymca-form mt-3">
              <div className="container">
                <div className="form-group">
                  <div className="form-check ymca-check-box my-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      defaultChecked={checkBox}
                      onChange={handelCheckBox}
                    />
                    <span
                      className="form-check-label ymca-cachet-normal-dark-text"
                      htmlFor="flexCheckDefault"
                    >
                      {APP_LABELS.UPLOAD_DATA_CONFRIM}
                    </span>
                  </div>
                  {invalidCount > 0 ? (
                    <div className="ymca-warning-text">
                      {APP_LABELS.UPLOAD_DATA_RECORDS}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <button
                  className="btn ymca-primary-outline-btn login-btn mt-0"
                  type="button"
                  onClick={redirectToDashBoard}
                >
                  {APP_LABELS.UPLOAD_DATA_CANCEL}
                </button>
                <button
                  onClick={confirmImportData}
                  className="btn ms-3 ymca-primary-btn login-btn mt-0 custom-align"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#upload-success-modal"
                  disabled={enableButton}
                >
                  {APP_LABELS.UPLOAD_DATA_IMPORT_DATA}
                </button>
              </div>
            </section>
            {redirect && <Redirect to={redirectUrl} />}
          </div>
          {help && (
            <Help hidePopup={hideShowHelp} keyValue="upload-employee-review" />
          )}
        </main>
      </BlockUi>

      {/* Attention Modal */}
      <CustomModal
        show={showAttentionPopup}
        type="Important"
        showAllSeperatedPartTimeStaff={showAllSeperatedPartTimeStaff}
        message="Before finalizing your upload, please verify all data in the tabs above."
        submitButtonName="Yes"
        onSubmit={() => {
          postImportDataFile();
        }}
        cancelButtonName="No"
        onCancel={() => {
          setShowAttentionPopup(false);
        }}
      />
      {/* Success Modal */}
      <CustomModal
        show={showSuccessPopup}
        type={APP_LABELS.SUCCESS}
        message={showMessage}
        submitButtonName="Ok"
        onSubmit={redirectToDashBoard}
      />

      {/* View Details Modal */}
      <ViewDetailsPopup
        showViewDetailsPopup={showViewDetailsPopup}
        handleCloseNewStaffPopup={handleCloseNewStaffPopup}
        popupTitle={popupTitle}
        popupData={popupData}
      />

      {/* Error Modal */}
      <ErrorPopup
        handleCloseErrorPopup={handleCloseErrorPopup}
        errorMessage={showMessage}
        showErrorPopup={showErrorPopup}
      />
      {/* */}
      <CustomModal
        show={showWarningPopup}
        type={APP_LABELS.WARNING}
        message={getMessage()}
        showSeperatedWarning={true}
        submitButtonName="Ok"
        onSubmit={() => {
          location.state.showWarningPopup = false;
          setShowWarningPopup(false);
        }}
      />
    </>
  );
};

UploadData.propTypes = {
  handleImportData: PropTypes.func.isRequired,
  UploadStaffData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handleImportData: getImportData
};

const mapStateToProps = state => ({
  UploadStaffData: UploadFileSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadData);
