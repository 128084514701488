export const GET_METADATA_DATASCORING_SUCCESS =
  'GET_METADATA_DATASCORING_SUCCESS';
export const GET_METADATA_DATASCORING_FAILURE =
  'GET_METADATA_DATASCORING_FAILURE';

export const GET_METADATA_DATASCORING_FORM_VIEW_SUCCESS =
  'GET_METADATA_DATASCORING_FORM_VIEW_SUCCESS';
export const GET_METADATA_DATASCORING_FORM_VIEW_FAILURE =
  'GET_METADATA_DATASCORING_FORM_VIEW_FAILURE';

export const GET_METADATA_DATASCORING_SUBFORM_VIEW_SUCCESS =
  'GET_METADATA_DATASCORING_SUBFORM_VIEW_SUCCESS';
export const GET_METADATA_DATASCORING_SUBFORM_VIEW_FAILURE =
  'GET_METADATA_DATASCORING_SUBFORM_VIEW_FAILURE';

export const GET_METADATA_DATASCORING_EVALUATION_GROUP_VIEW_SUCCESS =
  'GET_METADATA_DATASCORING_EVALUATION_GROUP_VIEW_SUCCESS';
export const GET_METADATA_DATASCORING_EVALUATION_GROUP_VIEW_FAILURE =
  'GET_METADATA_DATASCORING_EVALUATION_GROUP_VIEW_FAILURE';

export const GET_METADATA_DATASCORING_DATAPOINTS_VIEW_SUCCESS =
  'GET_METADATA_DATASCORING_DATAPOINTS_VIEW_SUCCESS';
export const GET_METADATA_DATASCORING_DATAPOINTS_VIEW_FAILURE =
  'GET_METADATA_DATASCORING_DATAPOINTS_VIEW_FAILURE';
  export const ADD_METADATA_DATASCORING_FAILURE =
  'ADD_METADATA_DATASCORING_FAILURE';
  export const ADD_METADATA_DATASCORING_SUCCESS =
  'ADD_METADATA_DATASCORING_SUCCESS';
