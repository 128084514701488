


export const handleResponse = (response) => {
    const res = { isSuccess: true, message: "", data: null };

    if (response.status === 200) {
        // which means no exception and user return some thing
        res.isSuccess = response.data.isSuccess;
        res.message = response.data.message;
        res.data = response.data.data;
    } else {
        // This block handles api exceptions
        res.isSuccess = response.isSucess;
        res.message = response.message;
    }
    return res;
}

export const handleCatch = async (error) => {
    let errorMessage = "";
    if (error.response) {
        // Server responded with a status other than 2xx and returned a Blob
        const errorData = error.response.data;

        if (errorData instanceof Blob) {
            // If errorData is a Blob, we need to read it as text or JSON
            const text = await errorData.text(); // convert the blob to text

            // Now parse the text as JSON (if the response contains JSON)
            const parsedError = JSON.parse(text);

            errorMessage = `Error caught: ${parsedError.message || 'Something went wrong'}`;
        } else {
            // If it's not a Blob, we can handle it normally
            errorMessage = `Error caught: ${errorData.message || 'Something went wrong'}`;
        }
    } else if (error.request) {
        // The request was made but no response was received
        errorMessage = `Error caught: No response from the server`;
    } else {
        // Something went wrong in setting up the request
        errorMessage = `Error caught: ${error.message}`;
    }
    return errorMessage;
}