import CryptoJS from 'crypto-js';
const HASH_KEY = process.env.REACT_APP_STORAGE_SECRET_KEY;

class SecureStorage {
  // eslint-disable-next-line no-useless-constructor
  constructor() {}

  setItem = (key, value) => {
    let originalValue = value;
    if (value) {
      originalValue = CryptoJS.AES.encrypt(JSON.stringify(value), HASH_KEY);
    }
    const encodedWord = CryptoJS.enc.Utf8.parse(key);
    const encryptedKey = CryptoJS.enc.Base64.stringify(encodedWord);
    sessionStorage.setItem(encryptedKey, originalValue);
  };

  getItem = key => {
    const encodedWord = CryptoJS.enc.Utf8.parse(key);
    const item = sessionStorage.getItem(
      CryptoJS.enc.Base64.stringify(encodedWord)
    );
    if (item) {
      const decyptedValue = CryptoJS.AES.decrypt(item, HASH_KEY).toString(
        CryptoJS.enc.Utf8
      );
      return JSON.parse(decyptedValue);
    }
    return item;
  };

  removeItem = key => {
    const encodedWord = CryptoJS.enc.Utf8.parse(key);
    sessionStorage.removeItem(CryptoJS.enc.Base64.stringify(encodedWord));
  };

  clear = () => {
    sessionStorage.clear();
  };
}
const storage = new SecureStorage();

export default storage;
