/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  getARPledgeListApi,
  PostARPledgeListApi,
  postSendEmailRequestCEOCVO,
  getCeoCvoDetails
} from '@redux/annual-report-pledgeStatement/actions';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { PledgeStatementDataSelector } from '@redux/annual-report-pledgeStatement/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { useFormik } from 'formik';
import CustomModal from '@utilities/CustomModal';
import SendRequestIcon from '@static/images/Send-request-Icon.svg';
import * as Yup from 'yup';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import moment from 'moment';

import { APP_LABELS } from '@config/app-config';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../../last-update-name';
import FeedbackModal from '../../../../../../utilities/feedback-modal';

const Pledge = props => {
  const {
    LoginUserData,
    handleGetARPledgeListApi,
    handlePostARPledgeListApi,
    setLoader,
    status,
    handleGetAnnualReportsDashboard,
    PledgeData,
    handlePostSendEmailRequestCEOCVO,
    handleGetCeoCvoDetails
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Pledge';

  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [instructions, setInstructions] = useState('');
  const [resStatus, setResStatus] = useState();
  const [cvoAcknowledgeCheckbox, setCvoAcknowledgeCheckbox] = useState(false);
  const [ceoAcknowledgeCheckbox, setCeoAcknowledgeCheckbox] = useState(false);
  const [cvoAcknowledgeBoardAndBylaw, setCvoAcknowledgeBoardAndBylaw] =
    useState(false);
  const [ceoAcknowledgeBoardAndBylaw, setCeoAcknowledgeBoardAndBylaw] =
    useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [ceoData, setCeoData] = useState();
  const [cvoData, setCvoData] = useState();
  const [emailRequest, setEmailRequest] = useState();
  const [showMessage, setShowMessage] = useState({
    status: '',
    type: '',
    message: '',
    name: '',
    jobTitle: '',
    email: ''
  });

  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);
  const [subInstructions, setSubInstructions] = useState();
  const [subInstructions1, setSubInstructions1] = useState();
  const [subInstructions2, setSubInstructions2] = useState();
  const [subInstructions3, setSubInstructions3] = useState();

  let getPlegdeUpdateData;

  const getCeoCvo = () => {
    setLoader(true);
    handleGetCeoCvoDetails(associationNumber)
      .then(res => {
        setLoader(false);
        res?.data?.data?.forEach(row => {
          if (row?.type === 'CEO') {
            setCeoData(row);
          } else if (row?.type === 'CVO') {
            setCvoData(row);
          }
        });
      })
      .catch(() => {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      });
  };

  const handleSendRequestYes = () => {
    setLoader(true);
    let payload;
    if (emailRequest === 'ceo') {
      payload = {
        name: `${ceoData?.firstName} ${ceoData?.lastName}`,
        jobTitle: ceoData?.type,
        email: ceoData?.emailAddress,
        associationNumber:
          role === APP_LABELS.ASSOCIATION_DATA_STEWARD ||
          role === APP_LABELS.CEO_DATA_STEWARD ||
          role === APP_LABELS.CVO_DATA_STEWARD
            ? associationNumber
            : associateData?.associationNumber,
        associationName:
          role === APP_LABELS.ASSOCIATION_DATA_STEWARD ||
          role === APP_LABELS.CEO_DATA_STEWARD ||
          role === APP_LABELS.CVO_DATA_STEWARD
            ? sessionStorage.getItem('yusa_selected_associate')
            : associateData?.associationName,
        year: sessionStorage.getItem('selectedBatchYear'),
        personId: ceoData?.personId,
        staffId: ceoData?.staffId
      };
    } else if (emailRequest === 'cvo') {
      payload = {
        name: `${cvoData?.firstName} ${cvoData?.lastName}`,
        jobTitle: cvoData?.type,
        email: cvoData?.emailAddress,
        associationNumber:
          role === APP_LABELS.ASSOCIATION_DATA_STEWARD ||
          role === APP_LABELS.CEO_DATA_STEWARD ||
          role === APP_LABELS.CVO_DATA_STEWARD
            ? associationNumber
            : associateData?.associationNumber,
        associationName:
          role === APP_LABELS.ASSOCIATION_DATA_STEWARD ||
          role === APP_LABELS.CEO_DATA_STEWARD ||
          role === APP_LABELS.CVO_DATA_STEWARD
            ? sessionStorage.getItem('yusa_selected_associate')
            : associateData?.associationName,
        year: sessionStorage.getItem('selectedBatchYear'),
        personId: ceoData?.personId,
        staffId: ceoData?.staffId
      };
    }

    handlePostSendEmailRequestCEOCVO(payload)
      .then(() => {
        setShowMessage({
          status: '',
          type: '',
          message: ''
        });
        setLoader(false);
      })
      .catch(() => {
        setShowMessage({
          status: '',
          type: '',
          message: ''
        });
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      });
  };

  const handleSendRequestNo = () => {
    setShowMessage({
      status: '',
      type: '',
      message: ''
    });
  };

  const handleSendRequest = emailFor => {
    setEmailRequest(emailFor);
    setShowMessage({
      status: 'attentionicon',
      type: 'Request for Signature!',
      message: 'Are you sure to send a sign in request to the below person?',
      name:
        emailFor === 'ceo'
          ? `${ceoData?.firstName} ${ceoData?.lastName}`
          : `${cvoData?.firstName} ${cvoData?.lastName}`,
      jobTitle: emailFor === 'ceo' ? ceoData?.type : cvoData?.type,
      email: emailFor === 'ceo' ? ceoData?.emailAddress : cvoData?.emailAddress
    });
  };

  const validations = {
    ceoDateSigned: Yup.string().nullable(),
    ceoSignatureName:
      role === APP_LABELS.CEO_DATA_STEWARD &&
      selectedYear >= '2023' &&
      Yup.string().nullable().required('* All fields are required.'),
    ceoYactivelyParticipatesStateAlliance:
      selectedYear >= '2023' &&
      role === APP_LABELS.CEO_DATA_STEWARD &&
      Yup.string().nullable().required('* Please select one option'),
    ceoYstrategicPlan:
      selectedYear >= '2023' &&
      role === APP_LABELS.CEO_DATA_STEWARD &&
      Yup.string().nullable().required('* Please select one option'),
    cvoDateSigned: Yup.string().nullable(),
    cvoSignatureName:
      selectedYear >= '2023' &&
      role === APP_LABELS.CVO_DATA_STEWARD &&
      Yup.string().nullable().required('* All fields are required.'),
    ceoAcknowledge:
      selectedYear >= '2023' &&
      role === APP_LABELS.CEO_DATA_STEWARD &&
      Yup.boolean().nullable().oneOf([true], '*All fields are required.'),
    cvoAcknowledge:
      selectedYear >= '2023' &&
      role === APP_LABELS.CVO_DATA_STEWARD &&
      Yup.boolean().nullable().oneOf([true], '*All fields are required.'),
    ceoAcknowledgeBoardAndBylaw:
      selectedYear >= '2024' &&
      role === APP_LABELS.CEO_DATA_STEWARD &&
      Yup.boolean().nullable().oneOf([true], '*All fields are required.'),
    cvoAcknowledgeBoardAndBylaw:
      selectedYear >= '2024' &&
      role === APP_LABELS.CVO_DATA_STEWARD &&
      Yup.boolean().nullable().oneOf([true], '*All fields are required.')
  };
  const [initialValues, setInitialValues] = useState({
    ceoDateSigned: '',
    ceoSignatureName: '',
    ceoYactivelyParticipatesStateAlliance: '',
    ceoYstrategicPlan: '',
    cvoDateSigned: '',
    cvoSignatureName: '',
    ceoAcknowledge: false,
    cvoAcknowledge: false,
    cvoAcknowledgeBoardAndBylaw: false,
    ceoAcknowledgeBoardAndBylaw: false
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      ...validations
    }),
    onSubmit: values => {
      setSuccessPopup(false);
      setLoader(true);
      const data = { ...values };
      if (selectedYear !== '2022') {
        data.ceoYstrategicPlan = values.ceoYstrategicPlan === 'true';
        data.ceoYactivelyParticipatesStateAlliance =
          values.ceoYactivelyParticipatesStateAlliance === 'true';
      } else {
        data.ceoYstrategicPlan = values.ceoYstrategicPlan === 'true';
        data.ceoYactivelyParticipatesStateAlliance =
          values.ceoYactivelyParticipatesStateAlliance !== 'false';
      }

      handlePostARPledgeListApi(data)
        .then(res => {
          setResStatus(res.data.data?.recordStatus);
          handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
            dashData => {
              dashData?.data?.data?.dashboardDTOs.forEach(record => {
                if (
                  record.name === sectionName &&
                  record.status === 'Completed' &&
                  record.rating === null &&
                  selectedYear >= '2023' &&
                  role !== APP_LABELS.YUSA_DATA_STEWARD &&
                  role !== APP_LABELS.YESS_DATA_STEWARD
                ) {
                  setFeedbackPopup(true);
                  setFormId(record?.formId);
                }
              });
            }
          );
          setLoader(false);
          if (res?.data?.isSuccess) {
            getPlegdeUpdateData(false, true);
          } else {
            setErrorMessage(res?.message);
            setErrorPopup(true);
          }
        })
        .catch(() => {
          setLoader(false);
          setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
          setErrorPopup(true);
        });
    }
  });

  const ceoDate = (result, resultData) => {
    if (result?.data?.data?.ceoDateSigned)
      return result?.data?.data?.ceoDateSigned;

    return resultData.ceoSignatureName
      ? new Date()
      : result?.data?.data?.ceoDateSigned;
  };

  const cvoDate = (result, resultData) => {
    if (result?.data?.data?.cvoDateSigned)
      return result?.data?.data?.cvoDateSigned;

    return resultData.cvoSignatureName
      ? new Date()
      : result?.data?.data?.cvoDateSigned;
  };

  getPlegdeUpdateData = (loader, success) => {
    if (loader) setLoader(true);
    handleGetARPledgeListApi(
      role === APP_LABELS.YUSA_DATA_STEWARD ||
        role === APP_LABELS.YUSA_DATA_VIEWER ||
        role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
        role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0')
    )
      .then(result => {
        if (result?.data?.isSuccess) {
          formik.setFieldValue(
            'ceoAcknowledge',
            result?.data?.data?.ceoAcknowledge
          );
          formik.setFieldValue(
            'cvoAcknowledge',
            result?.data?.data?.cvoAcknowledge
          );
          setCeoAcknowledgeCheckbox(result?.data?.data?.ceoAcknowledge);
          setCvoAcknowledgeCheckbox(result?.data?.data?.cvoAcknowledge);
          if (selectedYear >= '2024') {
            formik.setFieldValue(
              'cvoAcknowledgeBoardAndBylaw',
              result?.data?.data?.cvoAcknowledgeBoardAndBylaw
            );
            setCvoAcknowledgeBoardAndBylaw(
              result?.data?.data?.cvoAcknowledgeBoardAndBylaw
            );
            formik.setFieldValue(
              'ceoAcknowledgeBoardAndBylaw',
              result?.data?.data?.ceoAcknowledgeBoardAndBylaw
            );
            setCeoAcknowledgeBoardAndBylaw(
              result?.data?.data?.ceoAcknowledgeBoardAndBylaw
            );
          }
          const resultData = { ...result?.data?.data };
          // eslint-disable-next-line no-nested-ternary
          resultData.ceoDateSigned = ceoDate(result, resultData);
          // eslint-disable-next-line no-nested-ternary
          resultData.cvoDateSigned = cvoDate(result, resultData);
          setInitialValues(resultData);

          if (success) {
            setSuccessPopup(true);
          }
        } else {
          setLoader(false);
          setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        }
        if (loader) setLoader(false);
      })
      .catch(() => {
        if (loader) setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      });
  };

  useEffect(() => {
    getPlegdeUpdateData(true);
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.pledge?.Instruction
    );
    setSubInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.['sub-pledge-1']
        ?.Instruction
    );
    setSubInstructions1(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.['sub-pledge-2']
        ?.Instruction
    );
    setSubInstructions2(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.['sub-pledge-3']
        ?.Instruction
    );
    setSubInstructions3(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.['sub-pledge-4']
        ?.Instruction
    );
    if (selectedYear >= 2023) {
      getCeoCvo();
    }
  }, []);

  const handleSignaturename = e => {
    const regex = /^[a-zA-Z/\s]*$/;
    if (regex.test(e.target.value)) {
      formik.setFieldValue(e.target.name, e.target.value.trimStart());
    }

    if (e.target.name === 'ceoSignatureName') {
      formik.setFieldValue('ceoDateSigned', new Date());
    } else if (e.target.name === 'cvoSignatureName') {
      formik.setFieldValue('cvoDateSigned', new Date());
    }
  };

  const handleCeoAcknowledgeCheckbox = e => {
    setCeoAcknowledgeCheckbox(e.target.checked);
    formik.setFieldValue('ceoAcknowledge', e.target.checked);
  };

  const handleCvoAcknowledgeCheckbox = e => {
    setCvoAcknowledgeCheckbox(e.target.checked);
    formik.setFieldValue('cvoAcknowledge', e.target.checked);
  };
  const handleCvoAcknowledgeBoardAndBylawCheckbox = e => {
    setCvoAcknowledgeBoardAndBylaw(e.target.checked);
    formik.setFieldValue('cvoAcknowledgeBoardAndBylaw', e.target.checked);
  };
  const handleCeoAcknowledgeBoardAndBylawCheckbox = e => {
    setCeoAcknowledgeBoardAndBylaw(e.target.checked);
    formik.setFieldValue('ceoAcknowledgeBoardAndBylaw', e.target.checked);
  };

  useEffect(() => {
    sessionStorage.setItem('dirty', formik.dirty);
  }, [formik.dirty]);

  return (
    <div className="ymca-program custom-padding">
      <div className="ymca-covidRelif px-0 pt-0">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
          <div className="ymca-instructionBoxes">
            <div
              dangerouslySetInnerHTML={{
                __html: instructions
              }}
            />
          </div>
        </div>
        <div className="ymc-alert">
          {PledgeData?.data?.recordStatus?.toUpperCase() ===
            APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}
        </div>

        <form onSubmit={formik.handleSubmit}>
          {role !== APP_LABELS.CVO_DATA_STEWARD && (
            <>
              <div className="ymca-headline">
                <h4 className="ymca-text">Key Questions for the CEO </h4>
              </div>
              <h4 className="ymca-textTitle txt-mandatory my-0">
                The YMCA currently has a strategic plan guiding the organization
                that has been adopted by the board within the last three years.
              </h4>
              <div className="ymca-radioBTN d-flex p-1">
                <div className="ymca-setListing me-4">
                  <input
                    type="radio"
                    className="ymca-fieldRadio"
                    name="ceoYstrategicPlan"
                    value="true"
                    onChange={formik.handleChange}
                    checked={
                      formik?.values?.ceoYstrategicPlan?.toString() === 'true'
                    }
                  />
                  <span className="ymca-texting">Yes</span>
                </div>
                <div className="ymca-setListing">
                  <input
                    type="radio"
                    name="ceoYstrategicPlan"
                    value="false"
                    className="ymca-fieldRadio"
                    onChange={formik.handleChange}
                    checked={
                      formik?.values?.ceoYstrategicPlan?.toString() === 'false'
                    }
                  />
                  <span className="ymca-texting">No</span>
                </div>
              </div>
              {formik.touched.ceoYstrategicPlan &&
                formik.errors.ceoYstrategicPlan && (
                  <div className="errorNote">
                    {formik.errors.ceoYstrategicPlan}
                  </div>
                )}
              <div className="mt-4">
                <h4 className="ymca-textTitle txt-mandatory my-0">
                  The YMCA actively participates in its State Alliance as
                  defined by attending at least one gathering annually, actively
                  supporting alliance priorities, and paying dues timely and in
                  full.
                </h4>
              </div>
              <div className="ymca-radioBTN d-flex p-1">
                <div className="ymca-setListing me-4">
                  <input
                    type="radio"
                    name="ceoYactivelyParticipatesStateAlliance"
                    value="true"
                    className="ymca-fieldRadio"
                    onChange={formik.handleChange}
                    checked={
                      formik?.values?.ceoYactivelyParticipatesStateAlliance?.toString() ===
                      'true'
                    }
                  />
                  <span className="ymca-texting">Yes</span>
                </div>
                <div className="ymca-setListing">
                  <input
                    type="radio"
                    name="ceoYactivelyParticipatesStateAlliance"
                    value="false"
                    className="ymca-fieldRadio"
                    onChange={formik.handleChange}
                    checked={
                      formik?.values?.ceoYactivelyParticipatesStateAlliance?.toString() ===
                      'false'
                    }
                  />
                  <span className="ymca-texting">No</span>
                </div>
              </div>
              {formik.touched.ceoYactivelyParticipatesStateAlliance &&
                formik.errors.ceoYactivelyParticipatesStateAlliance && (
                  <div className="errorNote">
                    {formik.errors.ceoYactivelyParticipatesStateAlliance}
                  </div>
                )}

              <hr className="mt-4" />
            </>
          )}
          <div
            className="ymca-textTitle"
            dangerouslySetInnerHTML={{
              __html: subInstructions
            }}
          />
          <div className="ymca-pointsTable ymca-scrollableText">
            <div
              className="ymca-textTitle toast-body"
              dangerouslySetInnerHTML={{
                __html: subInstructions1
              }}
            />
          </div>
          {selectedYear >= '2024' && (
            <>
              <div
                className="ymca-textTitle mt-3"
                dangerouslySetInnerHTML={{
                  __html: subInstructions2
                }}
              />
              <div className="ymca-pointsTable ymca-scrollableText">
                <div
                  className="ymca-textTitle toast-body"
                  dangerouslySetInnerHTML={{
                    __html: subInstructions3
                  }}
                />
              </div>
            </>
          )}
          <div className="row">
            {selectedYear === '2022' ? (
              <>
                <div className="ymca-program p-0 mt-4 mb-4">
                  <div className="ymca-pointsTable">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h4 className="ymca-headingTitle">
                          CEO
                          {formik.values?.ceoName &&
                            ` | ${formik.values.ceoName}`}
                        </h4>
                        <p className="ymca-textTitle">
                          Please enter your electronic signature by entering
                          your name proceeded and followed by forward slash,
                          e.g/John Doe/
                        </p>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="ymca-selectWrap">
                          <div className="ymca-labelling ymca-dullText txt-mandatory">
                            Signature Name of CEO
                          </div>
                          <input
                            type="text"
                            className="ymca-fieldSet"
                            placeholder=""
                            name="ceoSignatureName"
                            onChange={handleSignaturename}
                            onBlur={formik.handleBlur}
                            value={formik.values.ceoSignatureName}
                            readOnly={role !== APP_LABELS.CEO_DATA_STEWARD}
                            maxLength={255}
                          />
                          {formik.touched.ceoSignatureName &&
                          formik.errors.ceoSignatureName ? (
                            <div className="errorNote">
                              {formik.errors.ceoSignatureName}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="ymca-selectWrap">
                          <div className="ymca-labelling ymca-dullText txt-mandatory">
                            Date Signed (mm/dd/yyyy)
                          </div>

                          <input
                            type="date"
                            className="ymca-fieldSet"
                            placeholder=""
                            name="ceoDateSigned"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            min={moment(new Date('1/1/1901 12:00:00')).format(
                              'YYYY-MM-DD'
                            )}
                            value={moment(formik.values.ceoDateSigned).format(
                              'YYYY-MM-DD'
                            )}
                            readOnly
                          />
                          {formik.touched.ceoDateSigned &&
                          formik.errors.ceoDateSigned ? (
                            <div className="errorNote">
                              {formik.errors.ceoDateSigned}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="ymca-selectWrap">
                          {!(
                            role === APP_LABELS.YUSA_DATA_VIEWER ||
                            role === APP_LABELS.YUSA_DATA_STEWARD ||
                            role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
                            role === APP_LABELS.YESS_DATA_STEWARD
                          ) && (
                            <button
                              type="submit"
                              className="btn ymca-savingBTN mt-45 "
                              disabled={
                                status?.toUpperCase() ===
                                  APP_LABELS.STATUS_SUBMITTED ||
                                role !== APP_LABELS.CEO_DATA_STEWARD ||
                                !formik.values?.ceoSignatureName ||
                                !formik.values?.ceoDateSigned
                              }
                            >
                              Save
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ymca-program p-0 mt-4 mb-4">
                  <div className="ymca-pointsTable">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h4 className="ymca-headingTitle">
                          CVO
                          {formik.values?.cvoName &&
                            ` | ${formik.values.cvoName}`}
                        </h4>
                        <p className="ymca-textTitle">
                          Please enter your electronic signature by entering
                          your name proceeded and followed by forward slash,
                          e.g/John Doe/
                        </p>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="ymca-selectWrap">
                          <div className="ymca-labelling ymca-dullText txt-mandatory">
                            Signature Name of CVO
                          </div>
                          <input
                            type="text"
                            className="ymca-fieldSet"
                            placeholder=""
                            name="cvoSignatureName"
                            onChange={handleSignaturename}
                            onBlur={formik.handleBlur}
                            value={formik.values.cvoSignatureName}
                            readOnly={role !== APP_LABELS.CVO_DATA_STEWARD}
                            maxLength={255}
                          />
                          {formik.touched.cvoSignatureName &&
                          formik.errors.cvoSignatureName ? (
                            <div className="errorNote">
                              {formik.errors.cvoSignatureName}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="ymca-selectWrap">
                          <div className="ymca-labelling ymca-dullText txt-mandatory">
                            Date Signed (mm/dd/yyyy)
                          </div>
                          <input
                            type="date"
                            className="ymca-fieldSet"
                            placeholder=""
                            name="cvoDateSigned"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={moment(formik.values.cvoDateSigned).format(
                              'YYYY-MM-DD'
                            )}
                            min={moment(new Date('1/1/1901 12:00:00')).format(
                              'YYYY-MM-DD'
                            )}
                            readOnly
                          />
                          {formik.touched.cvoDateSigned &&
                          formik.errors.cvoDateSigned ? (
                            <div className="errorNote">
                              {formik.errors.cvoDateSigned}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="ymca-selectWrap">
                          {!(
                            role === APP_LABELS.YUSA_DATA_STEWARD ||
                            role === APP_LABELS.YUSA_DATA_VIEWER ||
                            role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
                            role === APP_LABELS.YESS_DATA_STEWARD
                          ) && (
                            <button
                              type="submit"
                              className="btn ymca-savingBTN mt-45 "
                              disabled={
                                status?.toUpperCase() ===
                                  APP_LABELS.STATUS_SUBMITTED ||
                                role !== APP_LABELS.CVO_DATA_STEWARD ||
                                !formik.values?.cvoSignatureName ||
                                !formik.values?.cvoDateSigned
                              }
                            >
                              Save
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-xl-6 col-lg-6">
                  <div className="ymca-program p-0 mt-4 mb-4">
                    <div className="ymca-pointsTable pb-4">
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h4 className="ymca-headingTitle">CEO</h4>

                          <p className="ymca-textTitle txt-mandatory mb-0">
                            <input
                              type="checkbox"
                              name="ceoAcknowledge"
                              id="ceoAcknowledge"
                              className="styled-checkbox me-2 "
                              checked={ceoAcknowledgeCheckbox}
                              onChange={e => handleCeoAcknowledgeCheckbox(e)}
                              disabled={role !== APP_LABELS.CEO_DATA_STEWARD}
                            />
                            {/* */}I acknowledge the pledge statement
                          </p>
                          {selectedYear >= '2024' && (
                            <p className="ymca-textTitle txt-mandatory mb-0">
                              <input
                                type="checkbox"
                                name="ceoAcknowledgeBoardAndBylaw"
                                id="ceoAcknowledgeBoardAndBylaw"
                                className="styled-checkbox me-2"
                                checked={ceoAcknowledgeBoardAndBylaw}
                                onChange={e =>
                                  handleCeoAcknowledgeBoardAndBylawCheckbox(e)
                                }
                                disabled={role !== APP_LABELS.CEO_DATA_STEWARD}
                              />
                              {/* */}I acknowledge the board and bylaw
                              statements
                            </p>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-12">
                          <div className="ymca-selectWrap">
                            <div className="ymca-labelling ymca-dullText txt-mandatory annual-report-pledge">
                              Signature Name of CEO
                            </div>
                            <input
                              type="text"
                              className="ymca-fieldSet"
                              placeholder=""
                              name="ceoSignatureName"
                              onChange={handleSignaturename}
                              onBlur={formik.handleBlur}
                              value={formik.values.ceoSignatureName}
                              readOnly={role !== APP_LABELS.CEO_DATA_STEWARD}
                              maxLength={255}
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-12">
                          <div className="ymca-selectWrap">
                            <div className="ymca-labelling ymca-dullText txt-mandatory annual-report-pledge">
                              Date Signed (mm/dd/yyyy)
                            </div>
                            <input
                              type="date"
                              className="ymca-fieldSet"
                              placeholder=""
                              name="ceoDateSigned"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              min={moment(new Date('1/1/1901 12:00:00')).format(
                                'YYYY-MM-DD'
                              )}
                              value={moment(formik.values.ceoDateSigned).format(
                                'YYYY-MM-DD'
                              )}
                              readOnly
                            />
                            {formik.touched.ceoDateSigned &&
                            formik.errors.ceoDateSigned ? (
                              <div className="errorNote">
                                {formik.errors.ceoDateSigned}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                          {(formik.touched.ceoAcknowledge &&
                            formik.errors.ceoAcknowledge) ||
                          (formik.touched.ceoSignatureName &&
                            formik.errors.ceoSignatureName) ? (
                            <div className="errorNote mt-2">
                              {formik.errors.ceoAcknowledge ||
                                formik.errors.ceoSignatureName}
                            </div>
                          ) : selectedYear >= '2024' &&
                            formik.touched.ceoAcknowledgeBoardAndBylaw &&
                            formik.errors?.ceoAcknowledgeBoardAndBylaw ? (
                            <div className="errorNote mt-2">
                              {formik.errors.ceoAcknowledgeBoardAndBylaw}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 ms-auto">
                          <div className="ymca-selectWrap d-flex justify-content-end">
                            {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                              role !== APP_LABELS.YUSA_DATA_STEWARD &&
                              role !== APP_LABELS.YESS_DATA_STEWARD &&
                              role !== APP_LABELS.ALLIANCE_DATA_STEWARD &&
                              role !== APP_LABELS.ASSOCIATION_DATA_STEWARD && (
                                <button
                                  type="submit"
                                  className="btn ymca-savingBTN"
                                  disabled={
                                    status?.toUpperCase() ===
                                      APP_LABELS.STATUS_SUBMITTED ||
                                    role !== APP_LABELS.CEO_DATA_STEWARD
                                  }
                                >
                                  Save
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                      {role !== APP_LABELS.CEO_DATA_STEWARD && (
                        <>
                          <div className="ymca-headingTitle col-12 text-center c-pointer">
                            Or
                          </div>
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 c-pointer">
                            <h4 className="ymca-headingTitle">
                              Request For Signature
                            </h4>
                            <p className="ymca-textTitle ">
                              Click on the Send Request button below to have an
                              email sent to the CEO to sign and date the Pledge
                              Statement. After clicking the button, please
                              verify the contact information in the pop-up box.
                              Any incorrect information should be updated in
                              Manage Our Y Profile (MOYP) before sending the
                              email request
                            </p>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-12 ms-auto c-pointer">
                            <div className="ymca-selectWrap d-flex justify-content-end">
                              <button
                                type="button"
                                className="btn ymca-savingBTN px-3 mt-1"
                                onClick={() => handleSendRequest('ceo')}
                                disabled={
                                  (formik.values?.ceoSignatureName &&
                                    formik.values?.ceoDateSigned &&
                                    formik.values?.ceoAcknowledge) ||
                                  ceoData?.emailAddress === null ||
                                  ceoData === undefined
                                }
                              >
                                <img
                                  className="pe-2"
                                  src={SendRequestIcon}
                                  alt="SendRequestIcon"
                                />
                                {/* */}
                                Send Request
                              </button>
                            </div>
                          </div>
                          {formik.values?.ceoSignatureName &&
                            formik.values?.ceoDateSigned &&
                            formik.values?.ceoAcknowledge && (
                              <div className="text-end text-danger">
                                * The Pledge statement has already been
                                submitted.
                              </div>
                            )}
                          {(ceoData?.emailAddress === null ||
                            ceoData === undefined) && (
                            <div className="text-end text-danger">
                              * CEO Email is not available.
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6">
                  <div className="ymca-program p-0 mt-4 mb-4">
                    <div className="ymca-pointsTable pb-4">
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h4 className="ymca-headingTitle">
                            Chief Volunteer Officer/Board Chair{' '}
                          </h4>

                          <p className="ymca-textTitle txt-mandatory mb-0">
                            <input
                              type="checkbox"
                              name="cvoAcknowledge"
                              id="cvoAcknowledge"
                              className="styled-checkbox me-2"
                              checked={cvoAcknowledgeCheckbox}
                              onChange={e => handleCvoAcknowledgeCheckbox(e)}
                              disabled={role !== APP_LABELS.CVO_DATA_STEWARD}
                            />
                            {/* */}I acknowledge the pledge statement
                          </p>
                          {selectedYear >= '2024' && (
                            <p className="ymca-textTitle txt-mandatory mb-0">
                              <input
                                type="checkbox"
                                name="cvoAcknowledgeBoardAndBylaw"
                                id="cvoAcknowledgeBoardAndBylaw"
                                className="styled-checkbox me-2"
                                checked={cvoAcknowledgeBoardAndBylaw}
                                onChange={e =>
                                  handleCvoAcknowledgeBoardAndBylawCheckbox(e)
                                }
                                disabled={role !== APP_LABELS.CVO_DATA_STEWARD}
                              />
                              {/* */}I acknowledge the board and bylaw
                              statements
                            </p>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-12">
                          <div className="ymca-selectWrap">
                            <div className="ymca-labelling ymca-dullText txt-mandatory annual-report-pledge-cvo">
                              Signature Name of Chief Volunteer Officer/Board
                              Chair
                            </div>
                            <input
                              type="text"
                              className="ymca-fieldSet"
                              placeholder=""
                              name="cvoSignatureName"
                              onChange={handleSignaturename}
                              onBlur={formik.handleBlur}
                              value={formik.values.cvoSignatureName}
                              readOnly={role !== APP_LABELS.CVO_DATA_STEWARD}
                              maxLength={255}
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-12">
                          <div className="ymca-selectWrap">
                            <div className="ymca-labelling ymca-dullText txt-mandatory annual-report-pledge">
                              Date Signed (mm/dd/yyyy)
                            </div>
                            <input
                              type="date"
                              className="ymca-fieldSet"
                              placeholder=""
                              name="cvoDateSigned"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={moment(formik.values.cvoDateSigned).format(
                                'YYYY-MM-DD'
                              )}
                              min={moment(new Date('1/1/1901 12:00:00')).format(
                                'YYYY-MM-DD'
                              )}
                              readOnly
                            />
                            {formik.touched.cvoDateSigned &&
                            formik.errors.cvoDateSigned ? (
                              <div className="errorNote">
                                {formik.errors.cvoDateSigned}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                          {(formik.touched.cvoAcknowledge &&
                            formik.errors.cvoAcknowledge) ||
                          (formik.touched.cvoSignatureName &&
                            formik.errors.cvoSignatureName) ? (
                            <div className="errorNote mt-2">
                              {formik.errors.cvoAcknowledge ||
                                formik.errors.cvoSignatureName}
                            </div>
                          ) : selectedYear >= '2024' &&
                            formik.touched.cvoAcknowledgeBoardAndBylaw &&
                            formik.errors?.cvoAcknowledgeBoardAndBylaw ? (
                            <div className="errorNote mt-2">
                              {formik.errors.cvoAcknowledgeBoardAndBylaw}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 ms-auto">
                          <div className="ymca-selectWrap d-flex justify-content-end">
                            {role !== APP_LABELS.YUSA_DATA_STEWARD &&
                              role !== APP_LABELS.YUSA_DATA_VIEWER &&
                              role !== APP_LABELS.YESS_DATA_STEWARD &&
                              role !== APP_LABELS.ALLIANCE_DATA_STEWARD &&
                              role !== APP_LABELS.ASSOCIATION_DATA_STEWARD && (
                                <button
                                  type="submit"
                                  className="btn ymca-savingBTN"
                                  disabled={
                                    status?.toUpperCase() ===
                                      APP_LABELS.STATUS_SUBMITTED ||
                                    role !== APP_LABELS.CVO_DATA_STEWARD
                                  }
                                >
                                  Save
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                      {role !== APP_LABELS.CVO_DATA_STEWARD && (
                        <>
                          <div className="ymca-headingTitle col-12 text-center c-pointer">
                            Or
                          </div>
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 c-pointer">
                            <h4 className="ymca-headingTitle">
                              Request For Signature
                            </h4>
                            <p className="ymca-textTitle">
                              Click on the Send Request button below to have an
                              email sent to the CVO to sign and date the Pledge
                              Statement. After clicking the button, please
                              verify the contact information in the pop-up box.
                              Any incorrect information should be updated in
                              Manage Our Y Profile (MOYP) before sending the
                              email request.
                            </p>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-4 col-sm-12 col-12 ms-auto c-pointer">
                            <div className="ymca-selectWrap d-flex justify-content-end">
                              <button
                                type="button"
                                className="btn ymca-savingBTN px-3 mt-1"
                                onClick={() => handleSendRequest('cvo')}
                                disabled={
                                  (formik.values?.cvoSignatureName &&
                                    formik.values?.cvoDateSigned &&
                                    formik.values?.cvoAcknowledge) ||
                                  cvoData?.emailAddress === null ||
                                  cvoData === undefined
                                }
                              >
                                <img
                                  className="pe-2"
                                  src={SendRequestIcon}
                                  alt="SendRequestIcon"
                                />
                                {/* */}
                                Send Request
                              </button>
                            </div>
                          </div>
                          {formik.values?.cvoSignatureName &&
                            formik.values?.cvoDateSigned &&
                            formik.values?.cvoAcknowledge && (
                              <div className="text-end text-danger">
                                * The Pledge statement has already been
                                submitted.
                              </div>
                            )}
                          {(cvoData?.emailAddress === null ||
                            cvoData === undefined) && (
                            <div className="text-end text-danger">
                              * CVO Email is not available.
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
        <div className="ymca-submitHighlighter">
          <LastUpdateName
            Username={formik.values?.updatedByName}
            Time={formik.values?.updatedDatetime}
          />
        </div>

        {successPopup && <SuccessToaster Status={resStatus} />}
        <ErrorPopup
          handleCloseErrorPopup={() => {
            setErrorPopup(false);
          }}
          errorMessage={errorMessage}
          showErrorPopup={errorPopup}
        />

        <CustomModal
          show={showMessage.status}
          type={showMessage.type}
          message={showMessage.message}
          name={showMessage.name}
          jobTitle={showMessage.jobTitle}
          email={showMessage.email}
          submitButtonName="Yes"
          cancelButtonName="No"
          onSubmit={handleSendRequestYes}
          onCancel={handleSendRequestNo}
        />

        <FeedbackModal
          handleCloseFeedbackPopup={() => {
            setFeedbackPopup(false);
          }}
          showFeedbackPopup={feedbackPopup}
          sectionName={sectionName}
          selectedYear={selectedYear}
          associationNumber={associationNumber}
          formId={formId}
        />
      </div>
    </div>
  );
};

Pledge.propTypes = {
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetARPledgeListApi: PropTypes.func.isRequired,
  handlePostARPledgeListApi: PropTypes.func.isRequired,
  handleGetCeoCvoDetails: PropTypes.func.isRequired,
  handlePostSendEmailRequestCEOCVO: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  PledgeData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handleGetARPledgeListApi: getARPledgeListApi,
  handlePostARPledgeListApi: PostARPledgeListApi,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData,
  handlePostSendEmailRequestCEOCVO: postSendEmailRequestCEOCVO,
  handleGetCeoCvoDetails: getCeoCvoDetails
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  PledgeData: PledgeStatementDataSelector(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Pledge));
