import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import axios from 'axios';

import CustomModal from '@utilities/CustomModal';
import BackIcon from '@static/images/back-icon.svg';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import moment from 'moment';
import appConfig, { APP_LABELS } from '@config/app-config';
import userService from '@utilities/user-service';
import { META_DATA_UPLOAD_URLS } from '@config/api-config';
import {
  getDomains,
  getYears
} from '@redux/metadata-manager/dashboard/actions';
import { uploadMetadata } from '@redux/metadata-manager/upload-metadata/actions';
import { UploadMetadataSelector } from '@redux/metadata-manager/upload-metadata/selectors';
import { handleCatch, handleResponse } from '../shared/index';

const MetaDataManagerUploadMetadata = ({
  handleGetDomains,
  handleGetYears,
  handleUploadMetadata
}) => {
  const fileInputRef = useRef(null);
  const [canRedirect, setCanRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataDomains, setDataDomains] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedDataDomain, setSelectedDataDomain] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedUploadDataDomain, setSelectedUploadDataDomain] =
    useState(null);
  const [selectedUploadYear, setSelectedUploadYear] = useState(null);
  const [showDownloadStatus, setShowDownloadStatus] = useState(false);
  const [showDownloadMessage, setShowDownloadMessage] = useState('');
  const [instructions, setInstructions] = useState('');
  const downloadExistingData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        META_DATA_UPLOAD_URLS.DOWNLOAD_EXISTING_DATA(
          selectedDataDomain,
          selectedYear
        ),
        null,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userService.getToken()}`,
            'Ocp-Apim-Subscription-Key': appConfig.APIM_KEY
          }
        }
      );
      const result = handleResponse(response);
      if (response.status === 200) {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'Metadata-DataCollections.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        setShowDownloadMessage(result?.message || APP_LABELS.ERROR_MESSAGE);
        setShowDownloadStatus(true);
      }
    } catch (error) {
      const err = await handleCatch(error);
      setShowDownloadMessage(err || APP_LABELS.ERROR_MESSAGE);
      setShowDownloadStatus(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadMetadataClick = async uploadedFile => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('UploadedFile', uploadedFile);
    formData.append('DomainId', selectedUploadDataDomain);
    formData.append('Year', selectedUploadYear);
    try {
      const response = await handleUploadMetadata(formData);
      const result = handleResponse(response);
      if (result.isSuccess && result.data) {
        setCanRedirect(true);
      } else {
        setShowDownloadMessage(result.message || APP_LABELS.ERROR_MESSAGE);
        setShowDownloadStatus(true);
      }
    } catch (error) {
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Reset the file input
      }
      const err = handleCatch(error);
      setShowDownloadMessage(err || APP_LABELS.ERROR_MESSAGE);
      setShowDownloadStatus(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = e => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      handleUploadMetadataClick(selectedFile);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const initializeUpload = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await Promise.all([
        handleGetDomains(),
        handleGetYears()
      ]);
      const domainRes = handleResponse(response[0]);
      const yearRes = handleResponse(response[1]);
      if (domainRes.isSuccess && yearRes.isSuccess) {
        setDataDomains(domainRes.data);
        setYears(yearRes.data);

        const initialDomain = domainRes.data[0]?.dataDomainId;
        const initialYear = moment().year();
        setSelectedDataDomain(initialDomain);
        setSelectedYear(initialYear);

        setSelectedUploadDataDomain(initialDomain);
        setSelectedUploadYear(initialYear);
      }
    } finally {
      setIsLoading(false);
    }
  }, [handleGetDomains, handleGetYears]);

  useEffect(() => {
    initializeUpload();
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'upload-metadata-data'
      ]?.Instruction
    );
  }, [initializeUpload]);

  return (
    <BlockUi
      blocking={isLoading}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper">
        <main className="page-container">
          <div className="container">
            <div className="d-flex justify-content-between">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb ymca-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/metadata-manager/dashboard">
                      <span>Dashboard</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item active">Upload Data</li>
                </ol>
              </nav>
              <Link
                className="ymca-title-link"
                to="/metadata-manager/dashboard"
              >
                <img src={BackIcon} className="me-1 mb-2" alt="back-icon" />{' '}
                Back
              </Link>
            </div>

            <h5 className="ymca-sub-heading mb-0">Upload Metadata</h5>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 pb-3 pt-3">
                <div className="ymca-instructionBoxes">
                  <h4 className="ymca-instructHeadline">
                    {APP_LABELS.INSTRUCTIONS}
                  </h4>
                  <p className="ymca-instructTitle">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: instructions
                      }}
                    />
                  </p>
                </div>
              </div>
            </div>

            <h5 className="ymca-bold-heading">
              Step 1 : Download existing Metadata.
            </h5>
            <div className="card outline-card ymca-form px-3">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className="form-group">
                    <label htmlFor="ddlDataDomainId" className="form-label">
                      Domain<span className="text-mandatory ms-1">*</span>
                    </label>
                    <select
                      id="ddlDataDomainId"
                      className="form-select"
                      aria-label="Domain"
                      value={selectedDataDomain}
                      onChange={e => setSelectedDataDomain(e.target.value)}
                    >
                      {dataDomains.map(item => (
                        <option value={item.dataDomainId}>
                          {item.dataDomainName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className="form-group">
                    <label htmlFor="ddlYear" className="form-label">
                      Year<span className="text-mandatory ms-1">*</span>
                    </label>
                    <select
                      id="ddlYear"
                      className="form-select"
                      aria-label="Year"
                      value={selectedYear}
                      onChange={e => setSelectedYear(e.target.value)}
                    >
                      {years.map(item => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="text-md-center">
                <button
                  className="btn ymca-primary-btn custom-align"
                  type="button"
                  onClick={downloadExistingData}
                >
                  Download Existing Data
                </button>
              </div>
            </div>

            <h5 className="ymca-bold-heading">Step 2 : Upload metadata.</h5>
            <div className="card outline-card ymca-form px-3">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className="form-group">
                    <label htmlFor="ddlUploadDataDomain" className="form-label">
                      Domain<span className="text-mandatory ms-1">*</span>
                    </label>
                    <select
                      id="ddlUploadDataDomain"
                      className="form-select"
                      aria-label="Domain"
                      value={selectedUploadDataDomain}
                      onChange={e =>
                        setSelectedUploadDataDomain(e.target.value)
                      }
                    >
                      {dataDomains.map(item => (
                        <option value={item.dataDomainId}>
                          {item.dataDomainName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <div className="form-group">
                    <label htmlFor="ddlUploadYear" className="form-label">
                      Year<span className="text-mandatory ms-1">*</span>
                    </label>
                    <select
                      id="ddlUploadYear"
                      className="form-select"
                      aria-label="Year"
                      value={selectedUploadYear}
                      onChange={e => setSelectedUploadYear(e.target.value)}
                    >
                      {years.map(item => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="text-md-center">
                <button
                  className="btn ymca-primary-btn custom-align"
                  type="button"
                  onClick={handleButtonClick}
                >
                  Upload Data
                </button>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              {canRedirect && (
                <Redirect to="/metadata-manager/upload-metadata/review" />
              )}
            </div>
          </div>
        </main>
      </div>
      <CustomModal
        show={showDownloadStatus}
        type={APP_LABELS.ERROR}
        message={showDownloadMessage}
        submitButtonName="Ok"
        onSubmit={() => setShowDownloadStatus(false)}
      />
    </BlockUi>
  );
};
MetaDataManagerUploadMetadata.propTypes = {
  handleGetYears: PropTypes.func.isRequired,
  handleGetDomains: PropTypes.func.isRequired,
  handleUploadMetadata: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetYears: getYears,
  handleGetDomains: getDomains,
  handleUploadMetadata: uploadMetadata
};

const mapStateToProps = state => ({
  uploadedMetadata: UploadMetadataSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MetaDataManagerUploadMetadata);
