/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import {
  getARContactDetailsApi,
  PostARContactDetailsApi,
  getPledgeContactDetailsApi,
  getARIncomingContactDetailsApi,
  postIncomingContactDetailsApi,
  getPledgeIncomingCvoContactDetailsApi
} from '@redux/annual-report-pledgeStatement/actions';
import { getCountriesList } from '@redux/directory-information/actions';
import {
  CvoContactDataSelector,
  IncomingCvoContactDataSelector
} from '@redux/annual-report-pledgeStatement/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import moment from 'moment';
import { APP_LABELS } from '@config/app-config';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../../last-update-name';
import FeedbackModal from '../../../../../../utilities/feedback-modal';

const CvoContactDetails = props => {
  const {
    LoginUserData,
    handlegetARContactDetailsApi,
    handlePostARContactDetailsApi,
    setLoader,
    handleToGetCountries,
    status,
    handleGetAnnualReportsDashboard,
    CvoConatactDetails,
    handlegetIcomingCvoContactDetailsApi,
    handlePostIncomingCvoContactDetailsApi,
    IncomingCvoContactDataSelectorDetails
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'YMCA Directory Information';

  const [incomingCVOAge, setIncomingCVOAge] = useState();
  const [cVOAge, setCVOAge] = useState();
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [countriesResponse, setCountriesResponse] = useState(null);
  const [endDateGreater, setEndDateGreater] = useState(false);
  const [endDatePast, setEndDatePast] = useState(false);
  const [incomingEndDatePast, setIncomingEndDatePast] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);
  const [instructions, setInstructions] = useState('');
  const [resStatus, setResStatus] = useState();
  const [isDataNull, setIsDataNull] = useState(false);
  const [incomingCvoIsNull, setIncomingCvoIsNull] = useState(false);
  const [errorFlag, setErrorFlag] = useState(false);

  let countryData = [];

  const [initialValues, setInitialValues] = useState({
    cvoAddress1: '',
    cvoAddress2: '',
    cvoCity: '',
    cvoContactDetailsId: '',
    cvoEmail: '',
    cvoEndDate: '',
    cvoFname: '',
    cvoLname: '',
    cvoPhone: '',
    cvoStartDate: '',
    cvoCountry: '',
    cvoState: '',
    cvoZip: '',
    isCurrentCvo: '',
    cvoBirthDate: '',
    cvoOrganization: '',
    cvoBusinessTitle: ''
  });

  const [incomingCvoInitalValues, setIncomingCvoInitalValues] = useState({
    inComingCvoAddress1: '',
    inComingCvoAddress2: '',
    inComingCvoCity: '',
    inComingCvoCountry: '',
    inComingCvoEmail: '',
    inComingCvoEndDate: '',
    inComingCvoFname: '',
    inComingCvoLname: '',
    inComingCvoPhone: '',
    inComingCvoStartDate: '',
    inComingCvoState: '',
    inComingCvoZip: '',
    isValidIncomingCvo: '',
    inComingCvoBirthDate: '',
    incomingCvoContactDetailsId: '',
    inComingCvoOrganization: '',
    inComingCvoBusinessTitle: ''
  });

  const convertedValue = value => value || null;

  let getCvoUpdateData = null;
  let getIncomingCvoData = null;

  const calculateAge = date => {
    const dateObj = new Date(date);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - dateObj.getFullYear();
    const monthDiff = currentDate.getMonth() - dateObj.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < dateObj.getDate())
    ) {
      return age - 1;
    }
    return age;
  };

  const callPostAPI = resultData => {
    setLoader(true);
    handlePostIncomingCvoContactDetailsApi(resultData)
      .then(res => {
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
        setLoader(false);
        if (res?.data?.isSuccess) {
          setResStatus(res.data.data?.recordStatus);
          getIncomingCvoData(false, true);
        } else {
          setErrorMessage(res?.message);
          setErrorPopup(true);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const formikIncomingCvo = useFormik({
    initialValues: incomingCvoInitalValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      inComingCvoAddress1: Yup.string().nullable(),
      inComingCvoAddress2: Yup.string().nullable(),
      inComingCvoEndDate: Yup.string().nullable(),
      inComingCvoCity: Yup.string().nullable(),
      inComingCvoEmail: Yup.string().email('Invalid email').nullable(),
      inComingCvoFname: Yup.string().nullable(),
      inComingCvoOrganization: Yup.string().nullable(),
      inComingCvoBusinessTitle: Yup.string().nullable(),
      inComingCvoLname: Yup.string().nullable(),
      inComingCvoPhone: Yup.string()
        .matches(
          /^(1\s?)?(\d{3}|\(\d{3}\))-?\d{3}-?\d{4}$/g,
          'Phone number must be valid'
        )
        .nullable(),
      inComingCvoStartDate: Yup.string().nullable(),
      inComingCvoState: Yup.string().nullable(),
      inComingCvoCountry: Yup.string().nullable(),
      inComingCvoZip: Yup.string()
        .matches(/^((\d{5}(?:-\d{4})?$)|(\d{9}))*$/g, 'Zipcode must be valid')
        .nullable()
    }),
    onSubmit: values => {
      let resultData = { ...values };
      setIncomingEndDatePast(false);
      setIncomingCVOAge(calculateAge(resultData?.inComingCvoBirthDate));
      if (
        moment(formikIncomingCvo.values.inComingCvoEndDate).isBefore(
          moment().startOf('day')
        )
      ) {
        setIncomingEndDatePast(true);
        return;
      }
      if (calculateAge(resultData?.inComingCvoBirthDate) < 18) {
        return;
      }

      if (resultData.isValidIncomingCvo === '') {
        setErrorFlag(true);
        return;
      }

      if (resultData.isValidIncomingCvo === 'null') {
        resultData.inComingCvoAddress1 = null;
        resultData.inComingCvoAddress2 = null;
        resultData.inComingCvoCity = null;
        resultData.inComingCvoCountry = null;
        resultData.inComingCvoEmail = null;
        resultData.inComingCvoEndDate = null;
        resultData.inComingCvoFname = null;
        resultData.inComingCvoOrganization = null;
        resultData.inComingCvoBusinessTitle = null;
        resultData.inComingCvoLname = null;
        resultData.inComingCvoPhone = null;
        resultData.inComingCvoStartDate = null;
        resultData.inComingCvoState = null;
        resultData.inComingCvoZip = null;
        resultData.isValidIncomingCvo = null;
        resultData.inComingCvoBirthDate = null;
        resultData.personId = null;
        resultData.staffId = null;
        resultData.personAddressId = null;
      } else {
        resultData.inComingCvoAddress1 = convertedValue(
          resultData.inComingCvoAddress1
        );
        resultData.inComingCvoAddress2 = convertedValue(
          resultData.inComingCvoAddress2
        );
        resultData.inComingCvoCity = convertedValue(resultData.inComingCvoCity);
        resultData.inComingCvoCountry = convertedValue(
          resultData.inComingCvoCountry
        );
        resultData.inComingCvoEmail = convertedValue(
          resultData.inComingCvoEmail
        );
        resultData.inComingCvoEndDate = convertedValue(
          resultData.inComingCvoEndDate
        );
        resultData.inComingCvoFname = convertedValue(
          resultData.inComingCvoFname
        );
        resultData.inComingCvoOrganization = convertedValue(
          resultData.inComingCvoOrganization
        );
        resultData.inComingCvoBusinessTitle = convertedValue(
          resultData.inComingCvoBusinessTitle
        );
        resultData.inComingCvoLname = convertedValue(
          resultData.inComingCvoLname
        );
        resultData.inComingCvoPhone = convertedValue(
          resultData.inComingCvoPhone
        );
        resultData.inComingCvoStartDate = convertedValue(
          resultData.inComingCvoStartDate
        );
        resultData.inComingCvoState = convertedValue(
          resultData.inComingCvoState
        );
        resultData.inComingCvoZip = convertedValue(resultData.inComingCvoZip);
        if (
          resultData?.isValidIncomingCvo?.toString().toLowerCase() === 'true'
        ) {
          resultData.isValidIncomingCvo = true;
        } else if (
          resultData?.isValidIncomingCvo?.toString().toLowerCase() === 'false'
        ) {
          resultData.isValidIncomingCvo = false;
        } else {
          resultData.isValidIncomingCvo = null;
        }
        resultData.inComingCvoBirthDate = convertedValue(
          resultData.inComingCvoBirthDate
        );
      }

      if (selectedYear === '2022') {
        const { inComingCvoOrganization, ...restinComingCvoOrganization } =
          resultData;
        const { inComingCvoBusinessTitle, ...restinComingCvoBusinessTitle } =
          restinComingCvoOrganization;
        resultData = { ...restinComingCvoBusinessTitle };
      }
      setSuccessPopup(false);
      callPostAPI(resultData);
    }
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      cvoAddress1: Yup.string().nullable(),
      cvoAddress2: Yup.string().nullable(),
      cvoEndDate: Yup.string().nullable(),
      cvoCity: Yup.string().nullable(),
      cvoEmail: Yup.string().email('Invalid email').nullable(),
      cvoFname: Yup.string().nullable(),
      cvoOrganization: Yup.string().nullable(),
      cvoBusinessTitle: Yup.string().nullable(),
      cvoLname: Yup.string().nullable(),
      cvoPhone: Yup.string()
        .matches(
          /^(1\s?)?(\d{3}|\(\d{3}\))-?\d{3}-?\d{4}$/g,
          'Phone number must be valid'
        )
        .nullable(),
      cvoStartDate: Yup.string().nullable(),
      cvoState: Yup.string().nullable(),
      cvoCountry: Yup.string().nullable(),
      cvoZip: Yup.string()
        .matches(/^((\d{5}(?:-\d{4})?$)|(\d{9}))*$/g, 'Zipcode must be valid')
        .nullable()
    }),
    onSubmit: values => {
      setEndDateGreater(false);
      setEndDatePast(false);
      let resultData = { ...values };
      resultData.cvoAddress1 = convertedValue(resultData.cvoAddress1);
      resultData.cvoAddress2 = convertedValue(resultData.cvoAddress2);
      resultData.cvoCity = convertedValue(resultData.cvoCity);
      resultData.cvoEmail = convertedValue(resultData.cvoEmail);
      resultData.cvoEndDate = convertedValue(resultData.cvoEndDate);
      resultData.cvoFname = convertedValue(resultData.cvoFname);
      resultData.cvoOrganization = convertedValue(resultData.cvoOrganization);
      resultData.cvoBusinessTitle = convertedValue(resultData.cvoBusinessTitle);
      resultData.cvoLname = convertedValue(resultData.cvoLname);
      resultData.cvoPhone = convertedValue(resultData.cvoPhone);
      resultData.cvoStartDate = convertedValue(resultData.cvoStartDate);
      resultData.cvoCountry = convertedValue(resultData.cvoCountry);
      resultData.cvoState = convertedValue(resultData.cvoState);
      resultData.cvoZip = convertedValue(resultData.cvoZip);
      resultData.cvoBirthDate = convertedValue(resultData.cvoBirthDate);
      resultData.isCurrentCvo = resultData.isCurrentCvo
        ? `${resultData.isCurrentCvo}` === 'true'
        : null;
      if (
        moment(formikIncomingCvo.values.inComingCvoStartDate) <
        moment(formik.values.cvoEndDate)
      ) {
        setEndDateGreater(true);
        return;
      }
      if (moment(formik.values.cvoEndDate).isBefore(moment().startOf('day'))) {
        setEndDatePast(true);
        return;
      }
      if (selectedYear === '2022') {
        const { cvoOrganization, ...restcvoOrganization } = resultData;
        const { cvoBusinessTitle, ...restcvoBusinessTitle } =
          restcvoOrganization;
        resultData = { ...restcvoBusinessTitle };
      }

      setCVOAge(calculateAge(resultData?.cvoBirthDate));

      if (calculateAge(resultData?.cvoBirthDate) < 18) {
        return;
      }

      setSuccessPopup(false);
      setLoader(true);
      handlePostARContactDetailsApi(resultData)
        .then(res => {
          handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
            dashData => {
              dashData?.data?.data?.dashboardDTOs.forEach(record => {
                if (
                  record.name === sectionName &&
                  record.status === 'Completed' &&
                  record.rating === null &&
                  selectedYear >= '2023' &&
                  role !== APP_LABELS.YUSA_DATA_STEWARD &&
                  role !== APP_LABELS.YESS_DATA_STEWARD
                ) {
                  setFeedbackPopup(true);
                  setFormId(record?.formId);
                }
              });
            }
          );
          setLoader(false);
          if (res?.data?.isSuccess) {
            setResStatus(res.data.data?.recordStatus);
            getCvoUpdateData(false, true);
          } else {
            setErrorMessage(res?.message);
            setErrorPopup(true);
          }
        })
        .catch(() => {
          setLoader(false);
        });
    }
  });

  const updateCVOdataInitialDetails = result => {
    const resultData = { ...result?.data?.data };
    if (
      resultData?.inComingCvoAddress1 === null &&
      resultData?.inComingCvoAddress2 === null &&
      resultData?.inComingCvoCity === null &&
      resultData?.inComingCvoEmail === null &&
      !resultData?.inComingCvoCountry &&
      resultData?.inComingCvoFname === null &&
      resultData?.inComingCvoOrganization === null &&
      resultData?.inComingCvoBusinessTitle === null &&
      resultData?.inComingCvoLname === null &&
      resultData?.inComingCvoPhone === null &&
      resultData?.inComingCvoStartDate === null &&
      resultData?.inComingCvoZip === null &&
      resultData?.inComingCvoBirthDate === null &&
      resultData?.inComingCvoEndDate === null &&
      resultData?.incomingCvoContactDetailsId === 0
    ) {
      resultData.isValidIncomingCvo = '';
      setIncomingCvoIsNull(true);
    }
    if (!resultData?.inComingCvoCountry && countryData.length > 0)
      resultData.inComingCvoCountry = '';
    if (!resultData?.inComingCvoState) resultData.inComingCvoState = '';
    if (resultData?.isValidIncomingCvo === null) {
      resultData.isValidIncomingCvo = 'null';
    }
    formikIncomingCvo.setFieldValue(
      'isValidIncomingCvo',
      resultData.isValidIncomingCvo
    );
    if (
      resultData.inComingCvoZip?.trim()?.length === 6 &&
      resultData.inComingCvoZip?.trim()?.includes('-')
    ) {
      resultData.inComingCvoZip = resultData.inComingCvoZip
        .replace('-', '')
        .trim();
    }
    setIncomingCvoInitalValues(resultData);
  };

  getIncomingCvoData = async (loader, success) => {
    if (loader) setLoader(true);
    await handlegetIcomingCvoContactDetailsApi(associationNumber)
      .then(result => {
        if (loader) setLoader(false);
        if (result?.data?.isSuccess) {
          updateCVOdataInitialDetails(result);
          if (success) {
            setSuccessPopup(true);
          }
        } else {
          setLoader(false);
          setErrorMessage(result?.message);
        }
      })
      .catch(() => {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      });
  };

  const updateInitialValues = result => {
    const resultData = { ...result?.data?.data };
    if (
      resultData?.cvoAddress1 === null &&
      resultData?.cvoAddress2 === null &&
      resultData?.cvoCity === null &&
      resultData?.cvoEmail === null &&
      resultData?.cvoFname === null &&
      resultData?.cvoOrganization === null &&
      resultData?.cvoBusinessTitle === null &&
      resultData?.cvoLname === null &&
      resultData?.cvoPhone === null &&
      resultData?.cvoStartDate === null &&
      resultData?.cvoZip === null &&
      resultData?.cvoBirthDate === null &&
      resultData?.cvoEndDate === null &&
      !resultData?.cvoCountry
    ) {
      setIsDataNull(true);
      resultData.isCurrentCvo = 'false';
    }
    if (!resultData?.inComingCvoCountry && countryData.length > 0)
      resultData.inComingCvoCountry = '';
    if (!resultData?.inComingCvoState) resultData.inComingCvoState = '';
    if (!resultData?.cvoCountry && countryData.length > 0)
      resultData.cvoCountry = '';
    if (!resultData?.cvoState) resultData.cvoState = '';
    if (resultData?.isCurrentCvo === null) {
      resultData.isCurrentCvo = 'true';
    } else if (!resultData?.isCurrentCvo) {
      resultData.isCurrentCvo = 'false';
    }
    if (
      resultData.cvoZip?.trim()?.length === 6 &&
      resultData.cvoZip?.trim()?.includes('-')
    ) {
      console.log('innnn');
      resultData.cvoZip = resultData.cvoZip.replace('-', '').trim();
    }
    setInitialValues(resultData);
  };

  getCvoUpdateData = async (loader, success) => {
    if (loader) setLoader(true);

    await handleToGetCountries().then(result => {
      setCountriesResponse(result?.data?.data);
      countryData = result?.data?.data;
    });
    await handlegetARContactDetailsApi(associationNumber)
      .then(result => {
        if (loader) setLoader(false);
        if (result?.data?.isSuccess) {
          updateInitialValues(result);
          if (success) {
            setSuccessPopup(true);
          }
        } else {
          setLoader(false);
          setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        }
      })
      .catch(() => {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      });
  };

  const getStates = countryWithType => {
    const findState = countriesResponse.find(
      element => element.name === formik.values[countryWithType]
    );
    return findState?.states?.map(obj => obj.name);
  };

  const incomingGetStates = countryWithType => {
    const findState = countriesResponse.find(
      element => element.name === formikIncomingCvo.values[countryWithType]
    );
    return findState?.states?.map(obj => obj.name);
  };

  useEffect(async () => {
    formik.resetForm({ values: initialValues });
    formikIncomingCvo.resetForm({ values: incomingCvoInitalValues });
    getCvoUpdateData(true);
    getIncomingCvoData(true);
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'current-and-incoming-cvo-information'
      ]?.Instruction
    );
  }, []);

  useEffect(() => {
    sessionStorage.setItem('dirty', formik.dirty);
  }, [formik.dirty]);

  const countryChangeHandler = e => {
    const selectedCountry = e.target.value;
    const selectedStateType = e.target.name === 'inComingCvoState';

    formik.setFieldValue(e.target.name, selectedCountry);

    if (e.target.name === 'inComingCvoCountry') {
      formik.setFieldValue('inComingCvoState', '');
    } else {
      const findState = countriesResponse.find(
        element => element.name === selectedCountry
      );
      formik.setFieldValue(selectedStateType, findState.states[0].name);
    }
  };

  const incomingCountryChangeHandler = e => {
    const selectedCountry = e.target.value;
    const selectedStateType = e.target.name === 'inComingCvoState';

    formikIncomingCvo.setFieldValue(e.target.name, selectedCountry);

    if (e.target.name === 'inComingCvoCountry') {
      formikIncomingCvo.setFieldValue('inComingCvoState', '');
    } else {
      const findState = countriesResponse.find(
        element => element.name === selectedCountry
      );
      formikIncomingCvo.setFieldValue(
        selectedStateType,
        findState.states[0].name
      );
    }
  };

  const getCountries = () => countriesResponse?.map(obj => obj.name);

  const handleSignaturename = e => {
    const regex = /^[a-zA-Z0-9\s]*$/;
    if (regex.test(e.target.value)) {
      formik.setFieldValue(e.target.name, e.target.value.replace(' ', ''));
    }
  };
  const handleTitleName = e => {
    formik.setFieldValue(e.target.name, e.target.value.trimStart());
  };
  const handleIncomingTitleName = e => {
    formikIncomingCvo.setFieldValue(e.target.name, e.target.value.trimStart());
  };
  const handleCvoName = e => {
    const regex = /^[a-zA-Z0-9\s]*$/;
    if (regex.test(e.target.value)) {
      formikIncomingCvo.setFieldValue(
        e.target.name,
        e.target.value.replace(' ', '')
      );
    }
  };

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  const clearIncomingValues = e => {
    if (e.target.checked) {
      const resultData = { ...incomingCvoInitalValues };
      resultData.inComingCvoAddress1 = '';
      resultData.inComingCvoAddress2 = '';
      resultData.inComingCvoCity = '';
      resultData.inComingCvoEmail = '';
      resultData.inComingCvoEndDate = '';
      resultData.inComingCvoFname = '';
      resultData.inComingCvoOrganization = '';
      resultData.inComingCvoBusinessTitle = '';
      resultData.inComingCvoLname = '';
      resultData.inComingCvoPhone = '';
      resultData.inComingCvoStartDate = '';
      resultData.inComingCvoCountry = '';
      resultData.inComingCvoState = '';
      resultData.inComingCvoZip = '';
      resultData.inComingCvoBirthDate = '';
      resultData.isValidIncomingCvo = 'false';
      setIncomingCvoInitalValues(resultData);
    }
    setErrorFlag(false);
    formikIncomingCvo.setFieldValue(e.target.name, 'false');
  };

  const clearValues = e => {
    if (e.target.checked) {
      const resultData = { ...initialValues };
      resultData.cvoAddress1 = '';
      resultData.cvoAddress2 = '';
      resultData.cvoCity = '';
      resultData.cvoEmail = '';
      resultData.cvoEndDate = '';
      resultData.cvoFname = '';
      resultData.cvoOrganization = '';
      resultData.cvoBusinessTitle = '';
      resultData.cvoLname = '';
      resultData.cvoPhone = '';
      resultData.cvoStartDate = '';
      resultData.cvoCountry = '';
      resultData.cvoState = '';
      resultData.cvoZip = '';
      resultData.cvoBirthDate = '';
      resultData.isCurrentCvo = 'false';
      setInitialValues(resultData);
    }
    formik.setFieldValue(e.target.name, 'false');
  };

  const handleNonCvo = e => {
    setErrorFlag(false);
    formikIncomingCvo.setFieldValue(e.target.name, 'null');
  };

  const handleIncomingCvoChange = e => {
    setErrorFlag(false);
    formikIncomingCvo.setFieldValue(e.target.name, 'true');
  };

  const handleCurrentCvoChange = e => {
    formik.setFieldValue(e.target.name, 'true');
  };

  const handleCVOBirthDate = e => {
    formik.setFieldValue('cvoBirthDate', e.target.value);
    setCVOAge(calculateAge(e.target.value));
  };

  const handleIncomingCVOBirthDate = e => {
    formikIncomingCvo.setFieldValue('inComingCvoBirthDate', e.target.value);
    setIncomingCVOAge(calculateAge(e.target.value));
  };

  // Calculate the minimum date 100 years ago from today
  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() - 100);
  const minDate = currentDate.toISOString().split('T')[0]; // Convert to yyyy-mm-dd format

  return (
    <div>
      <div className="ymca-instructionBoxes">
        <h4 className="ymca-instructHeadline">{APP_LABELS.INSTRUCTIONS}</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: instructions
          }}
        />
      </div>
      <div className="ymc-alert pt-3">
        {CvoConatactDetails?.data?.recordStatus?.toUpperCase() ===
          APP_LABELS.STATUS_PARTIAL ||
        IncomingCvoContactDataSelectorDetails?.data?.recordStatus?.toUpperCase() ===
          APP_LABELS.STATUS_PARTIAL ? (
          <PartialSaveWarningPopup />
        ) : (
          ''
        )}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="ymca-program ymca-pledge-section">
          <div className="row mb-4">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <h4 className="ymca-programActivities">
                Does your current CVO’s contact and term information below need
                to be updated? Please select one of the options below.
              </h4>
            </div>

            <div className="ymca-radioBTN d-flex mx-3">
              <div className="ymca-setListing me-4">
                <input
                  type="radio"
                  name="isCurrentCvo"
                  value="true"
                  className="ymca-fieldRadio"
                  onChange={e => handleCurrentCvoChange(e)}
                  checked={formik?.values?.isCurrentCvo?.toString() === 'true'}
                />
                <span className="ymca-texting">
                  The person listed below is our current CVO/Board Chair. I will
                  review and update their information where necessary.
                </span>
              </div>
            </div>
            <div className="ymca-radioBTN d-flex mx-3">
              <div className="ymca-setListing">
                <input
                  type="radio"
                  name="isCurrentCvo"
                  value="false"
                  className="ymca-fieldRadio"
                  onChange={e => clearValues(e)}
                  checked={formik?.values?.isCurrentCvo?.toString() === 'false'}
                />
                <span className="ymca-texting">
                  The person listed below is not our current CVO/Board Chair. I
                  will provide new contact information.
                </span>
              </div>
            </div>
            <div className="d-flex flex-column mb-4">
              {isDataNull && (
                <div className="errorNote position-static fw-bold">
                  Current cvo details could not found in system.
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <h4 className="ymca-programActivities">Current CVO Details</h4>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="ymca-selectWrap">
                <div className="ymca-labelling ymca-dullText txt-mandatory ">
                  First Name
                </div>
                <input
                  type="text"
                  className={`ymca-fieldSet ${
                    formik.touched.cvoFname && formik.errors.cvoFname
                      ? 'invalid'
                      : ''
                  }`}
                  placeholder=""
                  name="cvoFname"
                  onChange={handleSignaturename}
                  onBlur={formik.handleBlur}
                  value={formik.values.cvoFname}
                  maxLength={255}
                />
                {formik.touched.cvoFname && formik.errors.cvoFname ? (
                  <div className="errorNote">{formik.errors.cvoFname}</div>
                ) : null}
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="ymca-selectWrap">
                <div className="ymca-labelling ymca-dullText txt-mandatory">
                  Last Name
                </div>
                <input
                  type="text"
                  className={`ymca-fieldSet ${
                    formik.touched.cvoLname && formik.errors.cvoLname
                      ? 'invalid'
                      : ''
                  }`}
                  placeholder=""
                  name="cvoLname"
                  onChange={handleSignaturename}
                  onBlur={formik.handleBlur}
                  value={formik.values.cvoLname}
                />
                {formik.touched.cvoLname && formik.errors.cvoLname ? (
                  <div className="errorNote">{formik.errors.cvoLname}</div>
                ) : null}
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="ymca-selectWrap">
                <div className="ymca-labelling ymca-dullText txt-mandatory">
                  Date of Birth
                </div>
                <input
                  type="date"
                  className="ymca-fieldSet"
                  placeholder=""
                  name="cvoBirthDate"
                  onChange={e => handleCVOBirthDate(e)}
                  onBlur={formik.handleBlur}
                  min={moment(minDate).format('YYYY-MM-DD')}
                  max={moment(new Date()).format('YYYY-MM-DD')}
                  value={moment(formik.values.cvoBirthDate).format(
                    'YYYY-MM-DD'
                  )}
                />
                {cVOAge !== null && (cVOAge < 18 || cVOAge > 100) && (
                  <p className="errorNote position-static fw-bold mb-5">
                    {cVOAge < 18
                      ? '* Age should be more than 18 years'
                      : '* Age above 100 years is not valid'}
                  </p>
                )}
              </div>
            </div>
            {selectedYear !== '2022' && (
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="ymca-selectWrap">
                  <div className="ymca-labelling ymca-dullText txt-mandatory ">
                    CVO Organization
                  </div>
                  <input
                    type="text"
                    className={`ymca-fieldSet ${
                      formik.touched.cvoOrganization &&
                      formik.errors.cvoOrganization
                        ? 'invalid'
                        : ''
                    }`}
                    placeholder=""
                    name="cvoOrganization"
                    onChange={handleTitleName}
                    onBlur={formik.handleBlur}
                    value={formik.values.cvoOrganization}
                    maxLength={255}
                  />
                  {formik.touched.cvoOrganization &&
                  formik.errors.cvoOrganization ? (
                    <div className="errorNote">
                      {formik.errors.cvoOrganization}
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
          {selectedYear !== '2022' && (
            <div className="row mb-4">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="ymca-selectWrap">
                  <div className="ymca-labelling ymca-dullText txt-mandatory ">
                    CVO Business Title
                  </div>
                  <input
                    type="text"
                    className={`ymca-fieldSet ${
                      formik.touched.cvoBusinessTitle &&
                      formik.errors.cvoBusinessTitle
                        ? 'invalid'
                        : ''
                    }`}
                    placeholder=""
                    name="cvoBusinessTitle"
                    onChange={handleTitleName}
                    onBlur={formik.handleBlur}
                    value={formik.values.cvoBusinessTitle}
                    maxLength={255}
                  />
                  {formik.touched.cvoBusinessTitle &&
                  formik.errors.cvoBusinessTitle ? (
                    <div className="errorNote">
                      {formik.errors.cvoBusinessTitle}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}

          <div className="row mb-4">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <h4 className="ymca-programActivities">Address Details</h4>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="ymca-selectWrap">
                <div className="ymca-labelling ymca-dullText txt-mandatory">
                  Address Line1
                </div>
                <input
                  type="text"
                  className={`ymca-fieldSet ${
                    formik.touched.cvoAddress1 && formik.errors.cvoAddress1
                      ? 'invalid'
                      : ''
                  }`}
                  placeholder=""
                  name="cvoAddress1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cvoAddress1}
                  maxLength={500}
                />
                {formik.touched.cvoAddress1 && formik.errors.cvoAddress1 ? (
                  <div className="errorNote">{formik.errors.cvoAddress1}</div>
                ) : null}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="ymca-selectWrap">
                <div className="ymca-labelling ymca-dullText">
                  Address Line2
                </div>
                <input
                  type="text"
                  className={`ymca-fieldSet ${
                    formik.touched.cvoAddress2 && formik.errors.cvoAddress2
                      ? 'invalid'
                      : ''
                  }`}
                  placeholder=""
                  name="cvoAddress2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cvoAddress2}
                  maxLength={500}
                />
                {formik.touched.cvoAddress2 && formik.errors.cvoAddress2 ? (
                  <div className="errorNote">{formik.errors.cvoAddress2}</div>
                ) : null}
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="ymca-selectWrap">
                <div className="ymca-labelling ymca-dullText txt-mandatory">
                  City
                </div>
                <input
                  type="text"
                  className={`ymca-fieldSet ${
                    formik.touched.cvoCity && formik.errors.cvoCity
                      ? 'invalid'
                      : ''
                  }`}
                  placeholder=""
                  name="cvoCity"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cvoCity}
                  maxLength={255}
                />
                {formik.touched.cvoCity && formik.errors.cvoCity ? (
                  <div className="errorNote">{formik.errors.cvoCity}</div>
                ) : null}
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
              <div className="ymca-selectWrap">
                <div className="ymca-labelling ymca-dullText txt-mandatory">
                  Country
                </div>
                <select
                  name="cvoCountry"
                  className="ymca-select ymca-customArrow notranslate"
                  onChange={countryChangeHandler}
                  onBlur={formik.handleBlur}
                  value={formik.values.cvoCountry}
                >
                  <option value="">--Select--</option>
                  {countriesResponse &&
                    getCountries()?.map(data => (
                      <option key={data} value={data}>
                        {data}
                      </option>
                    ))}
                </select>
                {formik.touched.cvoCountry && formik.errors.cvoCountry ? (
                  <div className="errorNote">{formik.errors.cvoCountry}</div>
                ) : null}
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
              <div className="ymca-selectWrap">
                <div className="ymca-labelling ymca-dullText txt-mandatory">
                  State
                </div>
                <select
                  name="cvoState"
                  className="ymca-select ymca-customArrow notranslate"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cvoState}
                >
                  <option value="">--Select--</option>
                  {countriesResponse &&
                    formik.values.cvoCountry &&
                    getStates('cvoCountry')?.map(data => (
                      <option key={data} value={data}>
                        {data}
                      </option>
                    ))}
                </select>
                {formik.touched.cvoState && formik.errors.cvoState ? (
                  <div className="errorNote">{formik.errors.cvoState}</div>
                ) : null}
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
              <div className="ymca-selectWrap">
                <div className="ymca-labelling ymca-dullText txt-mandatory">
                  Zipcode
                </div>
                <input
                  type="text"
                  className={`ymca-fieldSet ${
                    formik.touched.cvoZip && formik.errors.cvoZip
                      ? 'invalid'
                      : ''
                  }`}
                  placeholder=""
                  name="cvoZip"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cvoZip}
                />
                {formik.touched.cvoZip && formik.errors.cvoZip ? (
                  <div className="errorNote">{formik.errors.cvoZip}</div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <h4 className="ymca-programActivities">Contact Details</h4>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="ymca-selectWrap">
                <div className="ymca-labelling ymca-dullText txt-mandatory">
                  Email
                </div>
                <input
                  type="email"
                  className={`ymca-fieldSet ${
                    formik.touched.cvoEmail && formik.errors.cvoEmail
                      ? 'invalid'
                      : ''
                  }`}
                  placeholder=""
                  name="cvoEmail"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cvoEmail}
                  maxLength={100}
                />
                {formik.touched.cvoEmail && formik.errors.cvoEmail ? (
                  <div className="errorNote">{formik.errors.cvoEmail}</div>
                ) : null}
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="ymca-selectWrap">
                <div className="ymca-labelling ymca-dullText txt-mandatory">
                  Phone Number
                </div>
                <input
                  type="text"
                  placeholder=""
                  name="cvoPhone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cvoPhone}
                  className={`ymca-fieldSet ${
                    formik.touched.cvoPhone && formik.errors.cvoPhone
                      ? 'invalid'
                      : ''
                  }`}
                />
                {formik.touched.cvoPhone && formik.errors.cvoPhone ? (
                  <div className="errorNote">{formik.errors.cvoPhone}</div>
                ) : null}
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="ymca-selectWrap">
                <div className="ymca-labelling ymca-dullText txt-mandatory">
                  Start Date
                </div>
                <input
                  type="date"
                  className="ymca-fieldSet"
                  placeholder=""
                  name="cvoStartDate"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  min={moment(new Date('1/1/1901 12:00:00')).format(
                    'YYYY-MM-DD'
                  )}
                  max={moment(new Date()).format('YYYY-MM-DD')}
                  value={moment(formik.values.cvoStartDate).format(
                    'YYYY-MM-DD'
                  )}
                />
                {formik.touched.cvoStartDate && formik.errors.cvoStartDate ? (
                  <div className="errorNote">{formik.errors.cvoStartDate}</div>
                ) : null}
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="ymca-selectWrap">
                <div
                  className={`ymca-labelling ymca-dullText ${
                    selectedYear >= '2023' ? 'txt-mandatory' : ''
                  }`}
                >
                  End Date
                </div>
                <input
                  type="date"
                  className="ymca-fieldSet"
                  placeholder=""
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  min={
                    moment(
                      new Date(formik.values?.cvoStartDate).setDate(
                        new Date(formik.values?.cvoStartDate).getDate() + 1
                      )
                    ).format('YYYY-MM-DD') ||
                    moment(new Date()).format('YYYY-MM-DD')
                  }
                  value={moment(formik.values.cvoEndDate).format('YYYY-MM-DD')}
                  name="cvoEndDate"
                  disabled={!formik.values.cvoStartDate}
                  // min={moment(new Date()).format('YYYY-MM-DD')}
                />
                {formik.touched.cvoEndDate && formik.errors.cvoEndDate ? (
                  <div className="errorNote">{formik.errors.cvoEndDate}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="ymca-submitHighlighter sub-highlighter">
          <LastUpdateName
            Username={formik.values?.updatedByName}
            Time={formik.values?.updatedDatetime}
          />
        </div>
        <div className="d-flex flex-column ">
          {endDatePast && (
            <div className="errorNote position-static fw-bold ms-2">
              {
                'Your current CVO must not have a past end date and be expired. Please update your current CVO in MOYP with the correct information.'
              }
            </div>
          )}
        </div>
        <div className="d-flex flex-column ">
          {endDateGreater && (
            <div className="errorNote position-static fw-bold ms-2">
              {
                'Incoming CVO start date must be greater than current CVO end date'
              }
            </div>
          )}
        </div>
        {(((role === APP_LABELS.CEO_DATA_STEWARD ||
          role === APP_LABELS.CVO_DATA_STEWARD) &&
          selectedYear === '2022') ||
          ((role === APP_LABELS.CEO_DATA_STEWARD ||
            role === APP_LABELS.CVO_DATA_STEWARD ||
            role === APP_LABELS.YUSA_DATA_STEWARD ||
            role === APP_LABELS.ASSOCIATION_DATA_STEWARD ||
            role === APP_LABELS.YESS_DATA_STEWARD) &&
            selectedYear >= '2023')) && (
          <div className="clearfix">
            <button
              type="submit"
              className="btn ymca-savingBTN float-end mt-2 me-2"
              onClick={() => scrollToErrors(formik.errors)}
              disabled={
                status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED ||
                (cVOAge !== null && (cVOAge < 18 || cVOAge > 100))
              }
            >
              Save
            </button>
          </div>
        )}
      </form>
      <form onSubmit={formikIncomingCvo.handleSubmit}>
        <div className="ymca-program ymca-pledge-section">
          <div className="row mb-4">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <h4 className="ymca-programActivities">
                Does your incoming CVO’s contact and term information below need
                to be updated? Please select one of the options below.
              </h4>
            </div>
            <div className="ymca-radioBTN d-flex mx-3">
              <div className="ymca-setListing me-4">
                <input
                  type="radio"
                  name="isValidIncomingCvo"
                  value="true"
                  className="ymca-fieldRadio"
                  onChange={e => handleIncomingCvoChange(e)}
                  checked={
                    formikIncomingCvo?.values?.isValidIncomingCvo?.toString() ===
                    'true'
                  }
                />
                <span className="ymca-texting">
                  The person listed below is our incoming CVO. I will review and
                  update their information where necessary.
                </span>
              </div>
            </div>
            <div className="ymca-radioBTN d-flex mx-3">
              <div className="ymca-setListing">
                <input
                  type="radio"
                  name="isValidIncomingCvo"
                  value="false"
                  className="ymca-fieldRadio"
                  onChange={e => clearIncomingValues(e)}
                  checked={
                    formikIncomingCvo?.values?.isValidIncomingCvo?.toString() ===
                    'false'
                  }
                />
                <span className="ymca-texting">
                  The person listed below is not our incoming CVO. I will
                  provide new contact information.
                </span>
              </div>
            </div>
            <div className="ymca-radioBTN d-flex mx-3">
              <div className="ymca-setListing">
                <input
                  type="radio"
                  name="isValidIncomingCvo"
                  value="null"
                  className="ymca-fieldRadio"
                  onChange={e => handleNonCvo(e)}
                  checked={
                    formikIncomingCvo?.values?.isValidIncomingCvo?.toString() ===
                    'null'
                  }
                />
                <span className="ymca-texting">
                  We don't have Incoming CVO Details.
                </span>
              </div>
            </div>
            {errorFlag && (
              <div className="errorNote position-static fw-bold">
                * Please select one option.
              </div>
            )}
          </div>
          <div className="d-flex flex-column mb-4">
            {incomingCvoIsNull &&
              formikIncomingCvo?.values?.isValidIncomingCvo?.toString() !==
                'true' && (
                <div className="errorNote position-static fw-bold">
                  Incoming CVO details are not found in the system.
                </div>
              )}
          </div>
          {formikIncomingCvo?.values?.isValidIncomingCvo?.toString() !==
            'null' &&
            formikIncomingCvo?.values?.isValidIncomingCvo?.toString() !==
              '' && (
              <>
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h4 className="ymca-programActivities">
                      Incoming CVO Details
                    </h4>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="ymca-selectWrap">
                      <div className="ymca-labelling ymca-dullText txt-mandatory">
                        First Name
                      </div>
                      <input
                        type="text"
                        className="ymca-fieldSet"
                        placeholder=""
                        name="inComingCvoFname"
                        onChange={handleCvoName}
                        onBlur={formikIncomingCvo.handleBlur}
                        value={formikIncomingCvo?.values?.inComingCvoFname}
                        maxLength={255}
                      />
                      {formikIncomingCvo?.touched?.inComingCvoFname &&
                      formikIncomingCvo?.errors?.inComingCvoFname ? (
                        <div className="errorNote">
                          {formikIncomingCvo?.errors?.inComingCvoFname}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="ymca-selectWrap">
                      <div className="ymca-labelling ymca-dullText txt-mandatory">
                        Last Name
                      </div>
                      <input
                        type="text"
                        className={`ymca-fieldSet ${
                          formikIncomingCvo?.touched?.inComingCvoLname &&
                          formikIncomingCvo?.errors?.inComingCvoLname
                            ? 'invalid'
                            : ''
                        }`}
                        placeholder=""
                        name="inComingCvoLname"
                        onChange={handleCvoName}
                        onBlur={formikIncomingCvo.handleBlur}
                        value={formikIncomingCvo?.values?.inComingCvoLname}
                      />
                      {formikIncomingCvo?.touched?.inComingCvoLname &&
                      formikIncomingCvo?.errors?.inComingCvoLname ? (
                        <div className="errorNote">
                          {formikIncomingCvo?.errors?.inComingCvoLname}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="ymca-selectWrap">
                      <div className="ymca-labelling ymca-dullText txt-mandatory">
                        Date of Birth
                      </div>
                      <input
                        type="date"
                        className="ymca-fieldSet"
                        placeholder=""
                        name="inComingCvoBirthDate"
                        onChange={e => handleIncomingCVOBirthDate(e)}
                        onBlur={formikIncomingCvo.handleBlur}
                        min={moment(minDate).format('YYYY-MM-DD')}
                        max={moment(new Date()).format('YYYY-MM-DD')}
                        value={moment(
                          formikIncomingCvo?.values?.inComingCvoBirthDate
                        ).format('YYYY-MM-DD')}
                      />
                      {incomingCVOAge !== null &&
                        (incomingCVOAge < 18 || incomingCVOAge > 100) && (
                          <p className="errorNote position-static fw-bold mb-5">
                            {incomingCVOAge < 18
                              ? '* Age should be more than 18 years'
                              : '* Age above 100 years is not valid'}
                          </p>
                        )}
                    </div>
                  </div>
                  {selectedYear !== '2022' && (
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                      <div className="ymca-selectWrap">
                        <div className="ymca-labelling ymca-dullText txt-mandatory">
                          CVO Organization
                        </div>
                        <input
                          type="text"
                          className="ymca-fieldSet"
                          placeholder=""
                          name="inComingCvoOrganization"
                          onChange={handleIncomingTitleName}
                          onBlur={formikIncomingCvo.handleBlur}
                          value={
                            formikIncomingCvo?.values?.inComingCvoOrganization
                          }
                          maxLength={255}
                        />
                        {formikIncomingCvo?.touched?.inComingCvoOrganization &&
                        formikIncomingCvo?.errors?.inComingCvoOrganization ? (
                          <div className="errorNote">
                            {formikIncomingCvo?.errors?.inComingCvoOrganization}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}
                </div>
                {selectedYear !== '2022' && (
                  <div className="row mb-4">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                      <div className="ymca-selectWrap">
                        <div className="ymca-labelling ymca-dullText txt-mandatory">
                          CVO Business Title
                        </div>
                        <input
                          type="text"
                          className="ymca-fieldSet"
                          placeholder=""
                          name="inComingCvoBusinessTitle"
                          onChange={handleIncomingTitleName}
                          onBlur={formikIncomingCvo.handleBlur}
                          value={
                            formikIncomingCvo?.values?.inComingCvoBusinessTitle
                          }
                          maxLength={255}
                        />
                        {formikIncomingCvo?.touched?.inComingCvoBusinessTitle &&
                        formikIncomingCvo?.errors?.inComingCvoBusinessTitle ? (
                          <div className="errorNote">
                            {
                              formikIncomingCvo?.errors
                                ?.inComingCvoBusinessTitle
                            }
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}

                <div className="row mb-4">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h4 className="ymca-programActivities">Address Details</h4>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="ymca-selectWrap">
                      <div className="ymca-labelling ymca-dullText txt-mandatory">
                        Address Line1
                      </div>
                      <input
                        type="text"
                        className={`ymca-fieldSet ${
                          formikIncomingCvo.touched.inComingCvoAddress1 &&
                          formikIncomingCvo.errors.inComingCvoAddress1
                            ? 'invalid'
                            : ''
                        }`}
                        placeholder=""
                        name="inComingCvoAddress1"
                        onChange={formikIncomingCvo.handleChange}
                        onBlur={formikIncomingCvo.handleBlur}
                        value={formikIncomingCvo?.values?.inComingCvoAddress1}
                        maxLength={500}
                      />
                      {formikIncomingCvo.touched.inComingCvoAddress1 &&
                      formikIncomingCvo.errors.inComingCvoAddress1 ? (
                        <div className="errorNote">
                          {formikIncomingCvo.errors.inComingCvoAddress1}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="ymca-selectWrap">
                      <div className="ymca-labelling ymca-dullText ">
                        Address Line2
                      </div>
                      <input
                        type="text"
                        className={`ymca-fieldSet ${
                          formikIncomingCvo.touched.inComingCvoAddress2 &&
                          formikIncomingCvo.errors.inComingCvoAddress2
                            ? 'invalid'
                            : ''
                        }`}
                        placeholder=""
                        name="inComingCvoAddress2"
                        onChange={formikIncomingCvo.handleChange}
                        onBlur={formikIncomingCvo.handleBlur}
                        value={formikIncomingCvo?.values?.inComingCvoAddress2}
                        maxLength={500}
                      />
                      {formikIncomingCvo.touched.inComingCvoAddress2 &&
                      formikIncomingCvo.errors.inComingCvoAddress2 ? (
                        <div className="errorNote">
                          {formikIncomingCvo.errors.inComingCvoAddress2}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="ymca-selectWrap">
                      <div className="ymca-labelling ymca-dullText txt-mandatory">
                        City
                      </div>
                      <input
                        type="text"
                        className={`ymca-fieldSet ${
                          formikIncomingCvo.touched.inComingCvoCity &&
                          formikIncomingCvo.errors.inComingCvoCity
                            ? 'invalid'
                            : ''
                        }`}
                        placeholder=""
                        name="inComingCvoCity"
                        onChange={formikIncomingCvo.handleChange}
                        onBlur={formikIncomingCvo.handleBlur}
                        value={formikIncomingCvo?.values?.inComingCvoCity}
                        maxLength={255}
                      />
                      {formikIncomingCvo.touched.inComingCvoCity &&
                      formikIncomingCvo.errors.inComingCvoCity ? (
                        <div className="errorNote">
                          {formikIncomingCvo.errors.inComingCvoCity}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <div className="ymca-selectWrap">
                      <div className="ymca-labelling ymca-dullText txt-mandatory">
                        Country
                      </div>
                      <select
                        name="inComingCvoCountry"
                        className="ymca-select ymca-customArrow notranslate"
                        onChange={incomingCountryChangeHandler}
                        onBlur={formikIncomingCvo.handleBlur}
                        value={formikIncomingCvo.values?.inComingCvoCountry}
                      >
                        <option value="">--Select--</option>
                        {countriesResponse &&
                          getCountries()?.map(data => (
                            <option key={data} value={data}>
                              {data}
                            </option>
                          ))}
                      </select>
                      {formikIncomingCvo.touched.inComingCvoCountry &&
                      formikIncomingCvo.errors.inComingCvoCountry ? (
                        <div className="errorNote">
                          {formikIncomingCvo.errors.inComingCvoCountry}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <div className="ymca-selectWrap">
                      <div className="ymca-labelling ymca-dullText txt-mandatory">
                        State
                      </div>
                      <select
                        name="inComingCvoState"
                        className="ymca-select ymca-customArrow notranslate"
                        onChange={formikIncomingCvo.handleChange}
                        onBlur={formikIncomingCvo.handleBlur}
                        value={formikIncomingCvo.values?.inComingCvoState}
                      >
                        <option value="">--Select--</option>
                        {countriesResponse &&
                          formikIncomingCvo?.values?.inComingCvoCountry &&
                          incomingGetStates('inComingCvoCountry')?.map(data => (
                            <option key={data} value={data}>
                              {data}
                            </option>
                          ))}
                      </select>
                      {formikIncomingCvo.touched.inComingCvoState &&
                      formikIncomingCvo.errors.inComingCvoState ? (
                        <div className="errorNote">
                          {formikIncomingCvo.errors.inComingCvoState}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <div className="ymca-selectWrap">
                      <div className="ymca-labelling ymca-dullText txt-mandatory">
                        Zipcode
                      </div>
                      <input
                        type="text"
                        className={`ymca-fieldSet ${
                          formikIncomingCvo.touched.inComingCvoZip &&
                          formikIncomingCvo.errors.inComingCvoZip
                            ? 'invalid'
                            : ''
                        }`}
                        placeholder=""
                        name="inComingCvoZip"
                        onChange={formikIncomingCvo.handleChange}
                        onBlur={formikIncomingCvo.handleBlur}
                        value={formikIncomingCvo.values?.inComingCvoZip}
                      />
                      {formikIncomingCvo.touched.inComingCvoZip &&
                      formikIncomingCvo.errors.inComingCvoZip ? (
                        <div className="errorNote">
                          {formikIncomingCvo.errors.inComingCvoZip}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h4 className="ymca-programActivities">Contact Details</h4>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="ymca-selectWrap">
                      <div className="ymca-labelling ymca-dullText txt-mandatory">
                        Email
                      </div>
                      <input
                        type="email"
                        className={`ymca-fieldSet ${
                          formikIncomingCvo.touched.inComingCvoEmail &&
                          formikIncomingCvo.errors.inComingCvoEmail
                            ? 'invalid'
                            : ''
                        }`}
                        placeholder=""
                        name="inComingCvoEmail"
                        onChange={formikIncomingCvo.handleChange}
                        onBlur={formikIncomingCvo.handleBlur}
                        value={formikIncomingCvo.values?.inComingCvoEmail}
                        maxLength={100}
                      />
                      {formikIncomingCvo.touched.inComingCvoEmail &&
                      formikIncomingCvo.errors.inComingCvoEmail ? (
                        <div className="errorNote">
                          {formikIncomingCvo.errors.inComingCvoEmail}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="ymca-selectWrap">
                      <div className="ymca-labelling ymca-dullText txt-mandatory">
                        Phone Number
                      </div>
                      <input
                        type="text"
                        placeholder=""
                        name="inComingCvoPhone"
                        onChange={formikIncomingCvo.handleChange}
                        onBlur={formikIncomingCvo.handleBlur}
                        value={formikIncomingCvo.values?.inComingCvoPhone}
                        className={`ymca-fieldSet ${
                          formikIncomingCvo.touched.inComingCvoPhone &&
                          formikIncomingCvo.errors.inComingCvoPhone
                            ? 'invalid'
                            : ''
                        }`}
                      />
                      {formikIncomingCvo.touched.inComingCvoPhone &&
                      formikIncomingCvo.errors.inComingCvoPhone ? (
                        <div className="errorNote">
                          {formikIncomingCvo.errors.inComingCvoPhone}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="ymca-selectWrap">
                      <div className="ymca-labelling ymca-dullText txt-mandatory">
                        Start Date
                      </div>
                      <input
                        type="date"
                        className="ymca-fieldSet"
                        placeholder=""
                        name="inComingCvoStartDate"
                        onChange={formikIncomingCvo.handleChange}
                        onBlur={formikIncomingCvo.handleBlur}
                        value={moment(
                          formikIncomingCvo.values?.inComingCvoStartDate
                        ).format('YYYY-MM-DD')}
                        min={moment(
                          new Date(formik?.values?.cvoEndDate).setDate(
                            new Date(formik?.values?.cvoEndDate).getDate() + 1
                          )
                        ).format('YYYY-MM-DD')}
                      />
                      {formikIncomingCvo.touched.inComingCvoStartDate &&
                      formikIncomingCvo.errors.inComingCvoStartDate ? (
                        <div className="errorNote">
                          {formikIncomingCvo.errors.inComingCvoStartDate}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="ymca-selectWrap">
                      <div
                        className={`ymca-labelling ymca-dullText ${
                          selectedYear >= '2023' ? 'txt-mandatory' : ''
                        }`}
                      >
                        End Date
                      </div>
                      <input
                        type="date"
                        className="ymca-fieldSet"
                        placeholder=""
                        onChange={formikIncomingCvo.handleChange}
                        onBlur={formikIncomingCvo.handleBlur}
                        value={moment(
                          formikIncomingCvo.values?.inComingCvoEndDate
                        ).format('YYYY-MM-DD')}
                        name="inComingCvoEndDate"
                        disabled={
                          !formikIncomingCvo?.values?.inComingCvoStartDate
                        }
                        min={
                          moment(
                            new Date(formik.values?.cvoStartDate).setDate(
                              new Date(formik.values?.cvoStartDate).getDate() +
                                1
                            )
                          ).format('YYYY-MM-DD') ||
                          moment(new Date()).format('YYYY-MM-DD')
                        }
                      />
                      {formikIncomingCvo.touched.inComingCvoEndDate &&
                      formikIncomingCvo.errors.inComingCvoEndDate ? (
                        <div className="errorNote">
                          {formikIncomingCvo.errors.inComingCvoEndDate}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            )}
        </div>
        {incomingEndDatePast && (
          <div className="errorNote position-static fw-bold ms-2">
            {
              'Your incoming CVO must not have a past end date and be expired. Please update your incoming CVO in MOYP with the correct information.'
            }
          </div>
        )}
        <div className="ymca-submitHighlighter">
          <LastUpdateName
            Username={formikIncomingCvo?.values?.updatedByName}
            Time={formikIncomingCvo?.values?.updatedDatetime}
          />
        </div>
        {(((role === APP_LABELS.CEO_DATA_STEWARD ||
          role === APP_LABELS.CVO_DATA_STEWARD) &&
          selectedYear === '2022') ||
          ((role === APP_LABELS.CEO_DATA_STEWARD ||
            role === APP_LABELS.CVO_DATA_STEWARD ||
            role === APP_LABELS.YUSA_DATA_STEWARD ||
            role === APP_LABELS.ASSOCIATION_DATA_STEWARD ||
            role === APP_LABELS.YESS_DATA_STEWARD) &&
            selectedYear >= '2023')) && (
          <button
            type="submit"
            className="btn ymca-savingBTN float-end"
            onClick={() => scrollToErrors(formik.errors)}
            disabled={
              status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED ||
              (incomingCVOAge !== null &&
                (incomingCVOAge < 18 || incomingCVOAge > 100))
            }
          >
            Save
          </button>
        )}
      </form>
      {successPopup && <SuccessToaster Status={resStatus} />}

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />
    </div>
  );
};

CvoContactDetails.propTypes = {
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  handlegetARContactDetailsApi: PropTypes.func.isRequired,
  handlePostARContactDetailsApi: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  handleToGetCountries: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  CvoConatactDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  handlegetIcomingCvoContactDetailsApi: PropTypes.func.isRequired,
  handlePostIncomingCvoContactDetailsApi: PropTypes.func.isRequired,
  IncomingCvoContactDataSelectorDetails: PropTypes.objectOf(PropTypes.any)
    .isRequired
};

const mapDispatchToProps = {
  handlegetARContactDetailsApi: getARContactDetailsApi,
  handlegetPledgeContactDetailsApi: getPledgeContactDetailsApi,
  handlePostARContactDetailsApi: PostARContactDetailsApi,
  handleToGetCountries: getCountriesList,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData,
  handlegetIcomingCvoContactDetailsApi: getARIncomingContactDetailsApi,
  handlePostIncomingCvoContactDetailsApi: postIncomingContactDetailsApi,
  handleGetPledgeIncomingCvoContactDetailsApi:
    getPledgeIncomingCvoContactDetailsApi
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  CvoConatactDetails: CvoContactDataSelector(state),
  IncomingCvoContactDataSelectorDetails: IncomingCvoContactDataSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(CvoContactDetails));
