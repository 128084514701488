import { METADATA_DASHBOARD_URLS } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getDomains = () => (dispatch, getState) =>
  asyncRequest({
    url: METADATA_DASHBOARD_URLS.DASHBOARD_GET_DOMAINS(),
    method: 'GET',
    successAction: data => ({
      type: types.GET_METADATA_DOMAIN_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_METADATA_DOMAIN_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getYears = () => (dispatch, getState) =>
  asyncRequest({
    url: METADATA_DASHBOARD_URLS.DASHBOARD_GET_YEARS(),
    method: 'GET',
    successAction: data => ({
      type: types.GET_METADATA_YEAR_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_METADATAYEAR_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getDashboardForms = (domainId, year) => (dispatch, getState) =>
  asyncRequest({
    url: METADATA_DASHBOARD_URLS.DASHBOARD_GET_FORMS(domainId, year),
    method: 'GET',
    successAction: data => ({
      type: types.GET_METADATA_FORMS_SUCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_METADATA_FORMS_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getDashboardCounts = (domain, year) => (dispatch, getState) =>
  asyncRequest({
    url: METADATA_DASHBOARD_URLS.DASHBOARD_GET_DASHBOARD_COUNTS(domain, year),
    method: 'GET',
    successAction: data => ({
      type: types.GET_METADATA_DASHBOARD_COUNT_SUCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_METADATA_DASHBOARD_COUNT_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getTotalDataCollection = (domain, year) => (dispatch, getState) =>
  asyncRequest({
    url: METADATA_DASHBOARD_URLS.DASHBOARD_GET_TOTAL_DATA_COLLECTION(
      domain,
      year
    ),
    method: 'GET',
    successAction: data => ({
      type: types.GET_TOTAL_DATA_COLLECTION_SUCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_TOTAL_DATA_COLLECTION_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getTotalUploads = (domain, year) => (dispatch, getState) =>
  asyncRequest({
    url: METADATA_DASHBOARD_URLS.DASHBOARD_GET_TOTAL_UPLOAD(domain, year),
    method: 'GET',
    successAction: data => ({
      type: types.GET_TOTAL_UPLOAD_SUCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_TOTAL_UPLOAD_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getTotalChanges =
  (domain, year, from = null, to = null) =>
  (dispatch, getState) =>
    asyncRequest({
      url: METADATA_DASHBOARD_URLS.DASHBOARD_GET_TOTAL_CHANGES(
        domain,
        year,
        from,
        to
      ),
      method: 'GET',
      successAction: data => ({
        type: types.GET_TOTAL_CHANGES_SUCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_TOTAL_CHANGES_FAILURE,
        payload: error
      }),
      dispatch
    });
