import { META_DATA_CATEGORY_URLS } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getCategoryMappings = (id) => (dispatch, getState) =>
  asyncRequest({
    url: META_DATA_CATEGORY_URLS.GET_CATEGORY_MAPPINGS(id),
    method: 'GET',
    successAction: data => ({
      type: types.GET_METADATA_CATEGORY_MAPPINGS_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_METADATA_CATEGORY_MAPPINGS_FAILURE,
      payload: error
    }),
    dispatch
  });

  export const getMappingsByDomineAndYear = (domine,year) => (dispatch, getState) =>
    asyncRequest({
      url: META_DATA_CATEGORY_URLS.GET_CATEGORY_MAPPINGS_BY_DOMINE_YEAR(domine,year),
      method: 'GET',
      successAction: data => ({
        type: types.GET_METADATA_CATEGORY_MAPPINGS_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_METADATA_CATEGORY_MAPPINGS_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getCategoryMappingBySubForms =
  (formId, subFormId) => (dispatch, getState) =>
    asyncRequest({
      url: META_DATA_CATEGORY_URLS.GET_CATEGORY_MAPPINGS_BY_FORM_SUBFORM(
        formId,
        subFormId
      ),
      method: 'GET',
      successAction: data => ({
        type: types.GET_METADATA_CATEGORY_MAPPINGS_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_METADATA_CATEGORY_MAPPINGS_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getCategoryItems = categoryItem => (dispatch, getState) =>
  asyncRequest({
    url: META_DATA_CATEGORY_URLS.GET_CATEGORY_ITEM(categoryItem),
    method: 'GET',
    successAction: data => ({
      type: types.GET_METADATA_CATEGORY_ITEM_FAILURE,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_METADATA_CATEGORY_ITEM_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getConfigureCategories = () => (dispatch, getState) =>
  asyncRequest({
    url: META_DATA_CATEGORY_URLS.GET_CONFIGURE(),
    method: 'GET',
    successAction: data => ({
      type: types.GET_CONFIGURE_CATEGORIES_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_METADATA_CATEGORIES_FAILURE,
      payload: error
    }),
    dispatch
  });

export const addCategoryMappings = (bodyObject, id) => (dispatch, getState) =>
  asyncRequest({
    url: META_DATA_CATEGORY_URLS.GET_CATEGORY_MAPPINGS(id),
    method: id ? 'PUT' : 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.GET_CONFIGURE_CATEGORIES_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_METADATA_CATEGORIES_FAILURE,
      payload: error
    }),
    dispatch
  });

export const deleteConfigureCategorieItem =
  (id, categoryItem) => (dispatch, getState) =>
    asyncRequest({
      url: META_DATA_CATEGORY_URLS.DELETE_CATEGORY_ITEM(id, categoryItem),
      method: 'DELETE',
      successAction: data => ({
        type: types.GET_CONFIGURE_CATEGORIES_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_METADATA_CATEGORIES_FAILURE,
        payload: error
      }),
      dispatch
    });

export const addConfigureCategorieItem = bodyObject => (dispatch, getState) =>
  asyncRequest({
    url: META_DATA_CATEGORY_URLS.ADD_CATEGORY_ITEM(),
    method: 'POST',
    data: bodyObject,
    successAction: data => ({
      type: types.GET_CONFIGURE_CATEGORIES_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_METADATA_CATEGORIES_FAILURE,
      payload: error
    }),
    dispatch
  });

export const updateConfigureCategorieItem =
  (id, bodyObject) => (dispatch, getState) =>
    asyncRequest({
      url: META_DATA_CATEGORY_URLS.UPDATE_CATEGORY_ITEM(id),
      method: 'PUT',
      data: bodyObject,
      successAction: data => ({
        type: types.GET_CONFIGURE_CATEGORIES_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.GET_METADATA_CATEGORIES_FAILURE,
        payload: error
      }),
      dispatch
    });

export const deleteCategoryMappings = categoryItem => (dispatch, getState) =>
  asyncRequest({
    url: META_DATA_CATEGORY_URLS.GET_CATEGORY_MAPPINGS(categoryItem),
    method: 'DELETE',
    successAction: data => ({
      type: types.GET_METADATA_CATEGORY_MAPPINGS_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_METADATA_CATEGORY_MAPPINGS_FAILURE,
      payload: error
    }),
    dispatch
  });
