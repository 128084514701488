/* eslint-disable no-unused-expressions */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { APP_LABELS, FIELD_LENGTH } from '@config/app-config';
import {
  getLeadershipStaffDevelopment,
  postLeadershipStaffDevelopment,
  resetReduxState
} from '@redux/diversity-inclusion-&-global-metrics/leadership-staff-development/actions';
import { getAnnualReportsDashboardOverviewData } from '@redux/annualReport-overview/actions';
import { GetLeadershipStaffDevelopmentSelector } from '@redux/diversity-inclusion-&-global-metrics/leadership-staff-development/selectors';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import SuccessToaster from '@modules/shared/components/reusable-components/success-toaster';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import Help from '@modules/shared/components/reusable-components/help';
import helpicon from '@static/images/caretdoubleright.svg';
import PartialSaveWarningPopup from '@modules/shared/components/reusable-components/partial-save-popup';
import LastUpdateName from '../last-update-name';
import FeedbackModal from '../../../../../utilities/feedback-modal';

const validationSchema = Yup.object({
  yusaStaff: Yup.string().nullable(),
  yusaVolunteers: Yup.string().nullable(),

  orientationProcessesStaff: Yup.string().nullable(),
  orientationProcessesVolunteers: Yup.string().nullable(),

  buildCulturalCompetencyStaff: Yup.string().nullable(),
  buildCulturalCompetencyVolunteers: Yup.string().nullable(),

  outsideTheYstaff: Yup.string().nullable(),
  outsideTheYvolunteers: Yup.string().nullable(),

  ymcaAffinityEmployeeStaff: Yup.string().nullable(),
  ymcaAffinityEmployeeVolunteers: Yup.string().nullable(),
  periodicMeetingsStaff: Yup.string().nullable(),
  periodicMeetingsVolunteers: Yup.string().nullable(),

  ongoingTrainingStaff: Yup.string().nullable(),
  ongoingTrainingVolunteers: Yup.string().nullable(),

  staffSatisactionWorkplace: Yup.string().nullable(),

  opportunitiesForStaffYnotListed: Yup.string().nullable(),
  opportunitiesForVolunteersYnotListed: Yup.string().nullable(),
  keyResourcesFromOutsideTheY: Yup.string().nullable(),
  keyGlobalExchange: Yup.string().nullable(),
  yresults: Yup.string().nullable()
});

const LeadershipStaffDevelopmentTab = props => {
  const {
    handleGetLeadershipStaffDevelopment,
    handlePostLeadershipStaffDevelopment,
    GetLeadershipStaffDevelopmentSelectorData,
    LoginUserData,
    setLoader,
    status,
    handleGetAnnualReportsDashboard,
    handleResetRedux
  } = props;

  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const sectionName = 'Diversity Inclusion & Global Metrics';
  const formikref = useRef();
  const [successPopup, setSuccessPopup] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [formId, setFormId] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [instructions, setInstructions] = useState('');
  const [help, setHelp] = useState(false);
  const [resStatus, setResStatus] = useState();

  const [
    opportunitiesForStaffYnotListedCount,
    setOpportunitiesForStaffYnotListedCount
  ] = useState(
    GetLeadershipStaffDevelopmentSelectorData?.data
      ?.opportunitiesForStaffYnotListed?.length || 0
  );
  const [
    opportunitiesForVolunteersYnotListedCount,
    setOpportunitiesForVolunteersYnotListedCount
  ] = useState(
    GetLeadershipStaffDevelopmentSelectorData?.data
      ?.opportunitiesForVolunteersYnotListed?.length || 0
  );
  const [
    keyResourcesFromOutsideTheYCount,
    setKeyResourcesFromOutsideTheYCount
  ] = useState(
    GetLeadershipStaffDevelopmentSelectorData?.data?.keyResourcesFromOutsideTheY
      ?.length || 0
  );
  const [keyGlobalExchangeCount, setKeyGlobalExchangeCount] = useState(
    GetLeadershipStaffDevelopmentSelectorData?.data?.keyGlobalExchange
      ?.length || 0
  );
  const [yresultsCount, setYresultsCount] = useState(
    GetLeadershipStaffDevelopmentSelectorData?.data?.yresults?.length || 0
  );

  const [opportunitiesForStaffYnotListed, setOpportunitiesForStaffYnotListed] =
    useState(
      GetLeadershipStaffDevelopmentSelectorData?.data
        ?.opportunitiesForStaffYnotListed
    );

  const [
    opportunitiesForVolunteersYnotListed,
    setOpportunitiesForVolunteersYnotListed
  ] = useState(
    GetLeadershipStaffDevelopmentSelectorData?.data
      ?.opportunitiesForVolunteersYnotListed
  );

  const [keyResourcesFromOutsideTheY, setKeyResourcesFromOutsideTheY] =
    useState(
      GetLeadershipStaffDevelopmentSelectorData?.data
        ?.keyResourcesFromOutsideTheY
    );

  const [keyGlobalExchange, setKeyGlobalExchange] = useState(
    GetLeadershipStaffDevelopmentSelectorData?.data?.keyGlobalExchange
  );

  const [yresults, setYresults] = useState(
    GetLeadershipStaffDevelopmentSelectorData?.data?.yresults
  );

  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const associationNumber =
    role === APP_LABELS.YUSA_DATA_STEWARD ||
    role === APP_LABELS.YUSA_DATA_VIEWER ||
    role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
    role === APP_LABELS.YESS_DATA_STEWARD
      ? get(associateData, 'associationNumber', '')
      : get(LoginUserData, 'user.associations[0]', 0);

  const handelGetCall = () => {
    setLoader(true);
    const associationsNum =
      role === APP_LABELS.YUSA_DATA_STEWARD ||
      role === APP_LABELS.YUSA_DATA_VIEWER ||
      role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
      role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0');
    handleGetLeadershipStaffDevelopment(associationsNum).then(res => {
      if (res.status === 200) {
        setOpportunitiesForStaffYnotListedCount(
          res?.data?.data?.opportunitiesForStaffYnotListed?.length || 0
        );
        setOpportunitiesForStaffYnotListed(
          res?.data?.data?.opportunitiesForStaffYnotListed
        );
        setOpportunitiesForVolunteersYnotListedCount(
          res?.data?.data?.opportunitiesForVolunteersYnotListed?.length || 0
        );
        setOpportunitiesForVolunteersYnotListed(
          res?.data?.data?.opportunitiesForVolunteersYnotListed
        );
        setKeyResourcesFromOutsideTheYCount(
          res?.data?.data?.keyResourcesFromOutsideTheY?.length || 0
        );
        setKeyResourcesFromOutsideTheY(
          res?.data?.data?.keyResourcesFromOutsideTheY
        );
        setKeyGlobalExchangeCount(
          res?.data?.data?.keyGlobalExchange?.length || 0
        );
        setKeyGlobalExchange(res?.data?.data?.keyGlobalExchange);
        setYresultsCount(res?.data?.data?.yresults?.length || 0);
        setYresults(res?.data?.data?.yresults);
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMessage(APP_LABELS.COMMON_ERROR_MESSAGE);
        setErrorPopup(true);
      }
    });
  };

  useEffect(() => {
    formikref?.current?.resetForm();
    handelGetCall();
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'leadership-staff-development'
      ]?.Instruction
    );
    return () => {
      handleResetRedux('GetLeadershipStaffDevelopment');
    };
  }, []);

  const handleShowHelp = () => {
    setHelp(true);
  };

  const hideShowHelp = () => {
    setHelp(false);
  };

  const initialValues = {
    yusaStaff: '',
    yusaVolunteers: '',

    orientationProcessesStaff: '',
    orientationProcessesVolunteers: '',

    buildCulturalCompetencyStaff: '',
    buildCulturalCompetencyVolunteers: '',

    outsideTheYstaff: '',
    outsideTheYvolunteers: '',

    ymcaAffinityEmployeeStaff: '',
    ymcaAffinityEmployeeVolunteers: '',

    periodicMeetingsStaff: '',
    periodicMeetingsVolunteers: '',

    ongoingTrainingStaff: '',
    ongoingTrainingVolunteers: '',

    staffSatisactionWorkplace: ''
  };

  const editValues = {
    yusaStaff: `${GetLeadershipStaffDevelopmentSelectorData?.data?.yusaStaff}`,
    yusaVolunteers: `${GetLeadershipStaffDevelopmentSelectorData?.data?.yusaVolunteers}`,

    orientationProcessesStaff: `${GetLeadershipStaffDevelopmentSelectorData?.data?.orientationProcessesStaff}`,
    orientationProcessesVolunteers: `${GetLeadershipStaffDevelopmentSelectorData?.data?.orientationProcessesVolunteers}`,

    buildCulturalCompetencyStaff: `${GetLeadershipStaffDevelopmentSelectorData?.data?.buildCulturalCompetencyStaff}`,
    buildCulturalCompetencyVolunteers: `${GetLeadershipStaffDevelopmentSelectorData?.data?.buildCulturalCompetencyVolunteers}`,

    outsideTheYstaff: `${GetLeadershipStaffDevelopmentSelectorData?.data?.outsideTheYstaff}`,
    outsideTheYvolunteers: `${GetLeadershipStaffDevelopmentSelectorData?.data?.outsideTheYvolunteers}`,

    ymcaAffinityEmployeeStaff: `${GetLeadershipStaffDevelopmentSelectorData?.data?.ymcaAffinityEmployeeStaff}`,
    ymcaAffinityEmployeeVolunteers: `${GetLeadershipStaffDevelopmentSelectorData?.data?.ymcaAffinityEmployeeVolunteers}`,

    periodicMeetingsStaff: `${GetLeadershipStaffDevelopmentSelectorData?.data?.periodicMeetingsStaff}`,
    periodicMeetingsVolunteers: `${GetLeadershipStaffDevelopmentSelectorData?.data?.periodicMeetingsVolunteers}`,

    ongoingTrainingStaff: `${GetLeadershipStaffDevelopmentSelectorData?.data?.ongoingTrainingStaff}`,
    ongoingTrainingVolunteers: `${GetLeadershipStaffDevelopmentSelectorData?.data?.ongoingTrainingVolunteers}`,

    staffSatisactionWorkplace:
      GetLeadershipStaffDevelopmentSelectorData?.data
        ?.staffSatisactionWorkplace[0]
  };

  const onSubmit = values => {
    setLoader(true);
    const payloadBody = {
      associationNumber: String(
        role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD
          ? get(associateData, 'associationNumber', '')
          : get(LoginUserData, 'user.associations[0]', '0')
      ),
      createdByName:
        GetLeadershipStaffDevelopmentSelectorData?.data?.createdByName,
      createdByPersonId:
        GetLeadershipStaffDevelopmentSelectorData?.data?.createdByPersonId,
      createdDatetime:
        GetLeadershipStaffDevelopmentSelectorData?.data?.createdDatetime,
      year: sessionStorage.getItem('selectedBatchYear'),
      staffDevelopmentId:
        GetLeadershipStaffDevelopmentSelectorData?.data?.staffDevelopmentId,
      reportBatchId:
        GetLeadershipStaffDevelopmentSelectorData?.data?.reportBatchId,
      recordStatus:
        GetLeadershipStaffDevelopmentSelectorData?.data?.recordStatus,
      yusaStaff: values.yusaStaff.toLowerCase() === 'true',
      yusaVolunteers: values.yusaVolunteers.toLowerCase() === 'true',

      orientationProcessesStaff:
        values.orientationProcessesStaff.toLowerCase() === 'true',
      orientationProcessesVolunteers:
        values.orientationProcessesVolunteers.toLowerCase() === 'true',

      buildCulturalCompetencyStaff:
        values.buildCulturalCompetencyStaff.toLowerCase() === 'true',
      buildCulturalCompetencyVolunteers:
        values.buildCulturalCompetencyVolunteers.toLowerCase() === 'true',

      outsideTheYstaff: values.outsideTheYstaff.toLowerCase() === 'true',
      outsideTheYvolunteers:
        values.outsideTheYvolunteers.toLowerCase() === 'true',

      ymcaAffinityEmployeeStaff:
        values.ymcaAffinityEmployeeStaff.toLowerCase() === 'true',
      ymcaAffinityEmployeeVolunteers:
        values.ymcaAffinityEmployeeVolunteers.toLowerCase() === 'true',

      periodicMeetingsStaff:
        values.periodicMeetingsStaff.toLowerCase() === 'true',
      periodicMeetingsVolunteers:
        values.periodicMeetingsVolunteers.toLowerCase() === 'true',

      ongoingTrainingStaff:
        values.ongoingTrainingStaff.toLowerCase() === 'true',
      ongoingTrainingVolunteers:
        values.ongoingTrainingVolunteers.toLowerCase() === 'true',

      opportunitiesForStaffYnotListed,
      opportunitiesForVolunteersYnotListed,
      keyResourcesFromOutsideTheY,
      keyGlobalExchange,

      staffSatisactionWorkplace: [values.staffSatisactionWorkplace],

      yresults
    };
    setSuccessPopup(false);
    handlePostLeadershipStaffDevelopment(payloadBody).then(res => {
      if (res.status === 200 && res?.data?.isSuccess) {
        setResStatus(res.data.data?.recordStatus);
        handleGetAnnualReportsDashboard(associationNumber, selectedYear).then(
          dashData => {
            dashData?.data?.data?.dashboardDTOs.forEach(record => {
              if (
                record.name === sectionName &&
                record.status === 'Completed' &&
                record.rating === null &&
                selectedYear >= '2023' &&
                role !== APP_LABELS.YUSA_DATA_STEWARD &&
                role !== APP_LABELS.YESS_DATA_STEWARD
              ) {
                setFeedbackPopup(true);
                setFormId(record?.formId);
              }
            });
          }
        );
        handelGetCall();
        setLoader(false);
        setSuccessPopup(true);
      } else {
        setLoader(false);
        setErrorMessage(res?.message);
        setErrorPopup(true);
      }
    });
  };

  const handelCount = (value, textareaName) => {
    if (textareaName === 'opportunitiesForStaffYnotListed') {
      setOpportunitiesForStaffYnotListedCount(value?.length || 0);
      setOpportunitiesForStaffYnotListed(value);
    } else if (textareaName === 'opportunitiesForVolunteersYnotListed') {
      setOpportunitiesForVolunteersYnotListedCount(value?.length || 0);
      setOpportunitiesForVolunteersYnotListed(value);
    } else if (textareaName === 'keyResourcesFromOutsideTheY') {
      setKeyResourcesFromOutsideTheYCount(value?.length || 0);
      setKeyResourcesFromOutsideTheY(value);
    } else if (textareaName === 'keyGlobalExchange') {
      setKeyGlobalExchangeCount(value?.length || 0);
      setKeyGlobalExchange(value);
    } else if (textareaName === 'yresults') {
      setYresultsCount(value?.length || 0);
      setYresults(value);
    }
  };

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  return (
    <div>
      <button
        type="button"
        className="ymca-help-link text-decoration-none annual-report-help"
        onClick={handleShowHelp}
      >
        HELP{/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>
      <Formik
        innerRef={formikref}
        initialValues={editValues || initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ dirty, errors }) => {
          sessionStorage.setItem('dirty', dirty);
          return (
            <Form autoComplete="off">
              <div className="ymca-covidRelif">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pb-3">
                  <div className="ymca-instructionBoxes">
                    <h4 className="ymca-instructHeadline">
                      {APP_LABELS.INSTRUCTIONS}
                    </h4>
                    <p className="ymca-instructTitle">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: instructions
                        }}
                      />
                    </p>
                  </div>
                </div>

                {GetLeadershipStaffDevelopmentSelectorData?.data?.recordStatus?.toUpperCase() ===
                  APP_LABELS.STATUS_PARTIAL && <PartialSaveWarningPopup />}

                <h4 className="ymca-headingTitle txt-mandatory">
                  {selectedYear >= '2024'
                    ? `Our Y invests in leadership- development opportunities designed to equip staff and volunteers with the necessary skills and competencies to be successful in a multicultural, multiracial, and inclusive environment, as evidenced by the following:`
                    : `Our Y invests in leadership development opportunities designed to enhance the global and cultural competency of staff and/or volunteers, as evidenced by the following:`}
                </h4>

                <div className="ymca-tableResponsive ymca-short-table-wrapper">
                  <table className="table ymca-table table-striped">
                    <thead>
                      <tr>
                        <th rowSpan={2} />
                        <th colSpan={2}>Staff</th>
                        <th colSpan={2}>Volunteers</th>
                      </tr>
                      <tr>
                        <th>Yes</th>
                        <th>No</th>
                        <th>Yes</th>
                        <th>No</th>
                      </tr>
                    </thead>
                    <tbody className="ymca-cuttingBody">
                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          Offering resources, programs, tools, etc. from the Y
                          and/or YMCA of the USA
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field type="radio" name="yusaStaff" value="true" />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="yusaStaff"
                              value="false"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="yusaVolunteers"
                              value="true"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="yusaVolunteers"
                              value="false"
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          Providing options like orientation processes for new
                          staff/ volunteers, mentoring relationships, and
                          developmental assignments
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="orientationProcessesStaff"
                              value="true"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="orientationProcessesStaff"
                              value="false"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="orientationProcessesVolunteers"
                              value="true"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="orientationProcessesVolunteers"
                              value="false"
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          {
                            'Promoting global exchange and learning opportunities to build cultural competency'
                          }
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="buildCulturalCompetencyStaff"
                              value="true"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="buildCulturalCompetencyStaff"
                              value="false"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="buildCulturalCompetencyVolunteers"
                              value="true"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="buildCulturalCompetencyVolunteers"
                              value="false"
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          {
                            'Offering resources, programs, tools, etc., from outside the Y'
                          }
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="outsideTheYstaff"
                              value="true"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="outsideTheYstaff"
                              value="false"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="outsideTheYvolunteers"
                              value="true"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="outsideTheYvolunteers"
                              value="false"
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          {
                            'Supporting participation in YMCA Affinity/Employee Resource Groups'
                          }
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="ymcaAffinityEmployeeStaff"
                              value="true"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="ymcaAffinityEmployeeStaff"
                              value="false"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="ymcaAffinityEmployeeVolunteers"
                              value="true"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="ymcaAffinityEmployeeVolunteers"
                              value="false"
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          Conducting periodic meetings at which staff and
                          volunteers (including youth) actively discuss issues
                          related to strategy, community, programs, funding,
                          etc.
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="periodicMeetingsStaff"
                              value="true"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="periodicMeetingsStaff"
                              value="false"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="periodicMeetingsVolunteers"
                              value="true"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="periodicMeetingsVolunteers"
                              value="false"
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className="ymca-normalWrap ymcaFiftyPercent txt-mandatory">
                          Providing ongoing training that develops leadership,
                          global, and cultural competency
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="ongoingTrainingStaff"
                              value="true"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="ongoingTrainingStaff"
                              value="false"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="ongoingTrainingVolunteers"
                              value="true"
                            />
                          </div>
                        </td>
                        <td className="ymca-radioBTN">
                          <div className="">
                            <Field
                              type="radio"
                              name="ongoingTrainingVolunteers"
                              value="false"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h4 className="ymca-headingTitle">
                Please describe any other key leadership opportunities for staff supported by your Y but not listed above.
                </h4>
                <textarea
                  name="opportunitiesForStaffYnotListed"
                  className="ymca-areaTextWidth mb-0"
                  maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                  value={opportunitiesForStaffYnotListed}
                  defaultValue={
                    GetLeadershipStaffDevelopmentSelectorData?.data
                      ?.opportunitiesForStaffYnotListed
                  }
                  onChange={e =>
                    handelCount(
                      e.target.value,
                      'opportunitiesForStaffYnotListed'
                    )
                  }
                />
                <div className="row">
                  <div className="col-10" />
                  <div className="col-2 text-end ymca-textarea-count">
                    <p>{`(${opportunitiesForStaffYnotListedCount}/8000)`}</p>
                  </div>
                </div>

                <h4 className="ymca-headingTitle">
                  Please describe any other key leadership opportunities for
                  volunteers supported by your Y but not listed above.
                </h4>
                <textarea
                  name="opportunitiesForVolunteersYnotListed"
                  className="ymca-areaTextWidth mb-0"
                  maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                  value={opportunitiesForVolunteersYnotListed}
                  defaultValue={
                    GetLeadershipStaffDevelopmentSelectorData?.data
                      ?.opportunitiesForVolunteersYnotListed
                  }
                  onChange={e =>
                    handelCount(
                      e.target.value,
                      'opportunitiesForVolunteersYnotListed'
                    )
                  }
                />
                <div className="row">
                  <div className="col-10" />
                  <div className="col-2 text-end ymca-textarea-count">
                    <p>
                      {`(${opportunitiesForVolunteersYnotListedCount}/8000)`}
                    </p>
                  </div>
                </div>

                <h4 className="ymca-headingTitle ">
                  Please describe the key resources, programs, tools and support
                  that are from outside the Y.
                </h4>
                <textarea
                  name="keyResourcesFromOutsideTheY"
                  className="ymca-areaTextWidth mb-0"
                  maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                  value={keyResourcesFromOutsideTheY}
                  defaultValue={
                    GetLeadershipStaffDevelopmentSelectorData?.data
                      ?.keyResourcesFromOutsideTheY
                  }
                  onChange={e =>
                    handelCount(e.target.value, 'keyResourcesFromOutsideTheY')
                  }
                />
                <div className="row">
                  <div className="col-10" />
                  <div className="col-2 text-end ymca-textarea-count">
                    <p>{`(${keyResourcesFromOutsideTheYCount}/8000)`}</p>
                  </div>
                </div>

                <h4 className="ymca-headingTitle">
                  Please describe key global and cross-cultural exchange and
                  learning opportunities for staff and volunteers.
                </h4>
                <textarea
                  name="keyGlobalExchange"
                  className="ymca-areaTextWidth mb-0"
                  maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                  value={keyGlobalExchange}
                  defaultValue={
                    GetLeadershipStaffDevelopmentSelectorData?.data
                      ?.keyGlobalExchange
                  }
                  onChange={e =>
                    handelCount(e.target.value, 'keyGlobalExchange')
                  }
                />
                <div className="row">
                  <div className="col-10" />
                  <div className="col-2 text-end ymca-textarea-count">
                    <p>{`(${keyGlobalExchangeCount}/8000)`}</p>
                  </div>
                </div>

                <h4 className="ymca-headingTitle txt-mandatory">
                  Our Y conducts staff satisfaction or workplace climate surveys
                  and takes action on areas that need improvement. Our Y has...
                </h4>
                <div className="ymca-tableResponsive">
                  <table className="table ymca-table ymca-lastTable">
                    <tbody className="ymca-cuttingBody ymca-nostripeBody">
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN ymca-checkboxers">
                          <div className="ps-0 d-flex ymca-listingERA">
                            <Field
                              type="radio"
                              name="staffSatisactionWorkplace"
                              value="yconductsStaffActedPast2Years"
                            />
                            <span className="ymca-texting mt-0">
                              {
                                'Conducted and acted on areas of improvement in the past two to five years'
                              }
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN ymca-checkboxers">
                          <div className="ps-0 d-flex ymca-listingERA">
                            <Field
                              type="radio"
                              name="staffSatisactionWorkplace"
                              value="yconductsStaffNoActionPast2Yrs"
                            />
                            <span className="ymca-texting mt-0">
                              {
                                'Conducted but not taken action on areas of improvement in the past two years'
                              }
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN ymca-checkboxers">
                          <div className="ps-0 d-flex ymca-listingERA">
                            <Field
                              type="radio"
                              name="staffSatisactionWorkplace"
                              value="yconductsStaffActedPast2To5Years"
                            />
                            <span className="ymca-texting mt-0">
                              {
                                'Conducted and acted upon areas of improvement in the past two to five years'
                              }
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN ymca-checkboxers">
                          <div className="ps-0 d-flex ymca-listingERA">
                            <Field
                              type="radio"
                              name="staffSatisactionWorkplace"
                              value="yconductsStaffNoActionPast2To5Yrs"
                            />
                            <span className="ymca-texting mt-0">
                              {
                                'Conducted but not taken action on areas of improvement in the past two to five years'
                              }
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN ymca-checkboxers">
                          <div className="ps-0 d-flex ymca-listingERA">
                            <Field
                              type="radio"
                              name="staffSatisactionWorkplace"
                              value="noSurveyConductedYstaffPast5Yrs"
                            />
                            <span className="ymca-texting mt-0">
                              {
                                'Have not conducted a staff satisfaction/climate survey within the past five years'
                              }
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="ymca-normalWrap ymca-radioBTN ymca-checkboxers">
                          <div className="d-flex ymca-listingERA ps-0">
                            <Field
                              type="radio"
                              name="staffSatisactionWorkplace"
                              value="nonOfTheAbove"
                            />
                            <span className="ymca-texting mt-0">
                              We are doing none of the above
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h4 className="ymca-headingTitle">
                  {selectedYear >= '2024'
                    ? `Please describe what actions your Y took as a result of your staff satisfaction or climate survey results. If possible, please include specific measurements or statistics that would demonstrate this.`
                    : `Please describe what actions your Y took as a result of your
                  results.`}
                </h4>
                <textarea
                  name="yresults"
                  className="ymca-areaTextWidth mb-0"
                  maxLength={FIELD_LENGTH.TEXTAREA_LENGTH}
                  value={yresults}
                  defaultValue={
                    GetLeadershipStaffDevelopmentSelectorData?.data?.yresults
                  }
                  onChange={e => handelCount(e.target.value, 'yresults')}
                />
                <div className="row">
                  <div className="col-10" />
                  <div className="col-2 text-end ymca-textarea-count">
                    <p>{`(${yresultsCount}/8000)`}</p>
                  </div>
                </div>
              </div>
              <div className="ymca-submitHighlighter">
                <LastUpdateName
                  Username={
                    GetLeadershipStaffDevelopmentSelectorData?.data
                      ?.updatedByName
                  }
                  Time={
                    GetLeadershipStaffDevelopmentSelectorData?.data
                      ?.updatedDatetime
                  }
                />
              </div>
              {role !== APP_LABELS.YUSA_DATA_VIEWER &&
                role !== APP_LABELS.ALLIANCE_DATA_STEWARD && (
                  <button
                    type="submit"
                    className="btn ymca-savingBTN ymca-btnRightTitlee float-end"
                    onClick={() => scrollToErrors(errors)}
                    disabled={
                      status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED
                    }
                  >
                    Save
                  </button>
                )}
            </Form>
          );
        }}
      </Formik>

      {successPopup && <SuccessToaster Status={resStatus} />}

      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />

      <FeedbackModal
        handleCloseFeedbackPopup={() => {
          setFeedbackPopup(false);
        }}
        showFeedbackPopup={feedbackPopup}
        sectionName={sectionName}
        selectedYear={selectedYear}
        associationNumber={associationNumber}
        formId={formId}
      />

      {help && (
        <Help
          hidePopup={hideShowHelp}
          keyValue="leadership-staff-development"
        />
      )}
    </div>
  );
};

LeadershipStaffDevelopmentTab.propTypes = {
  handleGetLeadershipStaffDevelopment: PropTypes.func.isRequired,
  handlePostLeadershipStaffDevelopment: PropTypes.func.isRequired,
  status: PropTypes.objectOf(PropTypes.any).isRequired,
  GetLeadershipStaffDevelopmentSelectorData: PropTypes.objectOf(PropTypes.any)
    .isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoader: PropTypes.func.isRequired,
  handleGetAnnualReportsDashboard: PropTypes.func.isRequired,
  handleResetRedux: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetLeadershipStaffDevelopment: getLeadershipStaffDevelopment,
  handlePostLeadershipStaffDevelopment: postLeadershipStaffDevelopment,
  handleGetAnnualReportsDashboard: getAnnualReportsDashboardOverviewData,
  handleResetRedux: resetReduxState
};

const mapStateToProps = state => ({
  GetLeadershipStaffDevelopmentSelectorData:
    GetLeadershipStaffDevelopmentSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(LeadershipStaffDevelopmentTab));
