import React from 'react';
import { Link } from 'react-router-dom';
const EVALUATION_GROUPS_COLUMN = () => [
  {
    Header: 'Association',
    accessor: 'Association',
    className: 'notranslate'
  },
  {
    Header: 'Evaluation Group ID',
    accessor: 'EvaluationGroupID',
    className: 'notranslate'
  },
  {
    Header: 'Evaluation Group Name',
    accessor: 'EvaluationGroupName',
    className: 'notranslate'
  },
  {
    Header: 'Points Possible',
    accessor: 'PointsPossible',
    className: 'notranslate'
  },
  {
    Header: 'Points Earned',
    accessor: 'PointsEarned',
    className: 'notranslate'
  },
  {
    Header: 'Min.Points Required',
    accessor: 'MinPointsRequired',
    className: 'notranslate'
  },
  {
    Header: 'Earned Score',
    accessor: 'EarnedScore',
    className: 'notranslate'
  },
  {
    Header: 'Actions',
    accessor: 'ShowDataPoints',
    Cell: v => (
      <>
        <div className="d-flex">
          <Link
            to={v.value}
            className="table-link"
            onClick={() =>
              sessionStorage.setItem('metaData_categoryItem', v.value)
            }
          >
            Show Data Points
          </Link>
        </div>
      </>
    )
  }
];

export default EVALUATION_GROUPS_COLUMN;
