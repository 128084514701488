import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { APP_LABELS } from '@config/app-config';
import moment from 'moment';
import { connect } from 'react-redux';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import crossiconblue from '@static/images/cross-icon-blue.svg';
import convertUtcToLocal from '@utilities/ConvertTimezone';

const ViewDetailsPopup = props => {
  const { popupTitle, popupData, LoginUserData } = props;
  const lastupdated = get(popupData, 'updatedDate', '')
    ? get(popupData, 'updatedDate', '')
    : '';
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog ymca-modal view-details-modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="ymca-black-sub-heading">{popupTitle}</div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <img
                src={crossiconblue}
                alt="cross-icon-blue"
                className="close-icon img-fluid"
              />
            </button>
          </div>
          <div className="modal-body px-0">
            {/* <hr className="mt-0" /> */}
            {}{' '}
            {get(popupData, 'sourceSystem') ? (
              <div className="ymca-modal-title">
                {APP_LABELS.DETAIL_ASSOCIATION_DETAIL}
                <span className="ml-590">Updated In</span>
              </div>
            ) : (
              ''
            )}
            <div className="row px-3">
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DASH_ASSOCIATE_NAME}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'associationName', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DETAIL_BRANCH_NAME}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'branchName', '')}
                </div>
              </div>
              {get(popupData, 'sourceSystem') ? (
                <div className="col-12 col-md-6 col-lg-4 p-0">
                  <div className="ymca-bold-heading">
                    {get(popupData, 'sourceSystem', '')}
                  </div>
                  <div className="ymca-modal-content notranslate">
                    On{' '}
                    {get(popupData, 'sourceSystem', '')?.toUpperCase() === 'HUB'
                      ? convertUtcToLocal(lastupdated)
                      : moment(lastupdated).format('DD MMM YYYY hh:mm a')}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            <hr />
            <div className="ymca-modal-title">
              {APP_LABELS.DETAIL_PERSONAL_DETAILS}
            </div>
            <div className="row px-3">
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DETAIL_FIRST_NAME}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'firstName', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DETAIL_MIDDLE_NAME}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'middleName', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DETAIL_LAST_NAME}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'lastName', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DETAIL_BIRTH_DATE}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'birthDate')
                    ? moment(get(popupData, 'birthDate')).format('MM/DD/yyyy')
                    : ''}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.EMAIL_ADDRESS}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'emailAddress', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DETAIL_RACE}
                </div>
                {get(popupData, 'localYDisclosureConsent', false) && (
                  <div className="ymca-modal-content notranslate">
                    {get(LoginUserData, 'user.role[0]') ===
                    APP_LABELS.ALLIANCE_DATA_STEWARD
                      ? '****'
                      : get(popupData, 'raceEthnicity', '')}
                  </div>
                )}
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DETAIL_GENDER}
                </div>
                {get(popupData, 'localYDisclosureConsent', false) && (
                  <div className="ymca-modal-content notranslate">
                    {get(LoginUserData, 'user.role[0]') ===
                    APP_LABELS.ALLIANCE_DATA_STEWARD
                      ? '****'
                      : get(popupData, 'gender', '')}
                  </div>
                )}
              </div>
              {/* <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DETAIL_ADDRESS}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'address', '')}
                </div>
              </div> */}
              {/* <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DETAIL_ADDRESS2}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'address2', '')}
                </div>
              </div> */}
              {/* <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DETAIL_CITY}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'city', '')}
                </div>
              </div> */}
              {/* <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DETAIL_STATE}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'state', '')}
                </div>
              </div> */}
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DETAIL_RECORD_TYPE}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'displayStaffRecordType', '')}
                </div>
              </div>
            </div>
            <hr />
            <div className="ymca-modal-title">
              {APP_LABELS.DETAIL_STAFF_DETAILS}
            </div>
            <div className="row px-3">
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DETAIL_BRAND_ADVOCATE}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'brandAdvocate', '')}
                </div>
              </div>
              {/* <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {'Start date with the association'}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'startDateWithAssociation')
                    ? moment(get(popupData, 'startDateWithAssociation')).format(
                        'MM/DD/yyyy'
                      )
                    : ''}
                </div>
              </div> */}
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">Staff status</div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'staffStatus')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {'Current Position Start Date'}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'startDateCurrentRole')
                    ? moment(get(popupData, 'startDateCurrentRole')).format(
                        'MM/DD/yyyy'
                      )
                    : ''}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {'End date for current role'}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'endDateCurrentRole')
                    ? moment(get(popupData, 'endDateCurrentRole')).format(
                        'MM/DD/yyyy'
                      )
                    : ''}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DETAIL_STAFF_TYPE}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'staffType', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {'Current Unique Job Title'}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'currentJobTitle', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DETAIL_CURRENT_SALARY}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(LoginUserData, 'user.role[0]') ===
                  APP_LABELS.ALLIANCE_DATA_STEWARD
                    ? '****'
                    : get(popupData, 'currentSalary', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DETAIL_CURRENT_SALARY_START_DATE}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'currentPayStartDate')
                    ? moment(get(popupData, 'currentPayStartDate')).format(
                        'MM/DD/yyyy'
                      )
                    : ''}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.UPLOAD_DATA_CURRENT_POSITION}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'currentPositionLeadLevel', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.DETAIL_CURRENT_POSITION}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'currentPositionRole', '')}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 p-0">
                <div className="ymca-bold-heading">
                  {APP_LABELS.UPLOAD_DATA_CURRENT_POSITION_PRIMARY}
                </div>
                <div className="ymca-modal-content notranslate">
                  {get(popupData, 'currentPositionPrimaryFunction', '')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewDetailsPopup.propTypes = {
  popupTitle: PropTypes.string,
  popupData: PropTypes.arrayOf(PropTypes.any),
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};

ViewDetailsPopup.defaultProps = {
  popupTitle: '',
  popupData: []
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});
export default connect(mapStateToProps, null)(ViewDetailsPopup);
