import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { APP_LABELS } from '@config/app-config';
import {
  getAnnualReportOverviewData,
  getYmcaBranchesData,
  getCollabNonProfitOrgApi,
  getCollabEducationOrgApi,
  getCollabGovtOrgAndAffiliatesApi,
  getCollabOtherCommunityOrgApi,
  getCollabPrivateOrgAndBusinessesApi,
  getCollaborationsAmongYsApi,
  getProgramActivitiesApi,
  getProgramActivityHousingApi,
  getProgramActivityFoodProgramApi,
  getProgramActivityArmedServiceApi,
  getProgramActivityOlderAdultApi
} from '@redux/annualReport-overview/actions';
import Help from '@modules/shared/components/reusable-components/help';
import {
  FetchOverViewDataSelector,
  YmcaBrachDataSelector,
  CollabNonProfitOrgSelector,
  CollabEducationOrgSelector,
  CollabGovtOrgAndAffiliatesSelector,
  CollabOtherCommunityOrgSelector,
  CollabPrivateOrgAndBusinessesSelector,
  CollabAmongYsSelector
} from '@redux/annualReport-overview/selectors';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import helpicon from '@static/images/caretdoubleright.svg';
import tabComponent from '@static/images/annualReports/tabComponent.svg';
import tabDesign from '@static/images/annualReports/tabDesign.svg';
import convertUtcToLocal from '@utilities/ConvertTimezone';

const OverviewTab = props => {
  const [help, setHelp] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const selectedYear = sessionStorage.getItem('selectedBatchYear');
  const handleShowHelp = () => {
    setHelp(true);
  };
  const hideShowHelp = () => {
    setHelp(false);
  };
  const {
    handleGetOverviewData,
    LoginUserData,
    handleGetYmcaBranchData,
    handlegetCollabNonProfitOrgApi,
    handlegetCollabEducationOrgApi,
    handlegetCollabGovtOrgAndAffiliatesApi,
    handlegetCollabOtherCommunityOrgApi,
    handlegetCollabPrivateOrgAndBusinessesApi,
    handlegetCollaborationsAmongYsApi,
    handlegetProgramActivitiesApi,
    handlegetProgramActivityHousingApi,
    handlegetProgramActivityFoodProgramApi,
    handlegetProgramActivityArmedServiceApi,
    handlegetProgramActivityOlderAdultApi,
    setLoader
  } = props;
  const role = get(LoginUserData, 'user.role[0]');
  const [ymcsListEnabled, setYmcsListEnabled] = useState(false);
  const [overviewData, setOverviewData] = useState([]);
  const [collabTabsView, setCollabTabsView] = useState([
    false,
    false,
    false,
    false,
    false,
    false
  ]);
  const [programActivityView, setProgramActivityView] = useState([
    false,
    false,
    false,
    false,
    false,
    false
  ]);

  const constituencySubIds = [
    'memberships-tab',
    'Ethnicity-tab',
    'proggramMembers-tab',
    'programEnrollments-tab',
    'volunteers-tab',
    'donors-tab'
  ];
  const diversitySubTds = [
    'knowYourCommunity-tab',
    'leadership-tab',
    'programInnovation-tab',
    'strategicRelationShips-tab',
    'resourceMobilization-tab',
    'brandVisiblity-tab',
    'orgCommitement-tab'
  ];

  const collaborationSubTds = [
    'govtOrg-tab',
    'nonProfitOrg-tab',
    'educationalOrg-tab',
    'otherOrg-tab',
    'pvtOrg-tab',
    'collabration-tab'
  ];
  const programActivitiesSubTds = [
    'activityProgram-tab',
    'housing-tab',
    'foodProgram-tab',
    'armedServices-tab',
    'olderAdults-tab'
  ];
  const pledgeSubIds = [
    'pledge-tab',
    'cvocontactdetails-tab',
    'cvocontactdetails-tab'
  ];

  const programActivitiesTabFuncs = [
    handlegetProgramActivitiesApi,
    handlegetProgramActivityHousingApi,
    handlegetProgramActivityFoodProgramApi,
    handlegetProgramActivityArmedServiceApi,
    handlegetProgramActivityOlderAdultApi
  ];

  const [collabrationTabDatas, setCollabrationTabDatas] = useState([
    [],
    [],
    [],
    [],
    [],
    []
  ]);
  const [programActivityDatas, setProgramActivityDatas] = useState([
    [],
    [],
    [],
    [],
    [],
    []
  ]);

  const [ymcaBranchData, setYmcaBranchData] = useState([]);

  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  useEffect(() => {
    setLoader(true);
    handleGetOverviewData(
      role === APP_LABELS.YUSA_DATA_STEWARD ||
        role === APP_LABELS.YUSA_DATA_VIEWER ||
        role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
        role === APP_LABELS.YESS_DATA_STEWARD
        ? get(associateData, 'associationNumber', '')
        : get(LoginUserData, 'user.associations[0]', '0')
    ).then(res => {
      if (res?.data?.isSuccess) {
        setOverviewData(res?.data?.data);
        setLoader(false);
      } else {
        setLoader(false);
        setErrorPopup(true);
      }
    });
  }, []);

  const redirect = (id, subId, branchId) => {
    document.getElementById(id).click();
    if (branchId) {
      sessionStorage.setItem('branchSelected', branchId);
    }
    setTimeout(() => {
      if (subId) document.getElementById(subId).click();
    }, 500);
  };

  const getStatus = status => {
    if (status?.toUpperCase() === APP_LABELS.STATUS_YETTOBEGIN) {
      return (
        <td className="ymca-redLine ">{APP_LABELS.STATUS_YET_TO_BEGIN}</td>
      );
    }
    if (status?.toUpperCase() === APP_LABELS.STATUS_INPROGRESS) {
      return (
        <td className="ymca-yellowLine">
          <img src={tabDesign} alt="" className="img-fluid ymca-pattren" />
          {APP_LABELS.STATUS_IN_PROGRESS}
        </td>
      );
    }
    if (status?.toUpperCase() === APP_LABELS.STATUS_COMPLETED) {
      return (
        <td className="ymca-greenLine">
          <img src={tabComponent} alt="" className="img-fluid ymca-pattren" />
          {'Completed'}
        </td>
      );
    }
    if (status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED) {
      return (
        <td className="ymca-greenLine">
          <img src={tabComponent} alt="" className="img-fluid ymca-pattren" />
          {'Submitted'}
        </td>
      );
    }
    return <td className="ymca-redLine">{APP_LABELS.STATUS_YET_TO_BEGIN}</td>;
  };

  const actionElement = (element, id, sectionName) => (
    <button
      type="button"
      className="btn btn-transparent ymca-cyanLine custom-align"
      onClick={() =>
        redirect(
          id,
          sectionName,
          // subTab,
          element?.type?.toUpperCase() === 'BRANCH'
            ? element?.branchNumber
            : null
        )
      }
    >
      {((role === APP_LABELS.YUSA_DATA_VIEWER ||
        role === APP_LABELS.ALLIANCE_DATA_STEWARD) &&
        'View') ||
        (element?.status?.toUpperCase() === APP_LABELS.STATUS_YETTOBEGIN &&
          'Start') ||
        (element?.status?.toUpperCase() === APP_LABELS.STATUS_COMPLETED &&
          'Edit') ||
        (element?.status?.toUpperCase() === APP_LABELS.STATUS_INPROGRESS &&
          'Edit') ||
        (element?.status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED &&
          'View') ||
        'Start'}
    </button>
  );

  const showlist = (data, id, subTab, sectionName) =>
    data && data?.length > 0 ? (
      <tr>
        <td colSpan="5" className="ymca-innerTable">
          <table className="table ymca-table table-striped">
            <thead>
              <tr>
                <th className="YMCA-headerLeft">Branch Name</th>
                <th>Type</th>
                <th>Status</th>
                <th>Updated By</th>
                <th>Updated On</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {data.map((element, index) => (
                <tr key={index}>
                  <td className="ymca-leftSpacer notranslate">
                    {element.branchName}
                  </td>
                  <td>{element.type}</td>
                  {getStatus(element.status)}
                  <td
                    className={`notranslate ${
                      !element?.updatedByName && 'text-center'
                    }`}
                  >
                    {element.updatedByName || '----'}
                  </td>
                  <td
                    className={`notranslate ${
                      !element?.updatedDatetime && 'text-center'
                    }`}
                  >
                    {convertUtcToLocal(element.updatedDatetime) || '----'}
                  </td>
                  <td className="ymca-cyanLine">
                    {actionElement(element, id, sectionName)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </td>
      </tr>
    ) : (
      <tr>
        <td colSpan="5" className="text-center">
          Data Not Found
        </td>
      </tr>
    );

  const getYmcaBranchesList = () => {
    if (!ymcsListEnabled) {
      setLoader(true);
      handleGetYmcaBranchData(
        role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD
          ? get(associateData, 'associationNumber', '')
          : get(LoginUserData, 'user.associations[0]', '0')
      ).then(res => {
        if (res?.data?.isSuccess) {
          setYmcaBranchData(res?.data?.data);
          setLoader(false);
        } else {
          setLoader(false);
          setErrorPopup(true);
        }
      });
    }
    setYmcsListEnabled(!ymcsListEnabled);
    const { innerText } = document.getElementById(`ymcaDirectInfo`);

    if (innerText === 'View Branch Level Progress') {
      document.getElementById(`ymcaDirectInfo`).innerText =
        'Hide Branch Level Progress';
    } else {
      document.getElementById(`ymcaDirectInfo`).innerText =
        'View Branch Level Progress';
    }
  };

  const getCollabApis = (api, index) => {
    const newcollabTabsView = [...collabTabsView];
    newcollabTabsView[index] = !collabTabsView[index];
    setCollabTabsView([...newcollabTabsView]);
    const { innerText } = document.getElementById(`collaboration-tab-${index}`);

    if (innerText === 'View Branch Level Progress') {
      document.getElementById(`collaboration-tab-${index}`).innerText =
        'Hide Branch Level Progress';
      setLoader(true);
      api(
        role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD
          ? get(associateData, 'associationNumber', '')
          : get(LoginUserData, 'user.associations[0]', '0')
      ).then(res => {
        if (res?.data?.isSuccess) {
          const newcollabTabsData = [...collabrationTabDatas];
          newcollabTabsData[index] = res?.data?.data || [];
          setCollabrationTabDatas([...newcollabTabsData]);
          setLoader(false);
        } else {
          setLoader(false);
          setErrorPopup(true);
        }
      });
    } else {
      document.getElementById(`collaboration-tab-${index}`).innerText =
        'View Branch Level Progress';
    }
  };

  const getProgramActivitiesApis = (api, index) => {
    const newProgramActivitieView = [...programActivityView];
    newProgramActivitieView[index] = !programActivityView[index];
    setProgramActivityView([...newProgramActivitieView]);
    const { innerText } = document.getElementById(
      `programActivities-tab-${index}`
    );

    if (innerText === 'View Branch Level Progress') {
      document.getElementById(`programActivities-tab-${index}`).innerText =
        'Hide Branch Level Progress';
      setLoader(true);
      api(
        role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD
          ? get(associateData, 'associationNumber', '')
          : get(LoginUserData, 'user.associations[0]', '0')
      ).then(res => {
        if (res?.data?.isSuccess) {
          const newProgramActivitiesData = [...programActivityDatas];
          newProgramActivitiesData[index] = res?.data?.data || [];
          setProgramActivityDatas([...newProgramActivitiesData]);
          setLoader(false);
        } else {
          setLoader(false);
          setErrorPopup(true);
        }
      });
    } else {
      document.getElementById(`programActivities-tab-${index}`).innerText =
        'View Branch Level Progress';
    }
  };

  const getCursor = name => {
    if (
      name === APP_LABELS.PLEDGE &&
      role !== APP_LABELS.YUSA_DATA_VIEWER &&
      role !== APP_LABELS.ALLIANCE_DATA_STEWARD &&
      role !== APP_LABELS.YUSA_DATA_STEWARD &&
      role !== APP_LABELS.CVO_DATA_STEWARD &&
      role !== APP_LABELS.CEO_DATA_STEWARD &&
      role !== APP_LABELS.YESS_DATA_STEWARD &&
      selectedYear === '2022'
    ) {
      return 'cursor-not-allowed';
    }
    if (
      (name === APP_LABELS.SITE_INFORMATION ||
        name === APP_LABELS.EMPLOYEE ||
        name === APP_LABELS.CONSTITUENCY_REPORT ||
        name === APP_LABELS.DIVERSITY_METRICS ||
        name === APP_LABELS.PLEDGE ||
        name === APP_LABELS.REVIEW_SUBMIT) &&
      role === APP_LABELS.BRANCH_DATA_STEWARD
    ) {
      return 'cursor-not-allowed';
    }
    return '';
  };
  const isAccess = name => {
    if (
      name === APP_LABELS.PLEDGE &&
      role !== APP_LABELS.YUSA_DATA_VIEWER &&
      role !== APP_LABELS.ALLIANCE_DATA_STEWARD &&
      role !== APP_LABELS.YUSA_DATA_STEWARD &&
      role !== APP_LABELS.CVO_DATA_STEWARD &&
      role !== APP_LABELS.CEO_DATA_STEWARD &&
      role !== APP_LABELS.YESS_DATA_STEWARD &&
      selectedYear === '2022'
    ) {
      return true;
    }
    return !!(
      [
        APP_LABELS.SITE_INFORMATION,
        APP_LABELS.EMPLOYEE,
        APP_LABELS.CONSTITUENCY_REPORT,
        APP_LABELS.DIVERSITY,
        APP_LABELS.PLEDGE,
        APP_LABELS.REVIEW_SUBMIT
      ].includes(name) && role === APP_LABELS.BRANCH_DATA_STEWARD
    );
  };
  const getCollaborationsApiName = sectionName => {
    if (sectionName === 'Other Not-for-Profit Organizations') {
      return handlegetCollabNonProfitOrgApi;
    }
    if (sectionName === 'Educational Organizations') {
      return handlegetCollabEducationOrgApi;
    }
    if (sectionName === 'Other Community Organizations') {
      return handlegetCollabOtherCommunityOrgApi;
    }
    if (sectionName === 'Private Organizations and Businesses') {
      return handlegetCollabPrivateOrgAndBusinessesApi;
    }
    if (sectionName === 'Collabrations Among YS') {
      return handlegetCollaborationsAmongYsApi;
    }
    if (sectionName === 'Government Organizations & Their Affiliates') {
      return handlegetCollabGovtOrgAndAffiliatesApi;
    }
    return null;
  };

  const setAction = (name, status) =>
    ((role === APP_LABELS.YUSA_DATA_VIEWER ||
      role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
      (name === APP_LABELS.PLEDGE &&
        (role === APP_LABELS.YUSA_DATA_STEWARD ||
          role === APP_LABELS.YESS_DATA_STEWARD))) &&
      'View') ||
    (status?.toUpperCase() === APP_LABELS.STATUS_YETTOBEGIN && 'Start') ||
    (status?.toUpperCase() === APP_LABELS.STATUS_COMPLETED && 'Edit') ||
    (status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED && 'View') ||
    (status?.toUpperCase() === APP_LABELS.STATUS_INPROGRESS && 'Edit');

  const getActions = (
    name,
    status,
    tabIdORbranchfunc,
    subTabsListORview,
    index,
    sectionName
  ) => {
    if (
      name !== APP_LABELS.COLLABORATIONS &&
      name !== APP_LABELS.YMCA_DIRECTORY_INFO &&
      name !== APP_LABELS.PROGRAM_ACTIVITIES
    ) {
      return (
        <button
          type="button"
          className={`btn btn-transparent ymca-cyanLine custom-align ymca-word-break ${getCursor(
            name
          )}`}
          onClick={() => redirect(tabIdORbranchfunc, sectionName)}
        >
          <span className="overview-status">{setAction(name, status)}</span>
          {isAccess(name) && (
            <span className="ymca-option-tooltip ymcs-newToolTip">
              You are not authorized to access this section
            </span>
          )}
        </button>
      );
    }

    if (name === APP_LABELS.COLLABORATIONS) {
      return (
        <button
          type="button"
          className="btn btn-transparent ymca-cyanLine custom-align ymca-word-break"
          onClick={() =>
            getCollabApis(getCollaborationsApiName(sectionName), index)
          }
          id={`collaboration-tab-${index}`}
        >
          View Branch Level Progress
        </button>
      );
    }
    if (name === APP_LABELS.PROGRAM_ACTIVITIES) {
      return (
        <button
          type="button"
          className="btn btn-transparent ymca-cyanLine custom-align ymca-word-break"
          onClick={() =>
            getProgramActivitiesApis(programActivitiesTabFuncs[index], index)
          }
          id={`programActivities-tab-${index}`}
        >
          View Branch Level Progress
        </button>
      );
    }
    return (
      <button
        type="button"
        className="btn btn-transparent ymca-cyanLine custom-align ymca-word-break"
        onClick={tabIdORbranchfunc}
        id="ymcaDirectInfo"
      >
        View Branch Level Progress
      </button>
    );
  };

  const renderOption = (name, status, tabIdORbranchfunc, sectionName) => (
    <button
      type="button"
      className={`btn btn-transparent ymca-cyanLine custom-align ymca-word-break ${getCursor(
        name
      )}`}
      onClick={() => redirect(tabIdORbranchfunc, sectionName)}
    >
      <span className="overview-status">
        {((role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          (name === APP_LABELS.PLEDGE &&
            (role === APP_LABELS.YUSA_DATA_STEWARD ||
              role === APP_LABELS.YESS_DATA_STEWARD))) &&
          'View') ||
          (status?.toUpperCase() === APP_LABELS.STATUS_YETTOBEGIN && 'Start') ||
          (status?.toUpperCase() === APP_LABELS.STATUS_COMPLETED && 'Edit') ||
          (status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED && 'View') ||
          (status?.toUpperCase() === APP_LABELS.STATUS_INPROGRESS && 'Edit')}
      </span>
      {isAccess(name) && (
        <span className="ymca-option-tooltip ymcs-newToolTip">
          You are not authorized to access this section
        </span>
      )}
    </button>
  );

  const renderButton = (
    name,
    status,
    tabIdORbranchfunc,
    subTabsListORview,
    index
  ) => (
    <button
      type="button"
      className={`btn btn-transparent ymca-cyanLine custom-align ymca-word-break ${getCursor(
        name
      )}`}
      onClick={() =>
        redirect(
          tabIdORbranchfunc,
          subTabsListORview ? subTabsListORview[index] : 0
        )
      }
    >
      <span className="overview-status">
        {((role === APP_LABELS.YUSA_DATA_VIEWER ||
          role === APP_LABELS.ALLIANCE_DATA_STEWARD ||
          (name === APP_LABELS.PLEDGE &&
            (role === APP_LABELS.YUSA_DATA_STEWARD ||
              role === APP_LABELS.YESS_DATA_STEWARD))) &&
          'View') ||
          (status?.toUpperCase() === APP_LABELS.STATUS_YETTOBEGIN && 'Start') ||
          (status?.toUpperCase() === APP_LABELS.STATUS_COMPLETED && 'Edit') ||
          (status?.toUpperCase() === APP_LABELS.STATUS_SUBMITTED && 'View') ||
          (status?.toUpperCase() === APP_LABELS.STATUS_INPROGRESS && 'Edit')}
      </span>
      {isAccess(name) && (
        <span className="ymca-option-tooltip ymcs-newToolTip">
          You are not authorized to access this section
        </span>
      )}
    </button>
  );

  const get2023Actions = (
    name,
    status,
    tabIdORbranchfunc,
    subTabsListORview,
    index,
    sectionName
  ) => {
    if (
      name !== APP_LABELS.COLLABORATIONS &&
      name !== APP_LABELS.YMCA_DIRECTORY_INFO &&
      name !== APP_LABELS.PROGRAM_ACTIVITIES
    ) {
      return renderOption(name, status, tabIdORbranchfunc, sectionName);
    }
    if (
      name === APP_LABELS.YMCA_DIRECTORY_INFO &&
      (sectionName === APP_LABELS.CVO_CONTACT_DETAILS ||
        sectionName === APP_LABELS.INCOMING_CVO_CONTACT_DETAILS)
    ) {
      return renderButton(
        name,
        status,
        tabIdORbranchfunc,
        subTabsListORview,
        index
      );
    }
    if (name === APP_LABELS.COLLABORATIONS) {
      return (
        <button
          type="button"
          className="btn btn-transparent ymca-cyanLine custom-align ymca-word-break"
          onClick={() =>
            getCollabApis(getCollaborationsApiName(sectionName), index)
          }
          id={`collaboration-tab-${index}`}
        >
          View Branch Level Progress
        </button>
      );
    }
    if (name === APP_LABELS.PROGRAM_ACTIVITIES) {
      return (
        <button
          type="button"
          className="btn btn-transparent ymca-cyanLine custom-align ymca-word-break"
          onClick={() =>
            getProgramActivitiesApis(programActivitiesTabFuncs[index], index)
          }
          id={`programActivities-tab-${index}`}
        >
          View Branch Level Progress
        </button>
      );
    }
    return (
      <button
        type="button"
        className="btn btn-transparent ymca-cyanLine custom-align ymca-word-break"
        onClick={tabIdORbranchfunc}
        id="ymcaDirectInfo"
      >
        View Branch Level Progress
      </button>
    );
  };
  const ymcaSiteInfoList = (name, branchFunc, View) => {
    if (overviewData && overviewData.length > 1) {
      const list = overviewData.filter(res => res.formName === name);
      const sectionData = list ? list[0]?.sections.slice(0, 1) : [];

      return sectionData?.map(element => (
        <>
          {getStatus(element?.sectionStatus)}
          <td
            className={`notranslate ${
              !element?.lastUpdatedBy && 'text-center'
            }`}
          >
            {element?.lastUpdatedBy || '----'}
          </td>
          <td
            className={`notranslate ${
              !element?.lastUpdatedDate && 'text-center'
            }`}
          >
            {element?.lastUpdatedDate
              ? convertUtcToLocal(element.lastUpdatedDate)
              : '----'}
          </td>
          <td>{getActions(name, element?.sectionStatus, branchFunc, View)}</td>
        </>
      ));
    }
    return (
      <>
        <td className="text-center">----</td>
        <td className="text-center">----</td>
        <td className="text-center">----</td>
        <td className="text-center">----</td>
      </>
    );
  };
  const renderPledgeSectionsYmcaList = (name, tabId, subTabsList) => {
    if (overviewData && overviewData.length > 1) {
      const list = overviewData?.filter(res => res.formName === name);
      const sectionData = list ? list[0].sections : [];
      return sectionData.map((element, index) => {
        if (
          (element?.sectionName === APP_LABELS.CVO_CONTACT_DETAILS ||
            element?.sectionName === APP_LABELS.INCOMING_CVO_CONTACT_DETAILS) &&
          role !== APP_LABELS.BRANCH_DATA_STEWARD
        ) {
          return (
            <tr key={element?.sectionId}>
              <td className="ymca-leftSpacer notranslate">
                {element?.sectionName}
              </td>
              {getStatus(element?.sectionStatus)}
              <td
                className={`updated-by-data notranslate ${
                  !element?.lastUpdatedBy && 'text-center'
                }`}
              >
                {element?.lastUpdatedBy || '----'}
              </td>
              <td
                className={`notranslate ${
                  !element?.lastUpdatedDate && 'text-center'
                }`}
              >
                {convertUtcToLocal(element?.lastUpdatedDate)}
              </td>
              <td className="ymca-cyanLine ymca-word-break">
                {get2023Actions(
                  name,
                  element?.sectionStatus,
                  tabId,
                  subTabsList,
                  index,
                  element?.sectionName
                )}
              </td>
            </tr>
          );
        }
        return null;
      });
    }
    return null;
  };

  const renderSectionslist = (name, tabId, subTabsList) => {
    if (overviewData && overviewData.length > 1) {
      const list = overviewData?.filter(res => res.formName === name);
      const sectionData = list ? list[0]?.sections : [];
      return sectionData?.map((element, index) => (
        <>
          <tr>
            <td className="ymca-leftSpacer notranslate">
              {element?.sectionName}
            </td>
            {getStatus(element?.sectionStatus)}
            <td
              className={`updated-by-data notranslate ${
                !element?.lastUpdatedBy && 'text-center'
              }`}
            >
              {element?.lastUpdatedBy || '----'}
            </td>
            <td
              className={`notranslate ${
                !element?.lastUpdatedDate && 'text-center'
              }`}
            >
              {convertUtcToLocal(element?.lastUpdatedDate)}
            </td>
            <td className="ymca-cyanLine ymca-word-break">
              {getActions(
                name,
                element?.sectionStatus,
                tabId,
                subTabsList,
                index,
                element?.sectionName
              )}
            </td>
          </tr>
          {name === APP_LABELS.COLLABORATIONS &&
            collabTabsView[index] &&
            showlist(
              collabrationTabDatas[index] || [],
              tabId,
              subTabsList[index],
              element?.sectionName
            )}
          {name === APP_LABELS.PROGRAM_ACTIVITIES &&
            programActivityView[index] &&
            showlist(
              programActivityDatas[index] || [],
              tabId,
              subTabsList[index],
              element?.sectionName
            )}
        </>
      ));
    }
    return '';
  };
  const renderSections2022list = (name, tabId, subTabsList) => {
    if (overviewData && overviewData.length > 1) {
      const list = overviewData?.filter(res => res.formName === name);
      const sectionData = list ? list[0]?.sections : [];
      return sectionData?.map((element, index) => (
        <>
          <tr>
            <td className="ymca-leftSpacer notranslate">
              {element?.sectionName}
            </td>
            {getStatus(element?.sectionStatus)}
            <td
              className={`updated-by-data notranslate ${
                !element?.lastUpdatedBy && 'text-center'
              }`}
            >
              {element?.lastUpdatedBy || '----'}
            </td>
            <td
              className={`notranslate ${
                !element?.lastUpdatedDate && 'text-center'
              }`}
            >
              {convertUtcToLocal(element?.lastUpdatedDate)}
            </td>
            <td className="ymca-cyanLine ymca-word-break">
              {getActions(
                name,
                element?.sectionStatus,
                tabId,
                subTabsList,
                index,
                subTabsList[index]
              )}
            </td>
          </tr>
          {name === APP_LABELS.COLLABORATIONS &&
            collabTabsView[index] &&
            showlist(
              collabrationTabDatas[index] || [],
              tabId,
              subTabsList[index],
              element?.sectionName
            )}
          {name === APP_LABELS.PROGRAM_ACTIVITIES &&
            programActivityView[index] &&
            showlist(
              programActivityDatas[index] || [],
              tabId,
              subTabsList[index],
              element?.sectionName
            )}
        </>
      ));
    }
    return '';
  };
  return (
    <div className="ymca-completeBox ymca-overDraftView">
      <button
        type="button"
        className="ymca-help-link text-decoration-none ymca-up-data-help-link annual-report-help"
        onClick={handleShowHelp}
      >
        HELP {/* */}
        <img src={helpicon} className="img-fluid" alt="caret-icon" />
      </button>
      <div className="ymca-tableResponsive">
        <table className="table ymca-table table-striped">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Status</th>
              <th>Last Updated By</th>
              <th>Last Updated On</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {selectedYear >= 2023 ? (
              <>
                <tr>
                  <td className="ymca-rowLine ymca-word-break">
                    01 {APP_LABELS.YMCA_DIRECTORY_INFO}
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
                <tr>
                  <td className="ymca-leftSpacer notranslate">
                    Association Details
                  </td>
                  {ymcaSiteInfoList(
                    APP_LABELS.YMCA_DIRECTORY_INFO,
                    getYmcaBranchesList,
                    !ymcsListEnabled ? 'View' : 'Hide'
                  )}
                </tr>

                {ymcsListEnabled &&
                  showlist(ymcaBranchData, 'v-pills-Directory-tab', null)}

                {renderPledgeSectionsYmcaList(
                  APP_LABELS.YMCA_DIRECTORY_INFO,
                  'v-pills-Directory-tab',
                  pledgeSubIds
                )}
              </>
            ) : (
              <>
                <tr>
                  <td className="ymca-rowLine ymca-word-break">
                    01 {APP_LABELS.YMCA_DIRECTORY_INFO}
                  </td>

                  {ymcaSiteInfoList(
                    APP_LABELS.YMCA_DIRECTORY_INFO,

                    getYmcaBranchesList,

                    !ymcsListEnabled ? 'View' : 'Hide'
                  )}
                </tr>

                {ymcsListEnabled &&
                  showlist(ymcaBranchData, 'v-pills-Directory-tab', null)}
              </>
            )}

            <tr>
              <td className="ymca-rowLine">02 {APP_LABELS.SITE_INFORMATION}</td>
              {ymcaSiteInfoList(
                APP_LABELS.SITE_INFORMATION,
                'v-pills-siteInformations-tab'
              )}
            </tr>
            <tr>
              <td className="ymca-rowLine">03 {APP_LABELS.EMPLOYEES}</td>
              {ymcaSiteInfoList(
                APP_LABELS.EMPLOYEE,
                'v-pills-updateEmployee-tab'
              )}
            </tr>
            <tr>
              <td colSpan="5" className="ymca-rowLine">
                04 {APP_LABELS.CONSTITUENCY_REPORT}
              </td>
            </tr>
            {renderSectionslist(
              APP_LABELS.CONSTITUENCY_REPORT,
              'v-pills-Constituency-tab',
              constituencySubIds
            )}
            <tr>
              <td colSpan="5" className="ymca-rowLine">
                05 {APP_LABELS.DIVERSITY}
              </td>
            </tr>
            {renderSectionslist(
              APP_LABELS.DIVERSITY_METRICS,
              'v-pills-globalMetrics-tab',
              diversitySubTds
            )}
            <tr>
              <td colSpan="5" className="ymca-rowLine">
                06 {APP_LABELS.COLLABORATIONS}
              </td>
            </tr>
            {renderSectionslist(
              APP_LABELS.COLLABORATIONS,
              'v-pills-Collaborations-tab',
              collaborationSubTds
            )}
            <tr>
              <td className="ymca-rowLine" colSpan="5">
                07{' '}
                {selectedYear >= '2024'
                  ? APP_LABELS.PROGRAMS_ACTIVITIES
                  : APP_LABELS.PROGRAM_ACTIVITIES}
              </td>
            </tr>
            {renderSectionslist(
              selectedYear >= '2024'
                ? APP_LABELS.PROGRAMS_ACTIVITIES
                : APP_LABELS.PROGRAM_ACTIVITIES,
              'v-pills-Program-tab',
              programActivitiesSubTds
            )}
            {selectedYear >= 2023 ? (
              <tr>
                <td className="ymca-rowLine">
                  08 {APP_LABELS.PLEDGE_STATEMENT}
                </td>
                {ymcaSiteInfoList(APP_LABELS.PLEDGE, 'v-pills-Pledge-tab')}
              </tr>
            ) : (
              <>
                <tr>
                  <td className="ymca-rowLine" colSpan="5">
                    08 {APP_LABELS.PLEDGE_STATEMENT}
                  </td>
                </tr>
                {renderSections2022list(
                  APP_LABELS.PLEDGE,
                  'v-pills-Pledge-tab',
                  pledgeSubIds
                )}
              </>
            )}

            <tr>
              <td className="ymca-rowLine">09 Review & Submit</td>
              {ymcaSiteInfoList(
                APP_LABELS.REVIEW_SUBMIT,
                'v-pills-ReviewSubmit-tab'
              )}
            </tr>
          </tbody>
        </table>
      </div>
      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={APP_LABELS.COMMON_ERROR_MESSAGE}
        showErrorPopup={errorPopup}
      />
      {help && <Help hidePopup={hideShowHelp} keyValue="overview" />}
    </div>
  );
};

OverviewTab.propTypes = {
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleGetOverviewData: PropTypes.func.isRequired,
  handleGetYmcaBranchData: PropTypes.func.isRequired,
  handlegetCollabNonProfitOrgApi: PropTypes.func.isRequired,
  handlegetCollabEducationOrgApi: PropTypes.func.isRequired,
  handlegetCollabGovtOrgAndAffiliatesApi: PropTypes.func.isRequired,
  handlegetCollabOtherCommunityOrgApi: PropTypes.func.isRequired,
  handlegetCollabPrivateOrgAndBusinessesApi: PropTypes.func.isRequired,
  handlegetCollaborationsAmongYsApi: PropTypes.func.isRequired,
  handlegetProgramActivitiesApi: PropTypes.func.isRequired,
  handlegetProgramActivityHousingApi: PropTypes.func.isRequired,
  handlegetProgramActivityFoodProgramApi: PropTypes.func.isRequired,
  handlegetProgramActivityArmedServiceApi: PropTypes.func.isRequired,
  handlegetProgramActivityOlderAdultApi: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetOverviewData: getAnnualReportOverviewData,
  handleGetYmcaBranchData: getYmcaBranchesData,
  handlegetCollabNonProfitOrgApi: getCollabNonProfitOrgApi,
  handlegetCollabEducationOrgApi: getCollabEducationOrgApi,
  handlegetCollabGovtOrgAndAffiliatesApi: getCollabGovtOrgAndAffiliatesApi,
  handlegetCollabOtherCommunityOrgApi: getCollabOtherCommunityOrgApi,
  handlegetCollabPrivateOrgAndBusinessesApi:
    getCollabPrivateOrgAndBusinessesApi,
  handlegetCollaborationsAmongYsApi: getCollaborationsAmongYsApi,
  handlegetProgramActivitiesApi: getProgramActivitiesApi,
  handlegetProgramActivityHousingApi: getProgramActivityHousingApi,
  handlegetProgramActivityFoodProgramApi: getProgramActivityFoodProgramApi,
  handlegetProgramActivityArmedServiceApi: getProgramActivityArmedServiceApi,
  handlegetProgramActivityOlderAdultApi: getProgramActivityOlderAdultApi
};

const mapStateToProps = state => ({
  OverviewData: FetchOverViewDataSelector(state),
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  YmcaBranchData: YmcaBrachDataSelector(state),
  CollabNonProfitOrgData: CollabNonProfitOrgSelector(state),
  CollabEducationOrgData: CollabEducationOrgSelector(state),
  CollabGovtOrgAndAffiliatesData: CollabGovtOrgAndAffiliatesSelector(state),
  CollabOtherCommunityOrgData: CollabOtherCommunityOrgSelector(state),
  CollabPrivateOrgAndBusinessesData:
    CollabPrivateOrgAndBusinessesSelector(state),
  CollaborationsAmongYsData: CollabAmongYsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(OverviewTab));
