import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Loader } from 'react-loaders';
import { Link } from 'react-router-dom';
import CustomModal from '@utilities/CustomModal';
import BackIcon from '@static/images/back-icon.svg';
import { APP_LABELS, META_DATA } from '@config/app-config';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import { cloneMetadata, getCloneCount } from '@redux/metadata-manager/clone/actions';
import { getDomains, getYears } from '@redux/metadata-manager/dashboard/actions';

import { handleResponse } from '../shared/index';


const MetaDataManagerClone = props => {
  const {
    handleGetDomains,
    handleGetYears,
    handleClone,
    handleCloneCount,
    
  } = props

  const [listOfYears, setListOfYears] = useState([]);
  const [listOfCloneYears, setListOfCloneYears] = useState([]);
  const [listOfDataDomains, setListOfDataDomains] = useState([]);
  const [fromYear, setFromYear] = useState('');
  const [toYear, setToYear] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dataDomainId, setDataDomainId] = useState(undefined);
  const [showFailure, setShowFailure] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccess, setShowSucess] = useState(false);
  const [successMessage, setSucessMessage] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(META_DATA.CONFIRM_META_DATA_CLONE);

  const loadClone = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await Promise.all([handleGetDomains(), handleGetYears()]);
      const domainRes = handleResponse(response[0]);
      const yearRes = handleResponse(response[1]);
      if (domainRes.isSuccess && yearRes.isSuccess) {
        setListOfYears(yearRes.data);
        setListOfDataDomains(domainRes.data);
        const currentYear = new Date().getFullYear();
        const nextYear = currentYear + 1;


        const clonedYears = [];
        if (!clonedYears.includes(currentYear)) {
          clonedYears.push(currentYear);
        }

        if (!clonedYears.includes(nextYear)) {
          clonedYears.push(nextYear);
        }

        if (!clonedYears.includes(nextYear + 1)) {
          clonedYears.push(nextYear + 1);
        }

        setListOfCloneYears(clonedYears);

        const initialDomain = domainRes.data[0]?.dataDomainId;
        const initialYear = yearRes.data[0];
        setDataDomainId(initialDomain);
        setFromYear(initialYear);
        setToYear(nextYear);
      }
    }
    finally {
      setIsLoading(false);
    }
  }, [handleGetDomains, handleGetYears])

  const initializeClone = useCallback(async () => {
    await loadClone();
  }, [handleGetDomains, handleGetYears]);

  const showCloneConfirm = useCallback(async (reqDmainId, reqFromYear, reqToYear) => {
    setIsLoading(true);
    try {
      const response = await handleCloneCount(reqDmainId, reqFromYear, reqToYear);
      const result = handleResponse(response);
      if (result.isSuccess) {
        setShowConfirmation(true);
        setConfirmationMessage(`
          <div>
          <p class="ymca-textTitle"> Are you sure you want to clone <span class="ymca-headingTitle">${reqFromYear}</span> year data to 
            <span class="ymca-headingTitle">${reqToYear}</span>? This will add the following records:
          </p>
          <table>
            ${result.data.map(item => `<tr><td class="ymca-headingTitle">${item.source} - </td><td>${item.recordCount}</td></tr>`).join('')}
          </table>
          </div>
        `);
      } else {
        setErrorMessage(result.message);
        setShowFailure(true);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleCloneClick = useCallback(async () => {
    await showCloneConfirm(dataDomainId, fromYear, toYear);
  }, [dataDomainId, fromYear, toYear]);

  const handleConfirmClick = async (reqDomainId, reqfromYear, reqtoYear) => {
    setShowConfirmation(false);
    setIsLoading(true);
    try {
      const response = await handleClone(reqDomainId, reqfromYear, reqtoYear);
      const result = handleResponse(response);
      if (result.isSuccess) {
        setSucessMessage(META_DATA.CLONE_META_DATA);
        setShowSucess(true);
      } else {
        setErrorMessage(result.message);
        setShowFailure(true);
      }
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    initializeClone();
  }, [initializeClone]);

  return (
    <BlockUi
      blocking={isLoading}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className='ymca-wrapper'>
        <main className='page-container'>
          <div className='container'>
            <div className='d-flex justify-content-between'>
              <nav aria-label='breadcrumb'>
                <ol className='breadcrumb ymca-breadcrumb'>
                  <li className='breadcrumb-item'>
                    <Link to='/metadata-manager/dashboard'>
                      <span>Dashboard</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className='breadcrumb-icon'
                      alt=''
                    />
                  </li>
                  <li className='breadcrumb-item active'>Clone Metadata</li>
                </ol>
              </nav>
              <Link className='ymca-title-link' to='/metadata-manager/dashboard' id="dashboard-back-icon">
                <img src={BackIcon} className='me-1 mb-2' alt='back-icon' />{' '}
                Back
              </Link>
            </div>
            <h5 className='ymca-sub-heading'>Clone Metadata</h5>
          </div>
          <div className='container'>
            <div className='row ymca-form align-items-center'>
              <div className='col-12 col-md-6 col-lg-3'>
                <div className='form-group'>
                  <label htmlFor='ddlDataDomains' className='form-label'>
                    Data Domain<span className='text-mandatory ms-1'>*</span>
                  </label>
                  <select
                    id="ddlDataDomains"
                    className='form-select'
                    aria-label='Default select example'
                    value={dataDomainId}
                    onChange={e => setDataDomainId(e.target.value)}
                  >
                    {
                      listOfDataDomains && listOfDataDomains.map(item => (<option value={item.dataDomainId}>{item.dataDomainName}</option>))
                    }
                  </select>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3'>
                <div className='form-group'>
                  <label htmlFor='ddlYears' className='form-label'>
                    Existing Year<span className='text-mandatory ms-1'>*</span>
                  </label>
                  <select
                    id="ddlYears"
                    className='form-select'
                    aria-label='Default select example'
                    value={fromYear}
                    onChange={e => setFromYear(e.target.value)}
                  >
                    {
                      listOfYears && listOfYears.map(item => (<option value={item}>{item}</option>))
                    }
                  </select>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3'>
                <div className='form-group'>
                  <label htmlFor='ddlCloneYears' className='form-label'>
                    Clone Year<span className='text-mandatory ms-1'>*</span>
                  </label>
                  <select
                    id="ddlCloneYears"
                    className='form-select'
                    aria-label='Default select example'
                    value={toYear}
                    onChange={e => setToYear(e.target.value)}
                  >
                    {
                      listOfCloneYears && listOfCloneYears.map(item => (<option value={item}>{item}</option>))
                    }
                  </select>
                </div>
              </div>
              <div className='col-12 col-md-12 col-lg-3'>
                <div className='d-flex'>
                  <button className='btn ymca-primary-btn' type='button' onClick={() => handleCloneClick()}>
                    Clone
                  </button>
                  <button
                    className='btn ymca-primary-outline-btn ms-3'
                    type='button'
                    onClick={() => loadClone()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </main>
      </div>
      <CustomModal
        isHtml
        show={showConfirmation}
        type={APP_LABELS.ATTENTION}
        message={confirmationMessage}
        submitButtonName="Ok"
        cancelButtonName="Cancel"
        onSubmit={() => handleConfirmClick(dataDomainId, fromYear, toYear)}
        onCancel={() => setShowConfirmation(false)}
      />
      <CustomModal
        show={showSuccess}
        type={APP_LABELS.SUCCESS}
        message={successMessage || APP_LABELS.SUCCESS}
        submitButtonName="Ok"
        onSubmit={() => {
         document.getElementById("dashboard-back-icon").click()
          setShowSucess(false)
        } }
      />
      <CustomModal
        show={showFailure}
        type={APP_LABELS.ERROR}
        message={errorMessage || APP_LABELS.ERROR_MESSAGE}
        submitButtonName="Ok"
        onSubmit={() => setShowFailure(false)}
      />
    </BlockUi>
  );
};
MetaDataManagerClone.propTypes = {
  handleGetDomains: PropTypes.func.isRequired,
  handleGetYears: PropTypes.func.isRequired,
  handleClone: PropTypes.func.isRequired,
  handleCloneCount: PropTypes.func.isRequired
}

const mapDispatchToProps = {
  handleGetDomains: getDomains,
  handleGetYears: getYears,
  handleClone: cloneMetadata,
  handleCloneCount: getCloneCount
};

export default connect(null, mapDispatchToProps)(MetaDataManagerClone);
