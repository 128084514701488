import { META_DATA_UPLOAD_URLS } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const uploadMetadata = requestData => (dispatch, getState) =>
  asyncRequest({
    url: META_DATA_UPLOAD_URLS.UPLOAD_META_DATA(),
    method: 'POST',
    data: requestData,
    successAction: data => ({
      type: types.UPLOAD_METADATA_SUCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.UPLOAD_METADATA_FAILURE,
      payload: error
    }),
    dispatch
  });

export const importSummaryMetadata = uploadId => (dispatch, getState) =>
  asyncRequest({
    url: META_DATA_UPLOAD_URLS.IMPORT_SUMMARY_META_DATA(uploadId),
    method: 'GET',
    successAction: data => ({
      type: types.IMPORT_SUMMARY_SUCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.IMPORT_SUMMARY_FAILURE,
      payload: error
    }),
    dispatch
  });
