// eslint-disable-next-line import/prefer-default-export
export const METADATA_SCORING_COLUMNS = () => [
    {
        Header: 'Eval Group ID',
        accessor: 'evalGroupId',
        className: 'notranslate'
    },
    {
        Header: 'Eval Group Name',
        accessor: 'evalGroupName',
        className: 'notranslate'
    },
    {
        Header: 'Eval Group',
        accessor: 'evalGroup',
        className: 'notranslate ymca-word-break ymca-ellipse'
    },
    {
        Header: 'Eval Group Type',
        accessor: 'evalGroupType',
        className: 'notranslate ymca-word-break ymca-ellipse'
    },
    {
        Header: 'Min Points Required',
        accessor: 'minPointsReq',
        className: 'notranslate ymca-word-break ymca-ellipse'
    },

    {
        Header: 'Earned Score',
        accessor: "earnedScore",
        className: 'notranslate ymca-word-break ymca-ellipse'
    }
];