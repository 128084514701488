import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Link, Redirect } from 'react-router-dom';
import Breadcrumb from '@static/images/breadcrumb-icon.svg';
import { Formik, Form, ErrorMessage } from 'formik';
import { APP_LABELS } from '@config/app-config';
import BackIcon from '@static/images/back-icon.svg';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Loader } from 'react-loaders';
import PropTypes from 'prop-types';
import {
  getSwimSafetyAssociationDropdown,
  saveAssociaton
} from '@redux/siwm-safety/dashboard/actions';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import CustomModal from '@utilities/CustomModal';
import { Multiselect } from 'multiselect-react-dropdown';
import TextError from '@modules/shared/components/reusable-components/text-error';
import get from 'lodash.get';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';

const AddBranch = props => {
  const { handlegetAssociationList, handleSaveAssociation, LoginUserData } =
    props;
  const [loader, setLoader] = useState(false);
  const [associationList, setAssociationList] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [selectedAssociation, setSelectedAssociation] = useState(null);
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const associationNumber = get(associateData, 'associationNumber', '')
    ? get(associateData, 'associationNumber', '')
    : get(LoginUserData, 'user.associations[0]', '');

  const validationObj = {
    branches: Yup.array()
      .min(1, '* Atleast 1 branch required.')
      .required('* Atleast 1 branch required.')
  };
  const validationSchema = Yup.object(validationObj);

  const initialValues = {
    association: selectedAssociation,
    branches: []
  };

  useEffect(() => {
    setLoader(true);
    handlegetAssociationList()
      .then(result => {
        if (result?.data?.data) {
          setLoader(false);
          setAssociationList(result?.data?.data);
        } else {
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    if (associationList.length > 0) setSelectedAssociation(associationNumber);
  }, [associationList]);

  useEffect(() => {
    if (selectedAssociation) {
      const associationDetails = associationList.find(
        asso => asso.associationNumber === selectedAssociation.toString()
      );
      setBranchDropdown(associationDetails?.branchs);
    }
  }, [selectedAssociation]);

  const getAssociationByAN = aNumber =>
    associationList.find(asso => asso.associationNumber === aNumber.toString());

  const onSubmit = formData => {
    const associationDetails = getAssociationByAN(selectedAssociation);
    const branchesData = formData.branches?.map(data => ({
      // id: data?.branchId,
      branchNumber: data?.branchNumber,
      name: data?.branchName,
      address: data?.branchAddress,
      contactNumber: data?.branchPhoneNumber,
      branchId: data?.branchId
    }));

    const payload = {
      associationId: associationDetails.associationId,
      associationNumber: associationDetails.associationNumber,
      name: associationDetails.associationName,
      address: associationDetails.associationAddress,
      email: associationDetails.associationEmail,
      contactNumber: associationDetails.associationPhoneNumber,
      swimSafetyBranches: branchesData
    };
    setLoader(true);
    handleSaveAssociation(payload)
      .then(res => {
        if (res.status === 200) {
          setLoader(false);
          setSuccessPopup(true);
          setSuccessMessage(APP_LABELS.BRANCH_SUCCESS_MESSAGE);
        } else {
          setLoader(false);
          setErrorMessage(res?.message);
          setErrorPopup(true);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper">
        <main className="page-container">
          <div className="ymca-data-wrapper volunteer-dashboard-wrapper">
            <div className="container">
              <section className="dashboard-header">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb ymca-breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/swim/association/dashboard">
                            {APP_LABELS.NAV_DASHBOARD}
                          </Link>
                          <img
                            src={Breadcrumb}
                            className="breadcrumb-icon"
                            alt="double-right-arrow"
                          />
                        </li>
                        <li className="breadcrumb-item active">
                          {APP_LABELS.ADD_BRANCH}
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 text-lg-end text-md-end">
                    <Link
                      className="ymca-title-link"
                      to="/swim/association/dashboard"
                    >
                      <img
                        src={BackIcon}
                        className="ymca-back-link"
                        alt="back-icon"
                      />
                      {APP_LABELS.DASH_BACK}
                    </Link>
                  </div>
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-lg-7 col-md-6 col-sm-12">
                    <div className="ymca-associate-info-wrapper">
                      <h2 className="ymca-sub-heading mb-2">
                        {APP_LABELS.ADD_BRANCH}
                      </h2>
                    </div>
                  </div>
                </div>
              </section>
              <hr className="custom-hr mt-0" />
              <div className="container  ymca-data-wrapper">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  enableReinitialize
                >
                  {({ values, setFieldValue }) => (
                    <Form
                      autoComplete="off"
                      className="ymca-registeration-form"
                    >
                      <div className="col-lg-4">
                        <div className="form-group mb-3">
                          <label htmlFor="Association" className="form-label">
                            Association{/* */}
                            <span className="text-mandatory ms-1">*</span>
                          </label>
                          <select
                            name="association"
                            className="multiSelectContainer searchWrapper select-arrow-hide form-select longSearchWrapper pe-5 notranslate"
                            aria-label="Default select example"
                            value={values.association}
                            disabled
                          >
                            {associationList?.map(data => (
                              <option
                                key={data.associationNumber}
                                value={data.associationNumber}
                              >
                                {data.associationName}
                              </option>
                            ))}
                          </select>
                          <ErrorMessage
                            component={TextError}
                            name="association"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <label htmlFor="Association" className="form-label">
                          Branch{/* */}
                          <span className="text-mandatory ms-1">*</span>
                        </label>
                        <Multiselect
                          className="swim-saftey-multi-branch notranslate"
                          name="branches"
                          showCheckbox
                          onSelect={selectedList => {
                            setFieldValue('branches', selectedList);
                          }}
                          onRemove={selectedList => {
                            setFieldValue('branches', selectedList);
                          }}
                          selectionLimit={50}
                          options={branchDropdown}
                          displayValue="branchName"
                          hidePlaceholder
                        />
                        <ErrorMessage component={TextError} name="branches" />
                      </div>

                      <div className="pt-3 mt-3">
                        <button
                          className="btn ymca-primary-btn login-btn mt-0 me-3 px-5"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </main>
      </div>

      {redirect && <Redirect to="/swim/association/dashboard" />}
      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
      {successPopup && (
        <CustomModal
          show={successPopup}
          type={APP_LABELS.SUCCESS}
          message={successMessage}
          submitButtonName="Ok"
          onSubmit={() => {
            setRedirect(true);
          }}
        />
      )}
    </BlockUi>
  );
};

AddBranch.propTypes = {
  handlegetAssociationList: PropTypes.func.isRequired,
  handleSaveAssociation: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  handlegetAssociationList: getSwimSafetyAssociationDropdown,
  handleSaveAssociation: saveAssociaton
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBranch);
