import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { ProtectedRoute } from '@scaffolding';
import { ErrorPage, LoginPage, NewLogin, PledgeStatement } from '@pages';
import { Home, PostLogin } from '@components';

import {
  UserTable,
  UserForm,
  AssociationDashboard,
  Dashboard,
  AnnualreportDashboard,
  AnnualReports,
  DetailView,
  UploadData,
  UploadFile,
  MoypDetailView,
  VolunteerRegisteration,
  VolunteerRegisterationPreview,
  VolunteerAdminDashboard,
  VolunteerAdminPreview,
  YusaAnnualDashboard,
  UserUploadFile,
  UserUploadData,
  CdpDetails,
  CdpUploadData,
  CdpDashboard,
  CDPAdminDashboard,
  CDPAssociatedashboard,
  FeedBackAndSupport,
  AccessErrorPage,
  UserErrorPage,
  SwimSafetyDashboard,
  SwimSafetyProgramDetails,
  SwimSafetyAddProgram,
  SwimSafetyProfiles,
  SwimSafetyAddProfiles,
  SwimProgramDashboard,
  AddAssociation,
  AddBranch,
  SwimAssociationDashboard,
  SwimUploadProfile,
  SwimUploadData,
  InsuranceDashboard,
  InsuranceAssociationDashboard,
  MetaDataManagerDashboard
} from '@modules';

import { getShowLoginPage } from '../../config/api-config';
import MetaDataManagerClone from '@modules/metadata-manager/components/clone/index';
import MetaDataManagerManageMetadata from '@modules/metadata-manager/components/manage-metadata/index';
import MetaDataManagerCategories from '@modules/metadata-manager/components/categories/index';
import MetaDataManagerConfigureCategory from '@modules/metadata-manager/components/categories/configure';
import MetaDataManagerManageCategoryItem from '@modules/metadata-manager/components/categories/manage-category-item';
import MetaDataManagerCategoryMapping from '@modules/metadata-manager/components/categories/mapping';
import MetaDataManagerUploadMetadata from '@modules/metadata-manager/components/upload-metadata/index';
import MetaDataManagerDataScoring from '@modules/metadata-manager/components/data-scoring/index';
import MetaDataManagerForms from '@modules/metadata-manager/components/data-scoring/forms/forms';
import MetaDataManagerSubForms from '@modules/metadata-manager/components/data-scoring/sub-forms/subforms';
import MetaDataManagerEvalGroups from '@modules/metadata-manager/components/data-scoring/evaluation-groups/evaluation-groups';
import MetaDataManagerDataPoint from '@modules/metadata-manager/components/data-scoring/data-points/data-points';
import MetadataManagerReview from '@modules/metadata-manager/components/upload-metadata/review';
import ScoringMetadata from '@modules/metadata-manager/components/scoring-metadata/index';

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route path="/login" exact component={LoginPage} />
      {getShowLoginPage()?.showLoginPage === 'true' && (
        <Route path="/yusa-login" exact component={NewLogin} />
      )}
      <Route path="/signin-oidc" component={PostLogin} />
      <Route path="/pledge-statement" component={PledgeStatement} />
      <ProtectedRoute path="/home" exact component={Home} />

      <ProtectedRoute path="/employee/dashboard" exact component={Dashboard} />
      <ProtectedRoute
        path="/employee/dashboard/upload/data"
        exact
        component={UploadData}
      />
      <ProtectedRoute
        path="/employee/dashboard/upload/file"
        exact
        component={UploadFile}
      />
      <ProtectedRoute
        path="/employee/dashboard/detailView"
        exact
        component={DetailView}
      />
      <ProtectedRoute
        path="/employee/association/dashboard"
        exact
        component={AssociationDashboard}
      />

      <ProtectedRoute
        path="/employee/moyp/detailView"
        exact
        component={MoypDetailView}
      />

      <ProtectedRoute
        path="/feedback/support"
        exact
        component={FeedBackAndSupport}
      />

      <ProtectedRoute path="/user/error/page" exact component={UserErrorPage} />
      <ProtectedRoute
        path="/access/error/page"
        exact
        component={AccessErrorPage}
      />

      <ProtectedRoute
        path="/annual/report/dashboard"
        exact
        component={AnnualreportDashboard}
      />
      <ProtectedRoute path="/annual/report" exact component={AnnualReports} />
      <ProtectedRoute
        path="/annual/yusa/dashboard"
        exact
        component={YusaAnnualDashboard}
      />

      <ProtectedRoute
        path="/volunteer/registration"
        exact
        component={VolunteerRegisteration}
      />

      <ProtectedRoute
        path="/volunteer/registration/preview"
        exact
        component={VolunteerRegisterationPreview}
      />

      <ProtectedRoute
        path="/volunteer/dashboard"
        exact
        component={VolunteerAdminDashboard}
      />
      <ProtectedRoute
        path="/volunteer/preview/:volunteerID"
        exact
        component={VolunteerAdminPreview}
      />

      <ProtectedRoute
        path="/userManagement/dashboard"
        exact
        component={UserTable}
      />
      <ProtectedRoute path="/userManagement/form" exact component={UserForm} />
      <ProtectedRoute
        path="/userManagement/form/:userID"
        exact
        component={UserForm}
      />
      <ProtectedRoute path="/cdp/upload/data" exact component={CdpUploadData} />
      <ProtectedRoute
        path="/cdp/associate/dashboard"
        exact
        component={CDPAssociatedashboard}
      />
      <ProtectedRoute
        path="/cdp/admin/dashboard"
        exact
        component={CDPAdminDashboard}
      />
      <ProtectedRoute path="/cdp/dashboard" exact component={CdpDashboard} />
      <ProtectedRoute path="/cdp/details" exact component={CdpDetails} />
      <ProtectedRoute
        path="/userManagement/upload/file"
        exact
        component={UserUploadFile}
      />
      <ProtectedRoute
        path="/userManagement/upload/data"
        exact
        component={UserUploadData}
      />

      <ProtectedRoute
        path="/swim/program/dashboard"
        exact
        component={SwimProgramDashboard}
      />

      <ProtectedRoute
        path="/swim/dashboard"
        exact
        component={SwimSafetyDashboard}
      />

      <ProtectedRoute
        path="/swim/safety/addprogram"
        exact
        component={SwimSafetyAddProgram}
      />

      <ProtectedRoute
        path="/swim/safety/program/details"
        exact
        component={SwimSafetyProgramDetails}
      />

      <ProtectedRoute
        path="/swim/safety/profiles"
        exact
        component={SwimSafetyProfiles}
      />

      <ProtectedRoute
        path="/swim/safety/add/profiles"
        exact
        component={SwimSafetyAddProfiles}
      />

      <ProtectedRoute
        path="/swim/safety/add/association"
        exact
        component={AddAssociation}
      />

      <ProtectedRoute
        path="/swim/safety/add/branch"
        exact
        component={AddBranch}
      />

      <ProtectedRoute
        path="/swim/association/dashboard"
        exact
        component={SwimAssociationDashboard}
      />

      <ProtectedRoute
        path="/swim/upload/profile"
        exact
        component={SwimUploadProfile}
      />

      <ProtectedRoute
        path="/swim/upload/data"
        exact
        component={SwimUploadData}
      />

      <ProtectedRoute
        path="/insurance/dashboard"
        exact
        component={InsuranceDashboard}
      />

      <ProtectedRoute
        path="/insurance/association/dashboard"
        exact
        component={InsuranceAssociationDashboard}
      />
      <ProtectedRoute
        path="/metadata-manager/dashboard"
        exact
        component={MetaDataManagerDashboard}
      />
      <ProtectedRoute
        path="/metadata-manager/clone"
        exact
        component={MetaDataManagerClone}
      />
      <ProtectedRoute
        path="/metadata-manager/manage-metadata"
        exact
        component={MetaDataManagerManageMetadata}
      />
      <ProtectedRoute
        path="/metadata-manager/scoring"
        exact
        component={ScoringMetadata}
      />

      <ProtectedRoute
        path="/metadata-manager/manage-metadata/:id"
        exact
        component={MetaDataManagerManageMetadata}
      />
      <ProtectedRoute
        path="/metadata-manager/upload-metadata"
        exact
        component={MetaDataManagerUploadMetadata}
      />
      <ProtectedRoute
        path="/metadata-manager/upload-metadata/review"
        exact
        component={MetadataManagerReview}
      />
      <ProtectedRoute
        path="/metadata-manager/categories"
        exact
        component={MetaDataManagerCategories}
      />
      <ProtectedRoute
        path="/metadata-manager/categories/configure-items"
        exact
        component={MetaDataManagerConfigureCategory}
      />
      <ProtectedRoute
        path="/metadata-manager/categories/manage-configure-items"
        exact
        component={MetaDataManagerManageCategoryItem}
      />
      <ProtectedRoute
        path="/metadata-manager/categories/mapping"
        exact
        component={MetaDataManagerCategoryMapping}
      />

      <ProtectedRoute
        path="/metadata-manager/datascoring"
        exact
        component={MetaDataManagerDataScoring}
      />

      <ProtectedRoute
        path="/metadata-manager/datascoring/forms"
        exact
        component={MetaDataManagerForms}
      />

      <ProtectedRoute
        path="/metadata-manager/datascoring/forms/subforms"
        exact
        component={MetaDataManagerSubForms}
      />

      <ProtectedRoute
        path="/metadata-manager/datascoring/forms/subforms/evalgroups"
        exact
        component={MetaDataManagerEvalGroups}
      />

      <ProtectedRoute
        path="/metadata-manager/datascoring/forms/subforms/evalgroups/datapoints"
        exact
        component={MetaDataManagerDataPoint}
      />

      <Route component={ErrorPage} />
    </Switch>
  </Router>
);

export default App;
