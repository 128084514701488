import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import BackIcon from '@static/images/back-icon.svg';
import { Link } from 'react-router-dom';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import SearchIcon from '@static/images/search-icon.svg';
import { getDataScoringEvaluationGroupView } from '@redux/metadata-manager/data-scoring/actions';
import EVALUATION_GROUPS_COLUMN from './evaluation-groups-column';
import Table from './table';

const MetaDataManagerEvalGroups = ({
  handleGetDataScoringEvaluationGroupsView,
  location
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const {
    Year,
    dataDomainName,
    DataDomain,
    Association,
    associationName,
    Form,
    formName,
    SubForm,
    subFormName
  } = location?.state;
  const initializeDataScoringEvaluationGroupView = async () => {
    setIsLoading(true);
    try {
      const response = await handleGetDataScoringEvaluationGroupsView(
        Year,
        DataDomain,
        Association,
        Form,
        SubForm
      );
      if (response?.data?.data) {
        setTableData(response.data.data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initializeDataScoringEvaluationGroupView();
  }, []);
  const renderTabledata = tableData?.map(data => {
    const location1 = {
      pathname:
        '/metadata-manager/datascoring/forms/subforms/evalgroups/datapoints',
      state: {
        Year: Year,
        DataDomain: DataDomain,
        Association: Association,
        dataDomainName: dataDomainName,
        associationName: associationName,
        Form: Form,
        formName: formName,
        SubForm: SubForm,
        subFormName: subFormName,
        EvaluationGroup: data?.evaluationGroupID,
        EvaluationGroupName: data?.evaluationGroupName
      }
    };
    return {
      Association: Association,
      EvaluationGroupID: data?.evaluationGroupID,
      EvaluationGroupName: data?.evaluationGroupName,
      PointsPossible: data.pointsPossible,
      PointsEarned: data.pointsEarned,
      MinPointsRequired: data.minPointsRequired,
      EarnedScore: data.earnedScore,
      ShowDataPoints: location1
    };
  });
  return (
    <BlockUi
      blocking={isLoading}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper">
        <main className="page-container">
          <div className="container">
            <div className="d-flex justify-content-between">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb ymca-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/metadata-manager/dashboard">
                      <span>Dashboard</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/metadata-manager/datascoring">
                      <span> Data Scoring</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={{
                        pathname: '/metadata-manager/datascoring/forms',
                        state: {
                          Year: Year,
                          DataDomain: DataDomain,
                          Association: Association,
                          dataDomainName: dataDomainName,
                          associationName: associationName,
                          Form: Form,
                          formName: formName
                        }
                      }}
                    >
                      <span>Forms</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={{
                        pathname:
                          '/metadata-manager/datascoring/forms/subforms',
                        state: {
                          Year: Year,
                          DataDomain: DataDomain,
                          Association: Association,
                          dataDomainName: dataDomainName,
                          associationName: associationName,
                          Form: Form,
                          formName: formName,
                          SubForm: SubForm,
                          subFormName: subFormName
                        }
                      }}
                    >
                      <span>Sub-Forms</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item active">Evaluation Groups</li>
                </ol>
              </nav>
              <Link
                className="ymca-title-link"
                to={{
                  pathname: '/metadata-manager/datascoring/forms/subforms',
                  state: {
                    Year: Year,
                    DataDomain: DataDomain,
                    Association: Association,
                    dataDomainName: dataDomainName,
                    associationName: associationName,
                    Form: Form,
                    formName: formName,
                    SubForm: SubForm,
                    subFormName: subFormName
                  }
                }}
              >
                <img src={BackIcon} className="me-1 mb-2" alt="back-icon" />{' '}
                Back
              </Link>
            </div>
            <h5 className="ymca-sub-heading">Evaluation Groups</h5>
          </div>

          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card outline-card">
                  <div className="card-body p-0">
                    <div className="ymca-dark-para-2">Year</div>
                    <div className="ymca-dark-para-5 mb-0">
                      {location?.state?.Year}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card outline-card">
                  <div className="card-body p-0">
                    <div className="ymca-dark-para-2">Data Domain</div>
                    <div className="ymca-dark-para-5 mb-0">
                      {location?.state?.dataDomainName}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card outline-card">
                  <div className="card-body p-0">
                    <div className="ymca-dark-para-2">Association</div>
                    <div className="ymca-dark-para-5 mb-0">
                      {location?.state?.associationName}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card outline-card">
                  <div className="card-body p-0">
                    <div className="ymca-dark-para-2">Form</div>
                    <div className="ymca-dark-para-5 mb-0">
                      {location?.state?.formName}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card outline-card">
                  <div className="card-body p-0">
                    <div className="ymca-dark-para-2">Sub-Form</div>
                    <div className="ymca-dark-para-5 mb-0">
                      {location?.state?.subFormName}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <div className="ymca-section-heading">
                Evaluation Groups Details
              </div>
              <div className="search-form-wrapper mb-0">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      <img src={SearchIcon} alt="SearchIcon" />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control search-form-control"
                    placeholder="Search"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive custom-scrollbar mt-3">
              {tableData && (
                <Table
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  rows={renderTabledata}
                  headers={EVALUATION_GROUPS_COLUMN()}
                  showpagination
                />
              )}
            </div>
          </div>
        </main>
      </div>
    </BlockUi>
  );
};
MetaDataManagerEvalGroups.propTypes = {
  handleGetDataScoringEvaluationGroupsView: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetDataScoringEvaluationGroupsView: getDataScoringEvaluationGroupView
};
export default connect(null, mapDispatchToProps)(MetaDataManagerEvalGroups);
