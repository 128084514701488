import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { connect } from 'react-redux';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import { getEnvironment } from '@config/api-config';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import GetModulesConfigSelector from '@redux/module-config/selectors';
import getModulesConfigData from '@redux/module-config/actions';
import { APP_LABELS, ENUMS, STORAGE_KEYS } from '@config/app-config';
// import SwimProgramImage from '@static/images/swimRegistration/swim-program.svg';
import moment from 'moment';
import helpSectionPages from './strapi-json/help-section-pages';
import homePageDataCollection from './strapi-json/home-page-data-collections';
import volunteerRegistration from './strapi-json/volunteer-registrations';
import commonAxios from '../../utilities/CommonAxios';
import ListIcon from '../../static/images/ymca-list-icon.svg';
import RightIconBlue from '../../static/images/right-icon-blue.svg';
import CampDataIcon from '../../static/images/camp-data-img.svg';
import CheckListIcon from '../../static/images/check-list-icon.svg';
import SecureStorage from '../../utilities/SecureStorage';

const Home = props => {
  SecureStorage.setItem(STORAGE_KEYS.DASHBOARD_URL, '');
  const { LoginUserData, handleToGetModuleConfigInfo, ModulesConfigData } =
    props;
  const [homedata, setHomeData] = useState('');
  const [loader, setLoader] = useState(false);
  const loggedInUserRole = get(LoginUserData, 'user.role[0]', null);
  const userGroups = get(LoginUserData, 'user.groups', []);
  const loggedInVolunteerUserModuleRoles = get(
    LoginUserData,
    'user.UserManagementInfo.userModuleRoles',
    []
  );
  const cdpPrograms = get(LoginUserData, 'user.cdpPrograms', null);

  const UserManagementStatus = get(
    LoginUserData,
    'user.UserManagementInfo.status',
    null
  );

  const getModule = moduleName => {
    if (loggedInVolunteerUserModuleRoles.length > 0) {
      const moduleData = loggedInVolunteerUserModuleRoles?.find(module =>
        module?.moduleName?.includes(moduleName)
      );
      return moduleData;
    }
    return null;
  };

  const checkModuleAccess = moduleName => {
    if (getModule(moduleName)) {
      return true;
    }
    return false;
  };

  const processHelpSectionData = helpSectionData => {
    const formattedData = {};
    const response = helpSectionData;
    response.forEach(element => {
      formattedData[element?.attributes?.page] = element?.attributes;
    });
    sessionStorage.setItem('allinstructions', JSON.stringify(formattedData));
  };

  const setStrapiData = () => {
    setLoader(true);
    setHomeData(homePageDataCollection?.data[0]?.attributes);
    processHelpSectionData(helpSectionPages.data);
    sessionStorage.setItem(
      'VolunteerTermConditionTerm',
      volunteerRegistration?.data[0]?.attributes?.Volunteer_Attestation
    );
    setLoader(false);
  };

  const postStrapiCall = () => {
    setLoader(true);
    if (getEnvironment() === 'DEV' || getEnvironment() === 'STAGE') {
      setStrapiData();
    }
    commonAxios({
      url: '/login',
      method: 'GET'
    })
      .then(resp => {
        sessionStorage.setItem('strapitoken', resp?.data?.jwt);
        commonAxios({
          url: '/home-page-data-collections',
          method: 'GET',
          headers: {
            Authorization: `Bearer ${resp?.data?.jwt}`
          }
        })
          .then(res => {
            setHomeData(res?.data?.data[0]?.attributes);
          })
          .catch(() => {
            setHomeData(homePageDataCollection?.data[0]?.attributes);
          });
        commonAxios({
          url: '/help-section-pages?pagination[pageSize]=100&pagination[page]=1',
          method: 'GET',
          headers: {
            Authorization: `Bearer ${resp?.data?.jwt}`
          }
        })
          .then(res => {
            const formattedData = {};
            const response = res?.data?.data;
            response.forEach(element => {
              formattedData[element?.attributes?.page] = element?.attributes;
            });
            sessionStorage.setItem(
              'allinstructions',
              JSON.stringify(formattedData)
            );
          })
          .catch(() => {
            processHelpSectionData(helpSectionPages.data);
          });
        commonAxios({
          url: '/volunteer-registrations',
          method: 'GET',
          headers: {
            Authorization: `Bearer ${resp?.data?.jwt}`
          }
        })
          .then(response => {
            // Handle the response data
            setLoader(false);
            sessionStorage.setItem(
              'VolunteerTermConditionTerm',
              response?.data?.data[0]?.attributes?.Volunteer_Attestation
            );
          })
          .catch(() => {
            sessionStorage.setItem(
              'VolunteerTermConditionTerm',
              volunteerRegistration?.data[0]?.attributes?.Volunteer_Attestation
            );
          });
      })
      .catch(() => {
        setStrapiData();
      });
  };

  useEffect(() => {
    sessionStorage.removeItem(STORAGE_KEYS.YUSA_SELECTED_ASSOCIATE);
    setLoader(true);
    postStrapiCall();
    if (get(LoginUserData, 'user.annualReports') && loggedInUserRole !== null) {
      handleToGetModuleConfigInfo().catch(() => {
        setLoader(false);
      });
    } else {
      setLoader(false);
    }
  }, []);

  const onClickVolunteerCard = (statusValue, eventValue) => {
    if (
      (statusValue === 'InActive' &&
        loggedInUserRole !== APP_LABELS.YUSA_DATA_STEWARD) ||
      (loggedInUserRole === APP_LABELS.YUSA_DATA_STEWARD &&
        !userGroups?.includes(ENUMS.VOLUNTEER_REGISTRATION))
    ) {
      eventValue.preventDefault();
    }
  };

  /** commenting for data between */

  const checkDateBetween = data => {
    const currentDate = new Date();
    const startDate = data?.startDate;
    const endDate = data?.endDate;
    if (startDate && endDate) {
      const isDatebetween = moment(currentDate)?.isBetween(startDate, endDate);
      return isDatebetween;
    }
    return false;
  };
  const checkPastDueDate = data => {
    const currentDate = new Date();
    const startDate = data?.startDate;
    const endDate = data?.endDate;
    if (startDate && endDate) {
      const isDatebetween = moment(currentDate)?.isAfter(endDate);
      return isDatebetween;
    }
    return false;
  };

  const setARDashboardUrl = () => {
    SecureStorage.setItem(
      STORAGE_KEYS.DASHBOARD_URL,
      '/annual/report/dashboard'
    );
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <main className="page-container">
        <div className="program-admin-wrapper dashboard-wrapper">
          <div className="container">
            <section className="dashboard-header">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="user-name">
                    <h6>{APP_LABELS.WELCOME}</h6>
                    <h2 className="ymca-sub-heading mb-0 notranslate">
                      {`${get(LoginUserData, 'user.firstName', 'User')} ${get(
                        LoginUserData,
                        'user.lastName',
                        ''
                      )}`}
                    </h2>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <hr className="custom-hr" />

          {homedata?.Data_Collection !== '' &&
            homedata?.Employee_Data_Collection !== '' && (
              <section className="data-exchange-section">
                <div className="container">
                  <div className="row ">
                    <div className="col-lg-12">
                      <p className="ymca-para mb-5">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: homedata?.Data_Collection
                          }}
                        />
                      </p>
                    </div>
                  </div>

                  {get(LoginUserData, 'user.annualReports') &&
                    get(LoginUserData, 'user.staff') &&
                    homedata?.AnnualReportTab &&
                    homedata?.EmployeeTab && (
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="ymca-strip">
                            Employee records need to be updated as part of the
                            Annual Report Nonfinancials.
                          </div>
                        </div>
                      </div>
                    )}

                  <div className="row">
                    {get(LoginUserData, 'user.annualReports') &&
                      homedata?.AnnualReportTab && (
                        <div className="col-lg-4">
                          <Link
                            to={`${loggedInUserRole &&
                              (loggedInUserRole ===
                                APP_LABELS.YUSA_DATA_STEWARD ||
                                loggedInUserRole ===
                                APP_LABELS.YUSA_DATA_VIEWER ||
                                loggedInUserRole ===
                                APP_LABELS.ALLIANCE_DATA_STEWARD ||
                                loggedInUserRole ===
                                APP_LABELS.YESS_DATA_STEWARD)
                              ? '/annual/yusa/dashboard'
                              : '/annual/report/dashboard'
                              }`}
                            className="text-decoration-none"
                          >
                            <div className="card ymca-primary-card data-cards p-0">
                              {/** commenting code for demo purpose */}

                              <div className="card-header-block">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      homedata?.Annual_Report_Collection_Title
                                  }}
                                />
                                <span className="col d-flex justify-content-center">
                                  {checkDateBetween(
                                    get(ModulesConfigData, 'data')?.find(
                                      o => o?.moduleName === 'Annual Reports'
                                    )
                                  ) && (
                                      <div className="ymca-text-blink">
                                        <span className="text">
                                          {`${get(ModulesConfigData, 'data')?.find(
                                            o =>
                                              o?.moduleName === 'Annual Reports'
                                          )?.year
                                            } Submission Open`}
                                        </span>
                                      </div>
                                    )}
                                  {/* {checkPastDueDate(
                                    get(ModulesConfigData, 'data')?.find(
                                      o => o?.moduleName === 'Annual Reports'
                                    )
                                  ) && (
                                    <div className="ymca-past-due-text-blink">
                                      <span className="text">
                                        {`${
                                          get(ModulesConfigData, 'data')?.find(
                                            o =>
                                              o?.moduleName === 'Annual Reports'
                                          )?.year
                                        } Past Due`}
                                      </span>
                                    </div>
                                  )} */}
                                </span>
                              </div>
                              <div
                                className="ymcaStrapiImage"
                                dangerouslySetInnerHTML={{
                                  __html: homedata?.Annual_Report_Image
                                }}
                              />

                              <div className="card-sub-header-block">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: homedata?.Annual_Report_Submit_Date
                                  }}
                                />
                              </div>
                              <div className="card-body">
                                <div className="card-body-content">
                                  <p className="ymca-para">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          homedata?.Annual_Report_Collection
                                      }}
                                    />
                                  </p>
                                  {/* {/* {loggedInUserRole !==
                                    APP_LABELS.YUSA_DATA_STEWARD &&
                                    loggedInUserRole !==
                                      APP_LABELS.YUSA_DATA_VIEWER &&
                                    loggedInUserRole !==
                                      APP_LABELS.ALLIANCE_DATA_STEWARD &&
                                    loggedInUserRole !==
                                      APP_LABELS.BRANCH_DATA_STEWARD &&
                                    loggedInUserRole !==
                                      APP_LABELS.YESS_DATA_STEWARD && (
                                      <ul className="list-unstyled footer-list">
                                        <li className="footer-list-items mb-3">
                                          <img src={ListIcon} alt="icon" />
                                          <Link
                                            onClick={() => setARDashboardUrl()}
                                            to={{
                                              pathname: '/annual/report',
                                              state: {
                                                sectionId:
                                                  'v-pills-Directory-tab',
                                                selectedYear: get(
                                                  ModulesConfigData,
                                                  'data'
                                                )?.find(
                                                  o =>
                                                    o?.moduleName ===
                                                    'Annual Reports'
                                                )?.year
                                              }
                                            }}
                                            className="mx-3"
                                          >
                                            Y Directory Information
                                          </Link>
                                        </li>

                                        <li className="footer-list-items mb-3">
                                          <img src={ListIcon} alt="icon" />
                                          <Link
                                            onClick={() => setARDashboardUrl()}
                                            to={{
                                              pathname: '/annual/report',
                                              state: {
                                                sectionId:
                                                  'v-pills-Constituency-tab',
                                                selectedYear: get(
                                                  ModulesConfigData,
                                                  'data'
                                                )?.find(
                                                  o =>
                                                    o?.moduleName ===
                                                    'Annual Reports'
                                                )?.year
                                              }
                                            }}
                                            className="mx-3"
                                          >
                                            Constituency Report
                                          </Link>
                                        </li>

                                        <li className="footer-list-items mb-3">
                                          <img src={ListIcon} alt="icon" />
                                          <Link
                                            onClick={() => setARDashboardUrl()}
                                            to={{
                                              pathname: '/annual/report',
                                              state: {
                                                sectionId:
                                                  'v-pills-globalMetrics-tab',
                                                selectedYear: get(
                                                  ModulesConfigData,
                                                  'data'
                                                )?.find(
                                                  o =>
                                                    o?.moduleName ===
                                                    'Annual Reports'
                                                )?.year
                                              }
                                            }}
                                            className="mx-3"
                                          >
                                            Diversity and Inclusion Survey
                                          </Link>
                                        </li>
                                        <li className="footer-list-items">
                                          <img src={ListIcon} alt="icon" />
                                          <Link
                                            onClick={() => setARDashboardUrl()}
                                            to={{
                                              pathname: '/annual/report',
                                              state: {
                                                sectionId:
                                                  'v-pills-Collaborations-tab',
                                                selectedYear: get(
                                                  ModulesConfigData,
                                                  'data'
                                                )?.find(
                                                  o =>
                                                    o?.moduleName ===
                                                    'Annual Reports'
                                                )?.year
                                              }
                                            }}
                                            className="mx-3"
                                          >
                                            Collaborations Report
                                          </Link>
                                        </li>
                                      </ul>
                                    )} */}
                                </div>
                                <Link
                                  to={`${loggedInUserRole &&
                                    (loggedInUserRole ===
                                      APP_LABELS.YUSA_DATA_STEWARD ||
                                      loggedInUserRole ===
                                      APP_LABELS.YUSA_DATA_VIEWER ||
                                      loggedInUserRole ===
                                      APP_LABELS.ALLIANCE_DATA_STEWARD ||
                                      loggedInUserRole ===
                                      APP_LABELS.YESS_DATA_STEWARD)
                                    ? '/annual/yusa/dashboard'
                                    : '/annual/report/dashboard'
                                    }`}
                                  className="ymca-card-link"
                                >
                                  {APP_LABELS.START_NOW}
                                  <img
                                    src={RightIconBlue}
                                    className="img-fluid ms-2"
                                    alt="right-icon-blue"
                                  />
                                </Link>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )}

                    {get(LoginUserData, 'user.staff') &&
                      homedata?.EmployeeTab && (
                        <div className="col-lg-4">
                          <Link
                            to="/employee/dashboard"
                            className="text-decoration-none"
                          >
                            <div className="card ymca-primary-card data-cards p-0">
                              <div className="card-header-block">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      homedata?.Employee_Data_Collection_Title
                                  }}
                                />
                              </div>
                              <div
                                className="ymcaStrapiImage"
                                dangerouslySetInnerHTML={{
                                  __html: homedata?.Employee_Data_Image
                                }}
                              />
                              <div className="card-sub-header-block">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: homedata?.Employee_Submit_Date
                                  }}
                                />
                              </div>
                              <div className="card-body">
                                <div className="card-body-content">
                                  <p className="ymca-para">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          homedata?.Employee_Data_Collection
                                      }}
                                    />
                                  </p>
                                </div>
                                <Link
                                  to="/employee/dashboard"
                                  className="ymca-card-link"
                                >
                                  {APP_LABELS.START_NOW}
                                  <img
                                    src={RightIconBlue}
                                    className="img-fluid ms-2"
                                    alt="right-icon-blue"
                                  />
                                </Link>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )}

                    {get(LoginUserData, 'user.associationCampData') && (
                      <div className="col-lg-4">
                        <div className="card ymca-primary-card data-cards p-0">
                          <img
                            src={CampDataIcon}
                            className="img-fluid data-cards-img"
                            alt="camp-data-img"
                          />
                          <div className="card-header-block">
                            {'Camp Data Exchange'}
                          </div>
                          <div className="card-sub-header-block">
                            {'Submit by 15 December 2022'}
                          </div>
                          <div className="card-body">
                            <div className="card-body-content">
                              <p className="ymca-para">
                                {`Sed lorem augue, commodo at augue sit amet, hendrerit
                                        blandit velit. Vivamus non gravida ipsum. Curabitur a risus elit. Sed
                                        non
                                        laoreet nunc,`}
                              </p>
                              <div className="list-group">
                                <div className="list-group-item  border-0 ps-0 ymca-link">
                                  <img
                                    src={ListIcon}
                                    className="img-fluid me-2"
                                    alt="list-icon"
                                  />
                                  {'Camp Data Information'}
                                </div>
                                <div className="list-group-item  border-0 ps-0 ymca-link">
                                  <img
                                    src={ListIcon}
                                    className="img-fluid me-2"
                                    alt="list-icon"
                                  />
                                  {'Camp Data Report'}
                                </div>
                                <div className="list-group-item  border-0 ps-0 ymca-link">
                                  <img
                                    src={CheckListIcon}
                                    className="img-fluid me-1"
                                    alt="checklist-icon"
                                  />
                                  {'Data Survey'}
                                </div>
                                <div className="list-group-item  border-0 ps-0 ymca-link">
                                  <img
                                    src={ListIcon}
                                    className="img-fluid me-2"
                                    alt="list-icon"
                                  />
                                  {'Collaborations Report'}
                                </div>
                              </div>
                            </div>
                            <Link to="/" className="ymca-card-link">
                              {APP_LABELS.START_NOW}
                              <img
                                src={RightIconBlue}
                                className="img-fluid ms-2"
                                alt="right-icon-blue"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}

                    {homedata?.VolunteerRegistratioTab &&
                      (checkModuleAccess(ENUMS.VOLUNTEER_REGISTRATION) ||
                        (loggedInUserRole &&
                          loggedInUserRole ===
                          APP_LABELS.YUSA_DATA_STEWARD)) && (
                        <div className="col-lg-4">
                          <Link
                            to={`${loggedInUserRole &&
                              loggedInUserRole === APP_LABELS.YUSA_DATA_STEWARD
                              ? '/volunteer/dashboard'
                              : '/volunteer/registration/preview'
                              }`}
                            className={
                              (UserManagementStatus === 'InActive' &&
                                loggedInUserRole !==
                                APP_LABELS.YUSA_DATA_STEWARD) ||
                                (loggedInUserRole ===
                                  APP_LABELS.YUSA_DATA_STEWARD &&
                                  !userGroups?.includes(
                                    ENUMS.VOLUNTEER_REGISTRATION
                                  ))
                                ? 'text-decoration-none volunteer-card-disable'
                                : ' text-decoration-none'
                            }
                            onClick={event =>
                              onClickVolunteerCard(UserManagementStatus, event)
                            }
                          >
                            <div
                              className="card ymca-primary-card data-cards p-0"
                              data-bs-toggle={
                                UserManagementStatus === 'InActive' &&
                                  loggedInUserRole !==
                                  APP_LABELS.YUSA_DATA_STEWARD
                                  ? 'tooltip'
                                  : ''
                              }
                              data-bs-placement={
                                UserManagementStatus === 'InActive' &&
                                  loggedInUserRole !==
                                  APP_LABELS.YUSA_DATA_STEWARD
                                  ? 'right'
                                  : ''
                              }
                              title={
                                UserManagementStatus === 'InActive' &&
                                  loggedInUserRole !==
                                  APP_LABELS.YUSA_DATA_STEWARD
                                  ? 'User status is inactive; please contact administrator.'
                                  : ''
                              }
                            >
                              <div className="card-header-block">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      homedata?.Volunteer_Registration_Title
                                  }}
                                />
                              </div>
                              <div
                                className="ymcaStrapiImage"
                                dangerouslySetInnerHTML={{
                                  __html: homedata?.Volunteer_Registration_Image
                                }}
                              />

                              <div className="card-sub-header-block" />
                              <div className="card-body">
                                <div className="card-body-content">
                                  <p className="ymca-para">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          homedata?.Volunteer_Registration_Card_Text
                                      }}
                                    />
                                  </p>
                                </div>
                                <Link
                                  to={`${loggedInUserRole &&
                                    loggedInUserRole ===
                                    APP_LABELS.YUSA_DATA_STEWARD
                                    ? '/volunteer/dashboard'
                                    : '/volunteer/registration/preview'
                                    }`}
                                  className={
                                    UserManagementStatus === 'InActive' &&
                                      loggedInUserRole !==
                                      APP_LABELS.YUSA_DATA_STEWARD
                                      ? 'ymca-card-link volunteer-card-disable'
                                      : ' ymca-card-link'
                                  }
                                  onClick={event =>
                                    onClickVolunteerCard(
                                      UserManagementStatus,
                                      event
                                    )
                                  }
                                >
                                  {APP_LABELS.START_NOW}
                                  <img
                                    src={RightIconBlue}
                                    className="img-fluid ms-2"
                                    alt="right-icon-blue"
                                  />
                                </Link>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )}
                    {/** *commenting out for prod deployment */}
                    {homedata?.ChronicDiseaseProgramTab &&
                      (loggedInUserRole === APP_LABELS.YUSA_DATA_STEWARD ||
                        loggedInUserRole === APP_LABELS.BRANCH_DATA_STEWARD ||
                        loggedInUserRole === APP_LABELS.CEO_DATA_STEWARD ||
                        cdpPrograms?.length > 0) && (
                        <div className="col-lg-4">
                          <Link
                            to="/cdp/dashboard"
                            className="text-decoration-none"
                          >
                            <div className="card ymca-primary-card data-cards p-0">
                              <div className="card-header-block custom-height">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      homedata?.Chronic_Disease_Program_Title
                                  }}
                                />
                              </div>
                              <div
                                className="ymcaStrapiImage"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    homedata?.Chronic_Disease_Program_Image
                                }}
                              />

                              <div className="card-sub-header-block">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      homedata?.Chronic_Disease_Program_Date
                                  }}
                                />
                              </div>
                              <div className="card-body">
                                <div className="card-body-content">
                                  <p className="ymca-para">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          homedata?.Chronic_Disease_Program_Card_Text
                                      }}
                                    />
                                  </p>
                                </div>
                                <Link
                                  to="/cdp/dashboard"
                                  className="ymca-card-link"
                                >
                                  {APP_LABELS.START_NOW}
                                  <img
                                    src={RightIconBlue}
                                    className="img-fluid ms-2"
                                    alt="right-icon-blue"
                                  />
                                </Link>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )}

                    {homedata?.SwimTab &&
                      (loggedInUserRole === APP_LABELS.YUSA_DATA_STEWARD ||
                        userGroups?.includes('Swim Safety')) && (
                        <div className="col-lg-4">
                          <Link
                            to={`${loggedInUserRole &&
                              (loggedInUserRole ===
                                APP_LABELS.YUSA_DATA_STEWARD ||
                                loggedInUserRole ===
                                APP_LABELS.YUSA_DATA_VIEWER ||
                                APP_LABELS.VIEWER_PERMISSION_ROLES.includes(
                                  loggedInUserRole
                                ))
                              ? '/swim/dashboard'
                              : '/swim/association/dashboard'
                              }`}
                            className="text-decoration-none"
                          >
                            <div className="card ymca-primary-card data-cards p-0">
                              <div className="card-header-block custom-height">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: homedata?.Swim_Safety_Title
                                  }}
                                />
                              </div>
                              <div
                                className="ymcaStrapiImage"
                                dangerouslySetInnerHTML={{
                                  __html: homedata?.Swim_Safety_Image
                                }}
                              />

                              <div className="card-sub-header-block">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: homedata?.Swim_Safety_Date
                                  }}
                                />
                              </div>
                              <div className="card-body">
                                <div className="card-body-content">
                                  <p className="ymca-para">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: homedata?.Swim_Safety_Card_Text
                                      }}
                                    />
                                  </p>
                                </div>
                                <Link
                                  to={`${loggedInUserRole &&
                                    (loggedInUserRole ===
                                      APP_LABELS.YUSA_DATA_STEWARD ||
                                      loggedInUserRole ===
                                      APP_LABELS.YUSA_DATA_VIEWER ||
                                      APP_LABELS.VIEWER_PERMISSION_ROLES.includes(
                                        loggedInUserRole
                                      ))
                                    ? '/swim/dashboard'
                                    : '/swim/association/dashboard'
                                    }`}
                                  className="ymca-card-link"
                                >
                                  {APP_LABELS.START_NOW}
                                  <img
                                    src={RightIconBlue}
                                    className="img-fluid ms-2"
                                    alt="right-icon-blue"
                                  />
                                </Link>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )}

                    {homedata?.LiabilityTab &&
                      (loggedInUserRole === APP_LABELS.YUSA_DATA_STEWARD ||
                        APP_LABELS.COI_PERMISSION_ROLES.includes(
                          loggedInUserRole
                        ) ||
                        loggedInUserRole === APP_LABELS.CEO_DATA_STEWARD ||
                        userGroups?.includes(APP_LABELS.INSURANCE_TITLE)) && (
                        <div className="col-lg-4">
                          <Link
                            to={`${(loggedInUserRole &&
                              loggedInUserRole ===
                              APP_LABELS.YUSA_DATA_STEWARD) ||
                              APP_LABELS.COI_PERMISSION_ROLES.includes(
                                loggedInUserRole
                              )
                              ? '/insurance/dashboard'
                              : '/insurance/association/dashboard'
                              }`}
                            className="text-decoration-none"
                          >
                            <div className="card ymca-primary-card data-cards p-0">
                              <div className="card-header-block custom-height">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: homedata?.Liability_Title
                                  }}
                                />
                              </div>
                              <div
                                className="ymcaStrapiImage"
                                dangerouslySetInnerHTML={{
                                  __html: homedata?.Liability_Image
                                }}
                              />
                              {/* <img
                                src={InsuranceCard}
                                className="ymcaStrapiImage"
                                alt="insurance-card"
                              /> */}
                              <div className="card-sub-header-block">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: homedata?.Liability_Date
                                  }}
                                />
                              </div>
                              <div className="card-body">
                                <div className="card-body-content">
                                  <p className="ymca-para">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: homedata?.Liability_Card_Text
                                      }}
                                    />
                                  </p>
                                </div>
                                <Link
                                  to={`${(loggedInUserRole &&
                                    loggedInUserRole ===
                                    APP_LABELS.YUSA_DATA_STEWARD) ||
                                    APP_LABELS.COI_PERMISSION_ROLES.includes(
                                      loggedInUserRole
                                    )
                                    ? '/insurance/dashboard'
                                    : '/insurance/association/dashboard'
                                    }`}
                                  className="ymca-card-link"
                                >
                                  {APP_LABELS.START_NOW}
                                  <img
                                    src={RightIconBlue}
                                    className="img-fluid ms-2"
                                    alt="right-icon-blue"
                                  />
                                </Link>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )}

                  {homedata?.MetadataManagerTab &&
                      (loggedInUserRole === APP_LABELS.YUSA_DATA_STEWARD ||
                        loggedInUserRole === APP_LABELS.YUSA_DATA_VIEWER ||
                        userGroups?.includes('Metadata Manager')) && (
                        <div className="col-lg-4">
                          <Link
                            to={`${loggedInUserRole &&
                              (loggedInUserRole ===
                                APP_LABELS.YUSA_DATA_STEWARD ||
                                loggedInUserRole ===
                                APP_LABELS.YUSA_DATA_VIEWER) &&
                              '/metadata-manager/dashboard'
                              }`}
                            className="text-decoration-none"
                            onClick={() => sessionStorage.removeItem('year')}
                          >
                            <div className="card ymca-primary-card data-cards p-0">
                              <div className="card-header-block custom-height">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: homedata?.Metadata_Manager_Title
                                  }}
                                />
                              </div>
                              <div
                                className="ymcaStrapiImage"
                                dangerouslySetInnerHTML={{
                                  __html: homedata?.Metadata_Manager_Image
                                }}
                              />
                              <div className="card-sub-header-block">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: homedata?.Metadata_Manager_Date
                                  }}
                                />
                              </div>
                              <div className="card-body">
                                <div className="card-body-content">
                                  <p className="ymca-para">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          homedata?.Metadata_Manager_Card_Text
                                      }}
                                    />
                                  </p>
                                </div>
                                <Link
                                  to={`${(loggedInUserRole ===
                                      APP_LABELS.YUSA_DATA_STEWARD ||
                                      loggedInUserRole ===
                                      APP_LABELS.YUSA_DATA_VIEWER) &&
                                    '/metadata-manager/dashboard'
                                    }`}
                                  className="ymca-card-link"
                                  onClick={() => sessionStorage.removeItem('year')}
                                >
                                  {APP_LABELS.START_NOW}
                                  <img
                                    src={RightIconBlue}
                                    className="img-fluid ms-2"
                                    alt="right-icon-blue"
                                  />
                                </Link>
                              </div>
                            </div>
                          </Link>
                        </div>
                      )} 
                  </div>
                </div>
              </section>
            )}
        </div>
      </main>
    </BlockUi>
  );
};

Home.propTypes = {
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleToGetModuleConfigInfo: PropTypes.func.isRequired,
  ModulesConfigData: PropTypes.objectOf(PropTypes.any).isRequired
};
const mapDispatchToProps = {
  handleToGetModuleConfigInfo: getModulesConfigData
};
const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state),
  ModulesConfigData: GetModulesConfigSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
