import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';

import BackIcon from '@static/images/back-icon.svg';
import { Link, Redirect } from 'react-router-dom';
import CustomModal from '@utilities/CustomModal';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import totalRecordsLightIcon from '@static/images/total-records-light-icon.svg';
import totalRecordsDarkIcon from '@static/images/total-records-dark-icon.svg';
import validRecordsLightIcon from '@static/images/valid-records-light-icon.svg';
import validRecordsDarkIcon from '@static/images/valid-records-dark-icon.svg';
import invalidRecordsLightIcon from '@static/images/invalid-records-light-icon.svg';
import invalidRecordsDarkIcon from '@static/images/invalid-records-dark-icon.svg';
import crossiconblue from '@static/images/cross-icon-blue.svg';
import { UploadMetadataSelector } from '@redux/metadata-manager/upload-metadata/selectors';
import { importSummaryMetadata } from '@redux/metadata-manager/upload-metadata/actions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { APP_LABELS, META_DATA } from '@config/app-config';
import Table from './table';
import { METADATA_REVIEW_COLUMNS } from './columns';
import ReviewViewDetails from './view-deetail';
import { handleResponse } from '../shared/index';

const MetadataManagerReview = ({ uploadedMetadata, handleImportMetadata }) => {
  const { data } = uploadedMetadata;
  const [kpiCard, setKpiCard] = useState('');
  const [tableData, setTableData] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [uploadStatus, setUploadStatus] = useState('');
  const [importResponseStatus, setImportResponseStatus] = useState(false);
  const [showUploadStatus, setShowUploadStatus] = useState(false);
  const [canRedirect, setCanRedirect] = useState(false);
  const [canImport, setCanImport] = useState(false);
  const [isValidBatch, setIsValidBatch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [instructions, setInstructions] = useState('');

  useEffect(() => {
    setTableData(data.segregatedList.TotalData);
    setIsValidBatch(data.fileUploadSummary?.invalidData === 0);
    setInstructions(
      JSON.parse(sessionStorage.getItem('allinstructions'))?.[
        'review-metadata-data'
      ]?.Instruction
    );
  }, []);

  const handleImportClick = async () => {
    setIsLoading(true);
    const response = await handleImportMetadata(
      data.fileUploadSummary.fileUploadId
    );
    const result = handleResponse(response);
    setUploadStatus(result.isSuccess ? result.data : result.message);
    setImportResponseStatus(result.isSuccess);
    setShowUploadStatus(true);
    setIsLoading(false);
  };

  const handleKpiClick = key => {
    setTableData(data.segregatedList[key]);
    setKpiCard(key);
  };

  const handleViewClick = item => {
    setSelectedRow(item.row.original);
  };

  const handleSubmitmportPopup = () => {
    if (importResponseStatus) setCanRedirect(true);
    else setShowUploadStatus(false);
  };

  const columns = React.useMemo(
    () => METADATA_REVIEW_COLUMNS({ handleViewClick, isValid: kpiCard === "InValidData" }),
    [handleViewClick]
  );

  return (
    <BlockUi
      blocking={isLoading}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper">
        <main className="page-container">
          <div className="container">
            <div className="d-flex justify-content-between">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb ymca-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/metadata-manager/dashboard">
                      <span>Dashboard</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item">Upload Data</li>
                </ol>
              </nav>
              <Link
                className="ymca-title-link"
                to="/metadata-manager/upload-metadata"
              >
                <img src={BackIcon} className="me-1 mb-2" alt="back-icon" />{' '}
                Back
              </Link>
            </div>
            <h5 className="ymca-sub-heading">Metadata Review</h5>
          </div>
          <hr />
          <div className="container">
            <div
              className="alert ymca-instruction-alert text-black mt-4"
              role="alert"
            >
              <div className="ymca-cdp-instruction-title mb-2">
                {APP_LABELS.INSTRUCTIONS}
              </div>
              <p className="ymca-instructTitle">
                <div
                  dangerouslySetInnerHTML={{
                    __html: instructions
                  }}
                />
              </p>
            </div>

            <section className="stats-data">
              <div className="row justify-content-center">
                <div
                  className="col-lg-4 col-md-4 col-sm-12"
                  tabIndex="0"
                  role="button"
                  onKeyDown={e => e.preventDefault()}
                  onClick={() => handleKpiClick('TotalData')}
                >
                  <div
                    className={`card stats-card total-records-card ${kpiCard === 'TotalData' ? 'active' : ''
                      }`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="card-icon">
                        <img
                          src={totalRecordsLightIcon}
                          alt="totalRecordsLightIcon"
                          className="primary-icon"
                        />
                        <img
                          src={totalRecordsDarkIcon}
                          alt="totalRecordsDarkIcon"
                          className="secondary-icon"
                        />
                      </div>

                      <div className="card-title">
                        <h4>{data.fileUploadSummary?.totalData}</h4>
                        <p>{META_DATA.REVIEW_PAGE.TotalData}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 col-sm-12"
                  tabIndex="0"
                  role="button"
                  onKeyDown={e => e.preventDefault()}
                  onClick={() => handleKpiClick('ValidData')}
                >
                  <div
                    className={`card stats-card valid-records-card ${kpiCard === 'ValidData' ? 'active' : ''
                      }`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="card-icon">
                        <img
                          src={validRecordsLightIcon}
                          alt="validRecordsLightIcon"
                          className="primary-icon"
                        />
                        <img
                          src={validRecordsDarkIcon}
                          alt="validRecordsDarkIcon"
                          className="secondary-icon"
                        />
                      </div>

                      <div className="card-title">
                        <h4>{data.fileUploadSummary?.validData}</h4>
                        <p>{META_DATA.REVIEW_PAGE.ValidData}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 col-sm-12"
                  tabIndex="0"
                  role="button"
                  onKeyDown={e => e.preventDefault()}
                  onClick={() => handleKpiClick('InValidData')}
                >
                  <div
                    className={`card stats-card invalid-records-card ${kpiCard === 'InValidData' ? 'active' : ''
                      }`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="card-icon">
                        <img
                          src={invalidRecordsLightIcon}
                          alt="invalidRecordsLightIcon"
                          className="primary-icon"
                        />
                        <img
                          src={invalidRecordsDarkIcon}
                          alt="invalidRecordsDarkIcon"
                          className="secondary-icon"
                        />
                      </div>

                      <div className="card-title">
                        <h4>{data.fileUploadSummary?.invalidData}</h4>
                        <p>{META_DATA.REVIEW_PAGE.InvalidData}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className="ymca-section-heading">Metadata</div>

              <div className="table-responsive custom-scrollbar mt-3">
                <Table rows={tableData} headers={columns} showCount />
              </div>

              <div className="form-group">
                <div className="form-check ymca-check-box my-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    disabled={!isValidBatch}
                    onChange={() => setCanImport(!canImport)}
                  />
                  <label
                    className="form-check-label ymca-cachet-normal-dark-text"
                    htmlFor="flexCheckDefault"
                  >
                    I confirm to the changes in system.
                  </label>
                </div>
              </div>

              <div className="d-flex mt-4">
                <Link
                  className="btn ymca-primary-outline-btn"
                  type="button"
                  to="/metadata-manager/upload-metadata"
                >
                  Cancel
                </Link>
                <button
                  className="btn ymca-primary-btn ms-3"
                  disabled={!canImport || !isValidBatch}
                  type="button"
                  onClick={() => handleImportClick()}
                >
                  Import Data
                </button>
              </div>
            </section>
          </div>
        </main>
      </div>

      {<ReviewViewDetails row={selectedRow} kpiCard={kpiCard} />}

      <CustomModal
        show={showUploadStatus}
        type={importResponseStatus ? APP_LABELS.SUCCESS : APP_LABELS.ERROR}
        message={uploadStatus}
        submitButtonName={importResponseStatus ? 'Go To Dashboard' : 'Ok'}
        onSubmit={handleSubmitmportPopup}
      />

      {canRedirect && <Redirect to="/metadata-manager/dashboard" />}
    </BlockUi>
  );
};
MetadataManagerReview.propTypes = {
  uploadedMetadata: PropTypes.node.isRequired,
  handleImportMetadata: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleImportMetadata: importSummaryMetadata
};

const mapStateToProps = state => ({
  uploadedMetadata: UploadMetadataSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MetadataManagerReview);
