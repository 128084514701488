import React, { useEffect } from 'react';
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SearchIcon from '@static/images/search-icon.svg';
import PreviousIcon from '@static/images/previous-icon.svg';
import NextIcon from '@static/images/Next-icon.svg';
import SortUpIcon from '@static/images/sorting-icon-up.svg';
import SortDownIcon from '@static/images/sorting-icon-down.svg';
import FirstIcon from '@static/images/first-icon.svg';
import LastIcon from '@static/images/Last-icon.svg';

const Table = ({
    globalFilter,
    setGlobalFilter,
    rows,
    headers,
    hiddenColumns,
    noRecordMessage,
    title,
    showCount,
    getRowProps,
    autoResetSortBy,
    autoResetPage,
    autoResetFilters,
    autoResetGlobalFilter,
    hiddenRecordCount,
    showHideButtonText,
    showpagination,
}) => {
    const columns = headers;
    const data = rows || [];

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        gotoPage,
        pageCount,
        prepareRow,
        state,
        setGlobalFilter: setTableGlobalFilter,
        setPageSize,

        rows: filteredRows
    } = useTable(
        {
            columns,
            data,
            disableSortRemove: true,
            defaultCanSort: true,
            autoResetSortBy,
            autoResetPage,
            autoResetFilters,
            autoResetGlobalFilter,
            initialState: {
                pageSize: 25,
                hiddenColumns: hiddenColumns || []
            },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { pageIndex, pageSize } = state;

    useEffect(() => {
        setTableGlobalFilter(globalFilter);
    }, [globalFilter, setTableGlobalFilter]);

    const calculatePageEntries = () => {
        return data.length > 0 ? pageSize * pageIndex + 1 : 0;
    };

    const calculatePageToEntries = () => {
        let toCount = pageSize * (pageIndex + 1);
        return toCount > filteredRows.length ? filteredRows.length : toCount;
    };

    const calculatePageOfEntries = () => {
        return globalFilter ? `${filteredRows.length} (filtered)` : filteredRows.length;
    };

    const getSortedDesc = isSortedDesc =>
        isSortedDesc ? (
            <button type="button" className="btn btn-link p-0 custom-align">
                <img src={SortUpIcon} className="img-fluid" alt="sorting-icon" />
            </button>
        ) : (
            <button type="button" className="btn btn-link p-0 custom-align">
                <img src={SortDownIcon} className="img-fluid" alt="sorting-icon" />
            </button>
        );

    return (
        <section className="dashboard-list">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-responsive custom-scrollbar">
                            <table className="table ymca-table ymca-tableHeight" {...getTableProps()}>
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    {column.render('Header')}
                                                    {column.render('Header') !== "Action" && <div className="sorting-icon-wrapper ms-2">
                                                        {column.isSorted ? (
                                                            getSortedDesc(column.isSortedDesc)
                                                        ) : (
                                                            column.Header !== 'Summary' && column.Header !== '' && (
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-link p-0 custom-align"
                                                                    >
                                                                        <img
                                                                            src={SortUpIcon}
                                                                            className="img-fluid"
                                                                            alt="sorting-icon-up"
                                                                        />
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-link p-0 custom-align"
                                                                    >
                                                                        <img
                                                                            src={SortDownIcon}
                                                                            className="img-fluid"
                                                                            alt="sorting-icon-down"
                                                                        />
                                                                    </button>
                                                                </>
                                                            )
                                                        )}
                                                    </div>}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map(row => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps(getRowProps)}>
                                                {row.cells.map(cell => (
                                                    <td {...cell.getCellProps()} className={cell.column.className || ''}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            {page.length === 0 && (
                                <div className="text-center py-3 fw-bold ymca-warning-text">
                                    {noRecordMessage}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* Page Size Selector, Pagination, and Table Info */}
                {showpagination && <div className="row mt-3">
                    <div className="col-4">
                        <div className="d-flex">
                            <div className="mt-1">Show:</div>
                            <div className="ps-2 pe-2" style={{ width: '100px' }}>
                                <select
                                    id="sel-options"
                                    className="form-select"
                                    value={pageSize}
                                    onChange={e => setPageSize(Number(e.target.value))}
                                >
                                    {[10, 20, 25, 50, 100].map(pSize => (
                                        <option key={pSize} value={pSize}>
                                            {pSize}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <nav aria-label="Page navigation" className="ymca-pagination mt-0">
                            <ul className="pagination justify-content-center pagination-sm">
                                <li className="page-item">
                                    <button
                                        type="button"
                                        className="page-link custom-align"
                                        onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}
                                    >
                                        <img src={FirstIcon} className="pe-1" alt="" />
                                        {'First'}
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button
                                        type="button"
                                        className="page-link custom-align"
                                        onClick={() => previousPage()}
                                        disabled={!canPreviousPage}
                                    >
                                        <img src={PreviousIcon} className="pe-1" alt="" />
                                        {'Previous'}
                                    </button>
                                </li>
                                {[...Array(pageCount).keys()].map(count =>
                                    count >= (pageIndex > 10 ? pageIndex - 10 : 0) &&
                                        count <= (pageIndex < 10 ? 9 : pageIndex) ? (
                                        <li className="page-item notranslate" key={count + 1}>
                                            <button
                                                type="button"
                                                className={`page-link custom-align ${count === pageIndex ? 'active' : ''}`}
                                                onClick={() => gotoPage(count)}
                                            >
                                                {count + 1}
                                            </button>
                                        </li>
                                    ) : null
                                )}
                                <li className="page-item">
                                    <button
                                        type="button"
                                        className="page-link custom-align"
                                        onClick={() => nextPage()}
                                        disabled={!canNextPage}
                                    >
                                        {'Next '}
                                        <img src={NextIcon} className="pl-1 ms-1" alt="" />
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button
                                        type="button"
                                        className="page-link custom-align"
                                        onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}
                                    >
                                        {'Last'}
                                        <img src={LastIcon} className="pe-1" alt="" />
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="col-4">
                        <div className="b-400 float-end">
                            Showing {calculatePageEntries()} to {calculatePageToEntries()} of {calculatePageOfEntries()} entries
                        </div>
                    </div>
                </div>}
            </div>
        </section>
    );
};

Table.propTypes = {
    globalFilter: PropTypes.string,
    setGlobalFilter: PropTypes.func.isRequired,
    rows: PropTypes.arrayOf(PropTypes.any),
    headers: PropTypes.arrayOf(PropTypes.any),
    hiddenColumns: PropTypes.arrayOf(PropTypes.any),
    noRecordMessage: PropTypes.string,
    title: PropTypes.string,
    showCount: PropTypes.bool,
    getRowProps: PropTypes.func.isRequired,
    autoResetSortBy: PropTypes.bool.isRequired,
    autoResetPage: PropTypes.bool.isRequired,
    autoResetFilters: PropTypes.bool.isRequired,
    autoResetGlobalFilter: PropTypes.bool.isRequired,
    hiddenRecordCount: PropTypes.number.isRequired,
    showHideButtonText: PropTypes.string.isRequired
};

Table.defaultProps = {
    globalFilter: '',
    rows: [],
    headers: [],
    hiddenColumns: [],
    noRecordMessage: 'No records found!',
    title: '',
    showCount: false
};

export default connect()(Table);
