export const GET_METADATA_CATEGORY_MAPPINGS_SUCCESS =
  'GET_METADATA_CATEGORY_MAPPINGS_SUCCESS';
export const GET_METADATA_CATEGORY_MAPPINGS_FAILURE =
  'GET_METADATA_CATEGORY_MAPPINGS_FAILURE';

export const GET_METADATA_CATEGORY_ITEM_SUCCESS =
  'GET_METADATA_CATEGORY_ITEM_SUCCESS';
export const GET_METADATA_CATEGORY_ITEM_FAILURE =
  'GET_METADATA_CATEGORY_ITEM_FAILURE';

export const GET_METADATA_CATEGORIES_SUCCESS =
  'GET_METADATA_CATEGORIES_SUCCESS';
export const GET_METADATA_CATEGORIES_FAILURE =
  'GET_METADATA_CATEGORIES_FAILURE';

export const GET_CONFIGURE_CATEGORIES_SUCCESS =
  'GET_CONFIGURE_CATEGORIES_SUCCESS';
export const GET_CONFIGURE_CATEGORIES_FAILURE =
  'GET_CONFIGURE_CATEGORIES_FAILURE';
