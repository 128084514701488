import { META_DATA_URLS } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

// eslint-disable-next-line import/prefer-default-export
export const cloneMetadata =
  (domainId, year, cloneYear) => (dispatch, getState) =>
    asyncRequest({
      url: META_DATA_URLS.CLONE_META_DATA(domainId, year, cloneYear),
      method: 'POST',
      successAction: data => ({
        type: types.CLONE_META_DATA_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.CLONE_META_DATA_FAILURE,
        payload: error
      }),
      dispatch
    });

export const getCloneCount =
  (domainId, year, cloneYear) => (dispatch, getState) =>
    asyncRequest({
      url: META_DATA_URLS.GET_CLONE_COUNT(domainId, year, cloneYear),
      method: 'GET',
      successAction: data => ({
        type: types.CLONE_COUNT_META_DATA_SUCCESS,
        async: true,
        payload: data,
        state: getState()
      }),
      failureAction: error => ({
        type: types.CLONE_COUNT_META_DATA_FAILURE,
        payload: error
      }),
      dispatch
    });
