import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import ViewIcon from '@static/images/swimRegistration/view-icon.svg';
import EditPencilIcon from '@static/images/swimRegistration/edit-pencil-icon.svg';
import DeleteIcon from '@static/images/swimRegistration/delete-icon.svg';
import { APP_LABELS } from '@config/app-config';

export const METADATA_DASHBOARD_COLUMNS = ({
  handleViewClick,
  handleDeleteClick,
  role
}) => [
    {
      Header: 'Data Domain',
      accessor: 'dataDomainName',
      className: 'notranslate'
    },
    {
      Header: 'Form Name',
      accessor: 'formName',
      className: 'notranslate ymca-word-break ymca-ellipse'
    },
    {
      Header: 'Sub Form Name',
      accessor: 'subformName',
      className: 'notranslate ymca-word-break ymca-ellipse'
    },
    {
      Header: 'Category 1',
      accessor: 'category1Name',
      className: 'notranslate ymca-word-break ymca-ellipse'
    },
    {
      Header: 'Category 2',
      accessor: 'category2Name',
      className: 'notranslate ymca-word-break ymca-ellipse'
    },
    {
      Header: 'Category 3',
      accessor: 'category3Name',
      className: 'notranslate ymca-word-break ymca-ellipse'
    },
    {
      Header: 'Data Point ID',
      accessor: 'dataPointId',
      className: 'notranslate ymca-word-break ymca-ellipse'
    },
    {
      Header: 'Last Updated Name & Time',
      accessor: row => {
        if (row.updatedDttm)
          return (
            <>
              {row.updatedBy || ''} <br />
              {moment.utc(row.updatedDttm).local().format('DD MMM YYYY, hh:mm a')}
            </>
          );

        if (row.createdDttm)
          return (
            <>
              {row.createdBy || ''} <br />
              {moment.utc(row.createdDttm).local().format('DD MMM YYYY, hh:mm a')}
            </>
          );
      },
      className: 'notranslate ymca-word-break ymca-ellipse'
    },
    {
      Header: 'Actions',
      accessor: 'isHidden',
      className: 'hide',
      Cell: v => (
        <>
          <div className="d-flex">
            <button
              className="btn table-link"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              type="button"
              onClick={() => handleViewClick(v)}
            >
              <img src={ViewIcon} className="me-2 mb-1" alt="view-icon" />
            </button>
            {role !== APP_LABELS.YUSA_DATA_VIEWER && (
              <>
                <Link
                  className="btn table-link"
                  type="button"
                  to={`/metadata-manager/manage-metadata/${v.row.original.s_ID}`}
                >
                  <img
                    src={EditPencilIcon}
                    className="me-2 mb-1"
                    alt="edit-pencil-icon"
                  />
                </Link>
                <button
                  className="btn table-link"
                  type="button"
                  onClick={() => handleDeleteClick(v.row.original.s_ID)}
                >
                  <img
                    src={DeleteIcon}
                    className="me-2 mb-1"
                    alt="edit-pencil-icon"
                  />
                </button>
              </>
            )}
          </div>
        </>
      )
    }
  ];

export const METADATA_NO_OF_CHANGES_COLUMNS = ({ handleViewClick }) => [
  {
    Header: 'Data Domain',
    accessor: 'dataDomainName',
    className: 'notranslate'
  },
  {
    Header: 'Form Name',
    accessor: 'formName',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Sub Form Name',
    accessor: 'subformName',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Category 1',
    accessor: 'category1Name',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Category 2',
    accessor: 'category2Name',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Category 3',
    accessor: 'category3Name',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Data Point ID',
    accessor: 'dataPointId',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Last Updated Name & Time',
    accessor: row => {

      return (
        <>
          {row.updatedBy ?? row.createdBy} <br />
          {moment.utc(row.validTo).local().format('DD MMM YYYY, hh:mm a')}
        </>
      );
    },
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Actions',
    accessor: 'isHidden',
    className: 'hide p-0',
    Cell: v => (
      <>
        <div className="d-flex">
          <button
            className="btn table-link"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            type="button"
            onClick={() => handleViewClick(v)}
          >
            <img src={ViewIcon} className="me-2 mb-1" alt="view-icon" />
          </button>
        </div>
      </>
    )
  }
];

export const METADATA_DASHBOARD_NOOFUPLOAD_COLUMNS = () => [
  {
    Header: 'Data Domain',
    accessor: 'dataDomainName',
    className: 'notranslate'
  },
  {
    Header: 'Form Name',
    accessor: 'formName',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Sub Form Name',
    accessor: 'subformName',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Category 1',
    accessor: 'category1Name',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Category 2',
    accessor: 'category2Name',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Category 3',
    accessor: 'category3Name',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Datapoint ID',
    accessor: 'dataPointID',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Last Updated Name & Time',
    accessor: row => {
      if (row.createdDTTM)
        return (
          <>
            {row.createdBy} <br />
            {moment.utc(row.createdDTTM).local().format('DD MMM YYYY, hh:mm a')}
          </>
        );
    },
    className: 'notranslate ymca-word-break ymca-ellipse'
  }
];

export const METADATA_DASHBOARD_UPLOAD_COLUMNS = ({
  handleUploadViewClick
}) => [
    {
      Header: 'Data Domain',
      accessor: row =>
        row.metadataFileUploadMappings?.length > 0
          ? row.metadataFileUploadMappings[0].dataDomainName
          : '',
      className: 'notranslate'
    },
    {
      Header: 'File Name',
      accessor: 'fileName',
      className: 'notranslate'
    },
    {
      Header: 'Upload By Name & Time',
      accessor: row =>
        `${row.uploadedBy}  ${row.uploadedDTTM ? moment.utc(row.uploadedDTTM)
          .local().format('DD MMM YYYY, hh:mm a') : ""}`,
      className: 'notranslate'
    },
    {
      Header: 'No. Of Records',
      accessor: row =>
        row.metadataFileUploadMappings?.length > 0
          ? row.metadataFileUploadMappings.length
          : 0,
      className: 'notranslate'
    },
    {
      Header: 'Year',
      accessor: 'year',
      className: 'notranslate'
    },
    {
      Header: 'Actions',
      accessor: 'isHidden',
      Cell: v => (
        <>
          <div className="d-flex">
            <button
              className="btn table-link"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              type="button"
              onClick={() => handleUploadViewClick(v)}
            >
              <img src={ViewIcon} className="me-2 mb-1" alt="view-icon" />
            </button>
          </div>
        </>
      )
    }
  ];

export const METADATA_DETAIL_FIELDS = () => [
  {
    label: 'Form ID',
    accessor: 'formId',
    className: 'notranslate'
  },
  {
    label: 'Sub Form ID',
    accessor: 'subformId',
    className: 'notranslate'
  },
  {
    label: 'Last Updated',
    accessor: row => {
      if (row.validTo)
        return `${row.updatedBy ?? row.createdBy} - ${moment
          .utc(row.validTo)
          .local()
          .format('DD MMM YYYY, hh:mm a')}`;

      if (row.updatedDttm)
        return `${row.updatedBy || ''} on ${moment
          .utc(row.updatedDttm)
          .local()
          .format('DD MMM YYYY, hh:mm a')}`;

      if (row.createdDttm)
        return `${row.createdBy || ''}  ${moment
          .utc(row.createdDttm)
          .local()
          .format('DD MMM YYYY, hh:mm a')}`;
    },
    className: 'notranslate'
  },
  {
    label: 'Form Name',
    accessor: 'formName',
    className: 'notranslate'
  },
  {
    label: 'Sub Form Name',
    accessor: 'subformName',
    className: 'notranslate'
  },
  {
    label: '',
    accessor: () => <hr />,
    className: 'notranslate'
  },
  {
    label: 'Year',
    accessor: 'year',
    className: 'notranslate'
  },
  {
    label: 'Category 1 ID',
    accessor: 'category1Id',
    className: 'notranslate'
  },

  {
    label: 'Category 2 ID',
    accessor: 'category2Id',
    className: 'notranslate'
  },

  {
    label: 'Category 3 ID',
    accessor: 'category3Id',
    className: 'notranslate'
  },

  {
    label: 'Category 1 Name',
    accessor: 'category1Name',
    className: 'notranslate'
  },

  {
    label: 'Category 2 Name',
    accessor: 'category2Name',
    className: 'notranslate'
  },

  {
    label: 'Category 3 Name',
    accessor: 'category3Name',
    className: 'notranslate'
  },
  {
    label: 'Data Point Type',
    accessor: 'dataPointType',
    className: 'notranslate'
  },

  {
    label: 'Data Point ID',
    accessor: 'dataPointId',
    className: 'notranslate'
  },
  {
    label: 'Data Point Score',
    accessor: 'dataPoints',
    className: 'notranslate'
  },
  {
    label: 'QualtricsID',
    accessor: 'qualtricsId',
    className: 'notranslate'
  },
  {
    label: 'Data Source Mapping',
    accessor: 'sourceMapping',
    className: 'notranslate'
  },
  {
    label: 'Data Target Mapping',
    accessor: 'targetMapping',
    className: 'notranslate'
  },
  {
    label: 'Data Domain ID',
    accessor: 'dataDomainId',
    className: 'notranslate'
  },
  {
    label: 'Data Domain',
    accessor: 'dataDomainName',
    className: 'notranslate'
  },

  {
    label: 'Data Point Description',
    accessor: 'dataPointDescription',
    className: 'notranslate'
  },

  {
    label: 'Data Point Granularity',
    accessor: 'dataPointGranularity',
    className: 'notranslate'
  },

  {
    label: 'Data Segment Type',
    accessor: 'dataSegmentType',
    className: 'notranslate'
  },

  {
    label: 'Min Points Required',
    accessor: 'minPointsReq',
    className: 'notranslate'
  },
  {
    label: 'Data Evaluation ID',
    accessor: 'dataEvalId',
    className: 'notranslate'
  },

  {
    label: 'Form Points Possible',
    accessor: 'formPointsPossible',
    className: 'notranslate'
  }
];
