export const GET_METADATA_DOMAIN_SUCCESS = 'GET_METADATA_DOMAIN_SUCCESS';
export const GET_METADATA_DOMAIN_FAILURE = 'GET_METADATA_DOMAIN_FAILURE';

export const GET_METADATA_YEAR_SUCCESS = 'GET_METADATA_YEAR_SUCCESS';
export const GET_METADATAYEAR_FAILURE = 'GET_METADATA_YEAR_FAILURE';

export const GET_METADATA_DASHBOARD_COUNT_SUCESS =
  'GET_METADATA_DASHBOARD_COUNT_SUCESS';
export const GET_METADATA_DASHBOARD_COUNT_FAILURE =
  'GET_METADATA_DASHBOARD_COUNT_FAILURE';

export const GET_METADATA_FORMS_SUCESS = 'GET_METADATA_FORMS_SUCESS';
export const GET_METADATA_FORMS_FAILURE = 'GET_METADATA_FORMS_FAILURE';

export const GET_TOTAL_DATA_COLLECTION_SUCESS =
  'GET_TOTAL_DATA_COLLECTION_SUCESS';
export const GET_TOTAL_DATA_COLLECTION_FAILURE =
  'GET_TOTAL_DATA_COLLECTION_FAILURE';

export const GET_TOTAL_UPLOAD_SUCESS = 'GET_TOTAL_UPLOAD_SUCESS';
export const GET_TOTAL_UPLOAD_FAILURE = 'GET_TOTAL_UPLOAD_FAILURE';

export const GET_TOTAL_CHANGES_SUCESS = 'GET_TOTAL_CHANGES_SUCESS';
export const GET_TOTAL_CHANGES_FAILURE = 'GET_TOTAL_CHANGES_FAILURE';

export const EXPORT_METADATA_SUCESS = 'EXPORT_METADATA_SUCESS';
export const EXPORT_METADATA_FAILURE = 'EXPORT_METADATA_FAILURE';
