import { META_DATA_URLS } from '@config/api-config';
import { asyncRequest } from '@config/http-config';
import * as types from './types';

export const getSegmentTypes = () => (dispatch, getState) =>
  asyncRequest({
    url: META_DATA_URLS.GET_SEGMENT_TYPES(),
    method: 'GET',
    successAction: data => ({
      type: types.GET_METADATA_SEGMENT_TYPES_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_METADATA_SEGMENT_TYPES_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getDataPointTypes = () => (dispatch, getState) =>
  asyncRequest({
    url: META_DATA_URLS.GET_DATA_POINT_TYPES(),
    method: 'GET',
    successAction: data => ({
      type: types.GET_METADATA_DATA_POINT_TYPES_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_METADATA_DATA_POINT_TYPES_FAILURE,
      payload: error
    }),
    dispatch
  });

export const addMetadata = payload => (dispatch, getState) =>
  asyncRequest({
    url: META_DATA_URLS.ADD_META_DATA(),
    method: 'POST',
    data: payload,
    successAction: data => ({
      type: types.ADD_META_DATA_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.ADD_META_DATA_FAILURE,
      payload: error
    }),
    dispatch
  });

export const updateMetadata = (payload, id) => (dispatch, getState) =>
  asyncRequest({
    url: META_DATA_URLS.UPDATE_META_DATA(id),
    method: 'PUT',
    data: payload,
    successAction: data => ({
      type: types.UPDATE_META_DATA_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.UPDATE_META_DATA_FAILURE,
      payload: error
    }),
    dispatch
  });

export const deleteMetadata = id => (dispatch, getState) =>
  asyncRequest({
    url: META_DATA_URLS.DELETE_META_DATA(id),
    method: 'DELETE',
    successAction: data => ({
      type: types.DELETE_META_DATA_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.DELETE_META_DATA_FAILURE,
      payload: error
    }),
    dispatch
  });

export const getMetadata = id => (dispatch, getState) =>
  asyncRequest({
    url: META_DATA_URLS.GET_META_DATA(id),
    method: 'GET',
    successAction: data => ({
      type: types.GET_META_DATA_SUCCESS,
      async: true,
      payload: data,
      state: getState()
    }),
    failureAction: error => ({
      type: types.GET_META_DATA_FAILURE,
      payload: error
    }),
    dispatch
  });
